import { Alert } from '../../TSTypes/alert';
import colors from '../../colors';

const alert: Alert = {
  type: {
    error: {
      backgroundColor: `${colors.accent.red}`,
      color: `${colors.primary.white}`,
    },
    progress: {
      backgroundColor: `${colors.accent.ltOrange}`,
      color: `${colors.primary.darkGrey}`,
    },
    warning: {
      backgroundColor: `${colors.accent.orange}`,
      color: `${colors.primary.darkGrey}`,
    },
    success: {
      backgroundColor: `${colors.accent.green}`,
      color: `${colors.primary.darkGrey}`,
    },
  },
  size: {
    fullWidth: {
      height: '32px',
      borderRadius: '0px',
      padding: '7px',
      lineHeight: '1.125rem',
      position: 'absolute',
      left: '0',
    },
    inPage: {
      height: '32px',
      borderRadius: '4px',
      padding: '7px',
      lineHeight: '1.125rem',
      position: 'relative',
      left: '0',
    },
    cbPublish: {
      height: '48px',
      fontWeight1: '600',
      fontSize1: '0.75px',
      fontWeight2: '500',
      fontSize2: '1rem',
      borderRadius: '0px',
      padding: '7px',
      lineHeight: '1.125rem',
      position: 'relative',
    },
  },
};

export default alert;
