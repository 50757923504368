import React, { FC } from 'react';
import { WindowWidthContext } from '@minecraft.utils';
import { useMergedResponsiveProps } from '../helpers';
import { FlexProps } from '../types';
import { FlexBase } from './FlexBase';

export const FlexCol: FC<Omit<FlexProps, 'direction'>> = ({ smProps, mdProps, lgProps, xlProps, ...props }) => {
  const allProps = useMergedResponsiveProps({ props, smProps, mdProps, lgProps, xlProps });
  return <FlexBase {...allProps} direction="column" />;
};
FlexCol.displayName = 'FlexCol';

export const FlexRow: FC<Omit<FlexProps, 'direction'>> = ({ smProps, mdProps, lgProps, xlProps, ...props }) => {
  const allProps = useMergedResponsiveProps({ props, smProps, mdProps, lgProps, xlProps });
  return <FlexBase {...allProps} direction="row" />;
};
FlexRow.displayName = 'FlexRow';

export const FlexGrow: FC = ({ children }) => <div style={{ flex: 1 }}>{children}</div>;

export const FlexDynamic: FC<FlexProps> = ({ children, smProps, mdProps, lgProps, xlProps, ...props }) => {
  const allProps = useMergedResponsiveProps({ props, smProps, mdProps, lgProps, xlProps });

  return <FlexBase {...allProps}>{children}</FlexBase>;
};
FlexDynamic.displayName = 'FlexDynamic';

/**
 * @deprecated Use <FlexDynamic direction="column" mdProps={{ direction: 'row' }}> instead
 */
export const FlexSmColMdRow: FC<Omit<FlexProps, 'direction'>> = (props) => {
  const { lessMd } = WindowWidthContext.useWidthData();

  return <FlexBase {...props} direction={lessMd ? 'column' : 'row'} />;
};
