import React, { FunctionComponent } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { Zendesk } from '@blocs.zendesk';
import { PermissionsProvider } from '@minecraft.permissions';
import { LanguageSetter } from '../IntlFeature';

interface Props extends RouteProps {
  hideZendesk?: boolean;
  children: JSX.Element;
}

const UnauthenticatedRoute: FunctionComponent<Props> = ({ hideZendesk, children, ...restProps }) => {
  return (
    <Route {...restProps}>
      <PermissionsProvider>
        <Zendesk shouldHide={hideZendesk} />
        <LanguageSetter authenticated={false} loaded>
          {children}
        </LanguageSetter>
      </PermissionsProvider>
    </Route>
  );
};

export default UnauthenticatedRoute;
