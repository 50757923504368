import { ApolloLink } from '@apollo/client';
import { getAccessToken } from '../../utils';

// endpoints that are added to this list will not have
// the Authorization header added, which we need to do for
// routes exposed to the public
const PUBLIC_OPERATION_NAMES = [
  'getPresentationPublic',
  'getPublicProfile',
  'getPresentationGroupPublic',
  'addPickToPackageProfilePublic',
  'deletePickFromPresentationProfilePublic',
  'conflicts',
  'refreshToken',
  'getCollaboratorInviteById',
  'CollaboratorByInviteId',
];

const isPublicApi = (operationName) => {
  return PUBLIC_OPERATION_NAMES.includes(operationName);
};

export const authLink = new ApolloLink((operation, forward) => {
  const accessToken = isPublicApi(operation.operationName) ? '' : getAccessToken();

  // add the authorization to the headers
  operation.setContext(({ headers }) => ({
    headers: {
      ...headers,
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  }));

  return forward(operation);
});
