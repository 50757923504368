import { createContext } from 'react';

interface LoadContext {
  isLoading: boolean;
  showLoader: (loading: boolean) => void;
}
const LoaderContext = createContext<LoadContext>({
  isLoading: false,
  showLoader: () => undefined,
});

export default LoaderContext;
