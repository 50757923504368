import 'bootstrap/dist/css/bootstrap.min.css';
import CoreTheme from './CoreTheme';
import EnterpriseTheme from './EnterpriseTheme';
import EnterpriseDarkTheme from './EnterpriseDarkTheme';
import { StudioTheme, shouldUseStudioTheme } from './StudioTheme';
import { StudioDarkTheme } from './StudioDarkTheme';
import PCTheme from './PCTheme';
import { Theme as ThemeType } from './TSTypes/theme';
import { Media as MediaType } from './TSTypes/grid';
import { grid, media, atBreakpoint } from './grid';
import './global_overrides.css';
import { THEME_NAMES, ThemeNameValue } from './constants';

export {
  CoreTheme,
  EnterpriseTheme,
  EnterpriseDarkTheme,
  grid,
  media,
  atBreakpoint,
  PCTheme,
  StudioTheme,
  StudioDarkTheme,
  shouldUseStudioTheme,
};

export * from './constants';

export type Theme = ThemeType;

export type Media = MediaType;

export const THEMES: Record<ThemeNameValue, Theme> = {
  [THEME_NAMES.core]: CoreTheme,
  [THEME_NAMES.enterprise]: EnterpriseTheme,
  [THEME_NAMES.enterpriseDark]: EnterpriseDarkTheme,
  [THEME_NAMES.pc]: PCTheme,
  [THEME_NAMES.studio]: StudioTheme,
};
