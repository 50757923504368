import { parseBase64, useQueryParams, TokenParsed } from '@minecraft.utils';
import { DefaultContextParams, SwitchDefaultContextQueryParams } from '../types';

export interface ShouldSwitchParams {
  defaultAppContext?: DefaultContextParams;
  token?: TokenParsed;
}

export const shouldSwitchContext = ({ defaultAppContext, token }: ShouldSwitchParams) => {
  const contextCode = defaultAppContext?.roleCode;
  const contextArtist = defaultAppContext?.artistId;
  const contextOrg = defaultAppContext?.orgId;
  const tokenCode = token?.role?.code;
  const tokenArtist = token?.artistId;
  const tokenOrg = token?.organizationId;

  if (defaultAppContext && token && contextCode && tokenCode) {
    return (
      contextCode !== tokenCode ||
      (contextArtist && contextArtist !== tokenArtist) ||
      (contextOrg && contextOrg !== tokenOrg)
    );
  }

  return false;
};

export const useDefaultAppContext = () => {
  /* eslint-disable camelcase */
  const { default_app_context } = useQueryParams<SwitchDefaultContextQueryParams>();

  try {
    return default_app_context && parseBase64<DefaultContextParams>(decodeURIComponent(default_app_context));
  } catch (err) {
    // Nom nom nom
    // We simply return undefined if the parsing and decoding fail
    return undefined;
  }
};
