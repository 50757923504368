import _concat from 'lodash/concat';

export const MIN_LENGTH_PASSWORD = 8;

export const MAX_LENGTH_PASSWORD = 100;

const ERROR_CODES = {
  NULL_ERROR: 'Password must be at least 8 characters and include a number and a symbol',
  REGEX_ERROR_LENGTH: 'Please include at least 8 characters',
  REGEX_ERROR_NUMBER: 'Please include at least one number',
  REGEX_ERROR_SPCHAR: 'Please include at least one symbol',
  BANNEDWORD_ERROR: 'Must not be a common password',
};

const validateLength = (string) => {
  if (string.length >= MIN_LENGTH_PASSWORD && string.length <= MAX_LENGTH_PASSWORD) {
    return true;
  }

  return false;
};

const RgxAtleastNumber = /\d/;
const RgxAtleastSpChar = /\W/;
const bannedWords = ['qwerty', 'casting', 'network', 'password', '12345', 'letmein', 'football', 'iloveyou'];

/**
 * validatePassword
 * @param string
 * @return {{isMatch: boolean, errorCode: string}}
 */
const validatePassword = (string) => {
  let isMatch = true;
  let errorCode = '';

  if (string) {
    if (!validateLength(string)) {
      isMatch = false;
      errorCode = ERROR_CODES.REGEX_ERROR_LENGTH;
    } else if (!RgxAtleastNumber.test(string)) {
      isMatch = false;
      errorCode = ERROR_CODES.REGEX_ERROR_NUMBER;
    } else if (!RgxAtleastSpChar.test(string)) {
      isMatch = false;
      errorCode = ERROR_CODES.REGEX_ERROR_SPCHAR;
    }
  } else {
    isMatch = false;
    errorCode = ERROR_CODES.NULL_ERROR;
  }

  return { isMatch, errorCode };
};

/**
 * validatePasswordWithArray
 * @param string
 * @param commonWords
 * @return {{isMatch: boolean, errorCode: string}}
 */
const validatePasswordWithArray = (string, nameArray) => {
  const bannedWordsArray = _concat(bannedWords, nameArray);
  const validator = validatePassword(string);
  let {
    isMatch,
    errorCode,
  }: {
    isMatch: boolean;
    errorCode: string | string[];
  } = validator;

  if (isMatch) {
    // eslint-disable-next-line no-restricted-syntax
    for (const bannedWord of bannedWordsArray) {
      if (string.toLowerCase().indexOf(bannedWord.toLowerCase()) !== -1) {
        isMatch = false;
        errorCode = [ERROR_CODES.BANNEDWORD_ERROR];
        break;
      }
    }
  }

  return { isMatch, errorCode };
};

const Password = {
  ERROR_CODES,
  validatePassword,
  validatePasswordWithArray,
  MIN_LENGTH_PASSWORD,
  MAX_LENGTH_PASSWORD,
};

export default Password;
