import { Alert } from '../../TSTypes/alert';
import colors from '../../colors';

const alert: Alert = {
  type: {
    error: {
      backgroundColor: `${colors.accent.red}`,
      color: `${colors.primary.white}`,
    },
    progress: {
      backgroundColor: `${colors.accent.ltOrange}`,
      color: `${colors.primary.darkGrey}`,
    },
    warning: {
      backgroundColor: `${colors.accent.orange}`,
      color: `${colors.primary.darkGrey}`,
    },
    notify: {
      backgroundColor: `${colors.notifications.orange[100]}`,
      color: `${colors.primary.darkGrey}`,
    },
    success: {
      backgroundColor: `${colors.accent.green}`,
      color: `${colors.primary.darkGrey}`,
    },
  },
  size: {
    fullWidth: {
      borderRadius: '0px',
      padding: '18px',
      lineHeight: '1.25rem',
      position: 'absolute',
      left: '0',
    },
    inPage: {
      borderRadius: '4px',
      padding: '10px',
      lineHeight: '1.25rem',
      position: 'relative',
      left: '0',
    },
    cbPublish: {
      fontWeight1: '600',
      fontSize1: '1rem',
      fontWeight2: '500',
      fontSize2: '1.5rem',
      borderRadius: '0px',
      padding: '19px',
      lineHeight: '1.5rem',
      position: 'relative',
    },
  },
  sticky: {
    padding: '20px',
    position: 'fixed',
    top: '4px',
    left: '1.875rem',
    right: '1.875rem',
    zIndex: '100',
    width: 'auto',
  },
};

export default alert;
