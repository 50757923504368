import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import { useMutation, gql } from '@apollo/client';
import { resetIdentity } from '@blocs.heap';
import { getEnvironment } from '@minecraft.environment';
import { clearStorage, getRefreshToken, redirectToLogin } from '../utils';
import { PRODUCTION } from '../constants';

const environment = getEnvironment();

export const LOGOUT = gql`
  mutation logout($refreshToken: String!) {
    logout(refreshToken: $refreshToken)
  }
`;

const useLogout = (shouldRedirectBack = true, backUrl = '') => {
  const location = useLocation();
  const [logoutMutation, { ...state }] = useMutation(LOGOUT);
  const logout: any = useCallback(
    (ulaRedirectUrl = '') => {
      // call logout mutation and redirect regardless of the response
      logoutMutation({
        variables: {
          refreshToken: getRefreshToken(),
        },
      })
        .then((res) => {
          if (res?.errors?.[0]) {
            throw res.errors[0];
          }
        })
        .catch((e) => {
          console.error('logout mutation failed', { e });
        })
        .finally(() => {
          clearStorage();

          // heap user reset
          if (environment.ENV === PRODUCTION) {
            try {
              resetIdentity();
            } catch (e) {
              console.error(e);
            }
          }

          redirectToLogin(shouldRedirectBack, backUrl || location?.pathname, true, ulaRedirectUrl);
        });
    },
    [logoutMutation, shouldRedirectBack, backUrl, location?.pathname]
  );

  return useMemo(() => [logout, { ...state }], [logout, state]);
};

export default useLogout;
