import { gql } from '@apollo/client';

/**
 * @deprecated use `useGlobalNotification` instead
 */
export const SHOW_GLOBAL_ALERT = gql`
  mutation showGlobalAlert($globalAlertFields: GlobalAlert) {
    showGlobalAlert(globalAlertFields: $globalAlertFields) @client
  }
`;

/**
 * @deprecated use `useGlobalNotification` instead
 */
export const GET_GLOBAL_ALERT = gql`
  query GetGlobalAlert {
    globalAlert @client {
      type
      size
      text
      isCloseable
      sticky
      isShown
      isHtmlTag
      isTransTag
    }
  }
`;

/**
 * @deprecated use `useGlobalNotification` instead
 */
export const HIDE_GLOBAL_ALERT = gql`
  mutation hideGlobalAlert {
    hideGlobalAlert @client
  }
`;
