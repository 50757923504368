import { gqlSystemRoleCode } from '@minecraft.graphql-types';

export const getCastingPriorityRole = (roles: gqlSystemRoleCode[]) => {
  // if the user has multiple casting app roles and is going to be redirected there
  // set their default role as follows:
  // 1. if they have a CD role, it should be default
  // 2. if the have a STUDIO role, it should be next priority
  // 3. if they have PC role, it should be next priority
  // 4. if they have COLLAB (SHARED_PROJECT_USER) role, it should be next priority
  // 5. take the first entry in the array otherwise (this should never happen unless a new role type is added)
  if (roles?.includes(gqlSystemRoleCode.CASTING_DIRECTOR)) {
    return gqlSystemRoleCode.CASTING_DIRECTOR;
  }

  if (roles?.includes(gqlSystemRoleCode.STUDIO)) {
    return gqlSystemRoleCode.STUDIO;
  }

  if (roles?.includes(gqlSystemRoleCode.PROJECT_CREATOR)) {
    return gqlSystemRoleCode.PROJECT_CREATOR;
  }

  if (roles?.includes(gqlSystemRoleCode.SHARED_PROJECT_USER)) {
    return gqlSystemRoleCode.SHARED_PROJECT_USER;
  }

  return undefined;
};

export const getRepsPriorityRole = (roles: gqlSystemRoleCode[]) => {
  // if the user has multiple reps app roles and is going to be redirected there
  // set their default role as follows:
  // 1. if they have a AGENT role, it should be default
  // 2. if the have a MANAGER role, it should be next priority
  // 3. take the first entry in the array otherwise (this should never happen unless a new role type is added)
  if (roles?.includes(gqlSystemRoleCode.AGENT)) {
    return gqlSystemRoleCode.AGENT;
  }

  if (roles?.includes(gqlSystemRoleCode.MANAGER)) {
    return gqlSystemRoleCode.MANAGER;
  }

  return undefined;
};

export const getTalentPriorityRole = (roles: gqlSystemRoleCode[]) => {
  // if the user has multiple talent app roles and is going to be redirected there
  // set their default role as follows:
  // 1. if they have a TALENT role, it should be default
  // 2. take the first entry in the array otherwise (this should never happen unless a new role type is added)
  if (roles?.includes(gqlSystemRoleCode.TALENT)) {
    return gqlSystemRoleCode.TALENT;
  }

  return undefined;
};
