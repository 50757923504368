import _merge from 'lodash/merge';
import _cloneDeep from 'lodash/cloneDeep';
import { THEME_NAMES } from '../constants';
import { Theme } from '../TSTypes/theme';
import CoreTheme from '../CoreTheme';
import alert from './alert';
import button from './button';
import { checkbox, radio } from './checkboxAndRadio';
import common from './common';
import filters from './filters';
import form from './form';
import globalHeader from './globalHeader';
import meatballMenu from './meatballMenu';
import modal from './modal';
import tooltip from './tooltip';
import typography from './typography';
import { shouldUseStudioTheme } from './utils/shouldUseStudioTheme';
import { designSystem } from './designSystem';

const UniqueThemeFields = {
  name: THEME_NAMES.studio,
  alert,
  button,
  checkbox,
  radio,
  common,
  filters,
  form,
  globalHeader,
  meatballMenu,
  modal,
  tooltip,
  typography,
  designSystem,
};

const StudioTheme: Theme = _merge(_cloneDeep(CoreTheme), UniqueThemeFields);

export { shouldUseStudioTheme, StudioTheme };
