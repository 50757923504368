import { getEnvironment } from '@minecraft.environment';
import getRedirectQueryParams from './getRedirectQueryParams';

const redirectToRegister = (shouldRedirectBack = true, backUrl = '', clearStorage = true): void => {
  const environment = getEnvironment();
  let ulaUrl = environment['ULA_URL'];
  const audience = environment['AUDIENCE'];

  const queryParams = getRedirectQueryParams(clearStorage, shouldRedirectBack, backUrl);

  if (audience === 'talent') {
    ulaUrl = `${ulaUrl}/signup/TALENT`;
  }

  // remove 'register' param from url
  let redirectBackUrl = queryParams.get('redirectUrl');

  queryParams.delete('redirectUrl');
  redirectBackUrl = redirectBackUrl
    .split('/')
    .filter((part) => !part.toLowerCase().startsWith('register'))
    .join('/');
  queryParams.set('redirectUrl', redirectBackUrl);

  const redirectUrl = `${ulaUrl}/?${queryParams}`;

  // using window because login is a separate application
  window.location.replace(redirectUrl);
};

export default redirectToRegister;
