import { gqlCountryEnum as country, gqlTileSchemaValueEnum } from '@minecraft.graphql-types';
import { getEnvironment } from '@minecraft.environment';

const env = getEnvironment();
const cdnRoot = env?.CDN_URL;

// enums in gql are created from "keys" eg _1 -> {_1: '_1'}. So modifying here as we need values to be without '_'
export const modifiedTileSchemaValueEnum = Object.keys(gqlTileSchemaValueEnum).reduce((accumulator, key) => {
  return { ...accumulator, [key]: gqlTileSchemaValueEnum[key].replace(/^(_)/, '') };
}, {} as typeof gqlTileSchemaValueEnum);

const appConfig = {
  cdnRoot,
  svgRoot: `${cdnRoot}/images/icons/b21/`,
  photoExtensions: ['jpg', 'jpeg', 'png', 'gif'],
  videoTypes: ['mp4', 'mov', 'm4v', 'quicktime'], // mov files has type 'video/quicktime'
  pdfFileType: 'application/pdf',
  placeholderImgRoot: '//via.placeholder.com/',
  imperialDimensionsCountries: [country.US, country.CA],
  allCountriesCode: '00',
  // eslint-disable-next-line camelcase
  enumTagKeysToShow: ['_1', '_2', '_3', '_4', '_5', '_6', modifiedTileSchemaValueEnum.CB],
};

export default appConfig;
