const INSTANCES = [
  {
    label: 'Los Angeles (lacasting.com)',
    value: 'LA',
    region: 'LA',
    link: 'lacasting.com',
    item: 'LA Casting (Los Angeles)',
  },
  {
    label: 'San Francisco (sfcasting.com)',
    value: 'SF',
    region: 'SF',
    link: 'sfcasting.com',
    item: 'SF Casting (San Francisco)',
  },
  {
    label: 'USA & Canada (castingnetworks.com)',
    value: 'NY',
    region: 'NY',
    link: 'castingnetworks.com',
    item: 'Casting Networks USA and Canada (Excludes LA & SF)',
  },
  {
    label: 'UK (castingnetworks.co.uk)',
    value: 'EU',
    region: 'GB',
    link: 'castingnetworks.co.uk',
    item: 'Casting Networks UK/Europe',
  },
  {
    label: 'Australia (castingnetworks.com.au)',
    value: 'AU',
    region: 'AU',
    link: 'castingnetworks.com.au',
    item: 'Casting Networks Australia',
  },
];

export { INSTANCES as default };
