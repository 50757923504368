import { getEnvironment } from '@minecraft.environment';

interface UploadFileParams {
  file: File;
}

interface ZdAttachment {
  content_type: string;
  content_url: string;
  deleted: boolean;
  file_name: string;
  height?: number;
  id: number;
  inline: boolean;
  size: number;
  url: string;
  width?: number;
}

interface UploadFileOutput {
  upload: {
    token: string;
    expires_at: string;
    attachment: ZdAttachment;
  };
}

interface CreateRequestTicketParams {
  companyName: string;
  projectName: string;
  notes?: string;
  userEmail: string;
  userAccountId: number;
  username: string;
  uploadToken: string;
}

interface CreateRequestOutput {
  request: {
    collaborator_ids: number[];
    created_at: string;
    description: string;
    email_cc_ids: number[];
    id: number;
    is_public: boolean;
    requester_id: number;
    subject: string;
    updated_at: string;
    url: string;
  };
}

const getFileData = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString());
    reader.onerror = (error) => reject(error);
  });
};

const getContentType = async (file: File): Promise<string> => {
  if (file.type) {
    return Promise.resolve(file.type);
  }

  const fileData = await getFileData(file);
  // file data is a string with the following structure:
  // data:<mime-type>;base64,<base64-file-data>
  // we need to grab the <mime-type> out of that format
  const contentType = fileData?.split(';')?.[0]?.split(':')?.[1];

  if (!contentType) {
    throw new Error('could not determine the file type');
  }

  return contentType;
};

export const ZendeskSDK = {
  uploadFile: async ({ file }: UploadFileParams): Promise<UploadFileOutput['upload']> => {
    // while the native File object has a `type` it is not always set depending on
    // whether the browser knows a type for the file extension.
    // If it's not set we need to determine it by reading the actual file data
    // and parsing the resulting string, because Zendesk's api will reject
    // the upload if the content type header does not match the file being uploaded
    const contentType = await getContentType(file);
    const apiBase = getEnvironment().ZENDESK_API_ENDPOINT;
    const res = await fetch(`${apiBase}/api/v2/uploads?filename=${file.name}`, {
      method: 'POST',
      headers: { 'Content-Type': contentType },
      body: file,
    });

    const json = await res.json();

    return (json as UploadFileOutput)?.upload;
  },
  createRequestTicket: async ({
    companyName,
    projectName,
    notes = '',
    uploadToken,
    userAccountId,
    username,
    userEmail,
  }: CreateRequestTicketParams): Promise<CreateRequestOutput['request']> => {
    const apiBase = getEnvironment().ZENDESK_API_ENDPOINT;
    const messageBody = `
      Requesting User Account Id: ${userAccountId}
      Requesting User Name: ${username}
      Requesting User Email: ${userEmail}
      Request Notes: ${notes}
    `;
    /* eslint-disable camelcase */
    const res = await fetch(`${apiBase}/api/v2/requests`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        request: {
          subject: `Cast It Studio Breakdown Creation Requested from ${companyName} for ${projectName}`,
          comment: { body: messageBody, uploads: uploadToken ? [uploadToken] : [] },
          requester: { name: username, email: userEmail },
          email_ccs: [{ user_email: userEmail, user_name: username, action: 'put' }],
        },
      }),
    });

    const json = await res.json();

    return (json as CreateRequestOutput)?.request;
  },
};
