import React, { FC, PropsWithChildren } from 'react';

export type ListGroupProps = {
  className?: string;
};

export type ListGroupItemProps = {
  className?: string;
};

/**
 * This is a simple rendering of the Bootstrap ListGroup component.
 * [Figma Design](https://www.figma.com/file/AiDAD1WpprgfbZI2XV6hU1/Casting-Networks-Design-System?node-id=1143%3A3&t=srUGHMuyPhVcq8XC-0)
 * (see the "Role" section) - this is useful for mobile views
 *
 * @example
 * <UnorderedListGroup className="cn_m-2">
 *   <ListGroupItem>Item 1</ListGroupItem>
 *   <ListGroupItem>Item 2</ListGroupItem>
 * </UnorderedListGroup>
 */
export const UnorderedListGroup: FC<PropsWithChildren<ListGroupProps>> = ({ children, className }) => {
  return <ul className={`list-group ${className ?? ''}`}>{children}</ul>;
};

export const ListGroupItem: FC<PropsWithChildren<ListGroupItemProps>> = ({ children, className }) => {
  return <li className={`list-group-item ${className ?? ''}`}>{children}</li>;
};
