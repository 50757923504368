import { useEffect } from 'react';
import _difference from 'lodash/difference';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import {
  gqlAuthToken,
  gqlSystemRoleCode as SYSTEM_ROLE,
  gqlAccountStatusEnum as ACCOUNT_STATUS,
} from '@minecraft.graphql-types';
import { TokenParsed, TokenApp } from '@minecraft.utils';
import { getEnvironment } from '@minecraft.environment';
import { SystemRoleCode } from '@minecraft.graphql-operations';
import { setHeapIdentity } from '@blocs.heap';
import {
  parseJwt,
  setAccessToken,
  setRefreshToken,
  getAccessToken,
  getRefreshToken,
  deleteOnboardingToken,
} from '../utils';
import {
  REPS_URL,
  TALENT_URL,
  CASTING_URL,
  ROLES,
  QUERY_PARAMS,
  APPLICATIONS,
  AUDIENCE_TOKEN,
  ApplicationValue,
} from '../constants';

const getEnv = (key: string): string => {
  const environment = getEnvironment();
  const envValue = environment[key];

  return envValue || process.env[key];
};

export const getAppValueForTokenApp = (value?: TokenApp): ApplicationValue => {
  switch (value) {
    case 'talent':
      return APPLICATIONS.talent;
    case 'representative':
      return APPLICATIONS.reps;
    case 'casting':
      return APPLICATIONS.casting;
    default:
      return undefined;
  }
};

export const getAudience = (role: SYSTEM_ROLE | SystemRoleCode): ApplicationValue | null => {
  switch (role) {
    case SYSTEM_ROLE.TALENT:
      return APPLICATIONS.talent;
    case SYSTEM_ROLE.PROJECT_CREATOR:
    case SYSTEM_ROLE.CASTING_DIRECTOR:
    case SYSTEM_ROLE.STUDIO:
    case SYSTEM_ROLE.CREATIVE:
    case SYSTEM_ROLE.SHARED_PROJECT_USER:
      return APPLICATIONS.casting;
    case SYSTEM_ROLE.AGENT:
    case SYSTEM_ROLE.MANAGER:
      return APPLICATIONS.reps;
    default:
      return null;
  }
};

export const getUrlForAudience = (audience: ApplicationValue): string => {
  let url: string;

  switch (audience) {
    case APPLICATIONS.talent:
      url = getEnv(TALENT_URL);
      break;
    case APPLICATIONS.reps:
      url = getEnv(REPS_URL);
      break;
    case APPLICATIONS.casting:
      url = getEnv(CASTING_URL);
      break;
    default:
  }

  return url;
};

export const redirectToAudience = (audience: ApplicationValue, redirectTo = ''): void => {
  const url = getUrlForAudience(audience);
  const couponCode = audience === APPLICATIONS.talent ? localStorage.getItem(QUERY_PARAMS.couponCode) : undefined;

  // Next router can't redirect to external link, so using window here
  if (url) {
    const refreshToken = getRefreshToken();
    const queryParams = new URLSearchParams(redirectTo);

    if (couponCode) {
      queryParams.append(QUERY_PARAMS.couponCode, couponCode);
      localStorage.removeItem(QUERY_PARAMS.couponCode);
    }

    const generatedUrl = `${url}/authenticate/${refreshToken}?${queryParams.toString()}`;

    window.location.replace(generatedUrl);
  }
};

export const buildRedirectQueryParams = (
  query: URLSearchParams,
  organizationIds: number[],
  isFromOnboarding: boolean,
  parsedAudienceToken?: TokenParsed
) => {
  const queryRedirectUrl = query.get(QUERY_PARAMS.redirectUrl);
  const queryDivisionId = query.get(QUERY_PARAMS.divisionId);
  const requestTypeFromQuery = query.get(QUERY_PARAMS.requestType);
  const projectIdsFromQuery = query.get(QUERY_PARAMS.projectIds);
  const slotDateToFromQuery = query.get(QUERY_PARAMS.slotDateTo);
  const slotDateFromFromQuery = query.get(QUERY_PARAMS.slotDateFrom);
  const mediaQuestionDueDateToFromQuery = query.get(QUERY_PARAMS.dueDateTo);
  const mediaQuestionDueDateFromFromQuery = query.get(QUERY_PARAMS.dueDateFrom);
  const savedSearchId = query.get(QUERY_PARAMS.savedSearchId);
  const defaultAppContext = query.get(QUERY_PARAMS.defaultAppContext);

  let redirectTo = `?isFromOnboarding=${isFromOnboarding}`;

  // Keep redirect query params during auth.
  if (queryRedirectUrl) {
    redirectTo = `?redirectTo=${queryRedirectUrl}`;
    redirectTo = queryDivisionId ? `${redirectTo}&division_id=${queryDivisionId}` : redirectTo;
    redirectTo = requestTypeFromQuery ? `${redirectTo}&requestType=${requestTypeFromQuery}` : redirectTo;
    redirectTo = projectIdsFromQuery ? `${redirectTo}&projectIds=${projectIdsFromQuery}` : redirectTo;
    redirectTo = slotDateToFromQuery ? `${redirectTo}&slotDateTo=${slotDateToFromQuery}` : redirectTo;
    redirectTo = slotDateFromFromQuery ? `${redirectTo}&slotDateFrom=${slotDateFromFromQuery}` : redirectTo;
    redirectTo = mediaQuestionDueDateToFromQuery
      ? `${redirectTo}&dueDateTo=${mediaQuestionDueDateToFromQuery}`
      : redirectTo;
    redirectTo = mediaQuestionDueDateFromFromQuery
      ? `${redirectTo}&dueDateFrom=${mediaQuestionDueDateFromFromQuery}`
      : redirectTo;
    redirectTo = savedSearchId ? `${redirectTo}&savedSearchId=${savedSearchId}` : redirectTo;

    if (defaultAppContext) {
      // Preserve defaultAppContext query param if the user is an artist
      const tokenInfo = parseJwt(getAccessToken());

      if (tokenInfo?.artistId) {
        redirectTo = `${redirectTo}&default_app_context=${defaultAppContext}`;
      }
    }
  }

  if (Array.isArray(organizationIds) && Array.isArray(parsedAudienceToken?.organizations)) {
    const newOrganization = _difference(organizationIds, parsedAudienceToken.organizations)[0];

    redirectTo = newOrganization ? `${redirectTo}&organizationId=${newOrganization}` : redirectTo;
  }

  if (parsedAudienceToken) redirectTo = `${redirectTo}&isAdditionalAccount=true`;

  return redirectTo;
};

interface RedirectByRoleParams {
  token?: gqlAuthToken;
  selectedAudience?: SYSTEM_ROLE;
  audienceToken?: string;
  isFromOnboarding?: boolean;
  router: History; // this comes from react-router-dom's useHistory hook
}

export const redirectByRole = ({
  router,
  token,
  selectedAudience,
  audienceToken,
  isFromOnboarding,
}: RedirectByRoleParams) => {
  const query = new URLSearchParams(router.location.search);
  const queryRedirectAudience = query.get(QUERY_PARAMS.redirectAudience);
  const parsed: TokenParsed = token?.access && parseJwt(token.access);
  const parsedAudienceToken: TokenParsed = audienceToken && parseJwt(audienceToken);

  if (!parsed || (audienceToken && !parsedAudienceToken)) return;

  setAccessToken(token.access);
  setRefreshToken(token.refresh);

  if (parsed.status === ACCOUNT_STATUS.UNVERIFIED) {
    const queryParams = new URLSearchParams({
      email: parsed.email,
    });

    router.push(`/verify-email?${queryParams}`);

    return;
  }

  if (parsed.status === ACCOUNT_STATUS.INCOMPLETE) {
    const isPC = parsed?.roles?.includes(SYSTEM_ROLE.PROJECT_CREATOR);

    // No current requirements for other types of accounts marked as incomplete
    if (isPC) {
      router.push('/last-step');

      return;
    }
  }

  if (isFromOnboarding) deleteOnboardingToken(false);

  setHeapIdentity(token?.access);

  // if there is audience query or passed param we should take it and redirect to this page based on environment
  const audience = selectedAudience
    ? getAudience(selectedAudience)
    : (String(queryRedirectAudience) as ApplicationValue);

  // get availableAudience from roles in order to not redirect to forbidden application
  const availableAudience = parsed.roles.map((role) => getAudience(role));
  const redirectTo = buildRedirectQueryParams(query, parsed?.organizations, isFromOnboarding, parsedAudienceToken);

  localStorage.setItem(ROLES, JSON.stringify(parsed.roles));
  localStorage.removeItem(AUDIENCE_TOKEN);

  // redirect to audience app if user has appropriate role
  if (audience && availableAudience.includes(audience)) {
    redirectToAudience(audience, redirectTo);
  }

  // if there is no audience query or audience apropriate role and only 1 role redirect to audience app
  else if (availableAudience.length === 1) {
    redirectToAudience(availableAudience[0], redirectTo);
  }
  // if user has several system roles, but one of them is Agent or Manager - we are redirecting him to reps
  // this case can happen only in case of dirty data
  else if (
    availableAudience.length > 1 &&
    (parsed.roles.includes(SYSTEM_ROLE.AGENT) || parsed.roles.includes(SYSTEM_ROLE.MANAGER))
  ) {
    redirectToAudience('reps', redirectTo);
  }
  // redirect to select role page if there is multiple roles available
  else {
    // since hook could be used in any application we should redirect user to ula's disambiguation screen
    window.location.assign('/login/disambiguation');
  }
};

export type UseRedirectByRoleFn = (
  token?: gqlAuthToken,
  selectedAudience?: SYSTEM_ROLE,
  audienceToken?: string,
  isFromOnboarding?: boolean
) => void;

export const useRedirectByRole: UseRedirectByRoleFn = (
  token,
  selectedAudience,
  audienceToken,
  isFromOnboarding = false
) => {
  const router = useHistory();

  useEffect(() => {
    redirectByRole({ token, router, selectedAudience, audienceToken, isFromOnboarding });
  }, [token, audienceToken, selectedAudience, isFromOnboarding, router]);
};
