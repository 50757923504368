import colors from '../../colors';

const meatballMenu = {
  backgroundColor: colors.primary.white,
  color: colors.primary.darkGrey,
  borderColor: colors.primary.ltGrey1,
  state: {
    disabled: {
      color: colors.primary.ltGrey1,
    },
  },
  item: {
    state: {
      hover: {
        backgroundColor: colors.backgrounds.grey1,
      },
    },
  },
};

export default meatballMenu;
