import _merge from 'lodash/merge';
import _cloneDeep from 'lodash/cloneDeep';
import { THEME_NAMES } from '../constants';
import { Theme } from '../TSTypes/theme';
import EnterpriseTheme from '../EnterpriseTheme';
import alert from './alert';
import button from './button';
import { checkbox, radio } from './checkboxAndRadio';
import common from './common';
import filters from './filters';
import form from './form';
import globalHeader from './globalHeader';
import meatballMenu from './meatballMenu';
import modal from './modal';
import tooltip from './tooltip';
import typography from './typography';
import { designSystem } from './designSystem';

const UniqueThemeFields = {
  name: THEME_NAMES.enterpriseDark,
  alert,
  button,
  checkbox,
  common,
  filters,
  form,
  globalHeader,
  meatballMenu,
  modal,
  radio,
  tooltip,
  typography,
  designSystem,
};

const EnterpriseDarkTheme: Theme = _merge(_cloneDeep(EnterpriseTheme), UniqueThemeFields);

export default EnterpriseDarkTheme;
