import React from 'react';
import { Navbar } from 'reactstrap';
import styled from 'styled-components';

const StyledNavbar = styled(Navbar)`
  padding: 0.8rem 1.125rem;
  border-bottom: 0.0625rem solid ${({ theme }) => theme.colors.primary.ltGrey1};
`;

const StyledLogo = styled.img`
  width: 9.375rem;
  height: 2.25rem;
  margin-right: 0.5rem;
`;

type Props = {
  logoImage: string;
};

const GlobalPublicHeader = ({ logoImage }: Props): JSX.Element => {
  return (
    <StyledNavbar light>
      <StyledLogo src={logoImage} />
    </StyledNavbar>
  );
};

export default GlobalPublicHeader;
