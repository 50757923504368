import { ApolloLink, FetchResult, NextLink, Observable, Operation } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { getRouterHistory } from '@minecraft.utils';
import { getEnvironment } from '@minecraft.environment';
import { captureGqlAndNetworkErrors } from '../../../Sentry/Sentry';
import { clearStorage, getListsFeatureDisabledResponse, getUnauthorizedResponse, redirectToLogin } from '../../utils';
import { SCHEDULED_MAINTENANCE } from '../../constants';
import { refreshTokenApiCall } from '../refreshToken';

type ErrorHandlerFn = (input: {
  graphQLErrors: readonly GraphQLError[];
  networkError?: any;
  response?: FetchResult;
}) => void;

const handleUnauthorized = (redirectTo?: string) => {
  clearStorage();

  if (redirectTo) {
    getRouterHistory().push(redirectTo);

    return;
  }

  redirectToLogin();
};

// this refreshes the tokens and if successful will retry the original request again
const refreshTokenAndRetry = (
  observer: ZenObservable.SubscriptionObserver<any>,
  forward: NextLink,
  operation: Operation,
  errorHandler: ErrorHandlerFn,
  redirectTo?: string
) => {
  refreshTokenApiCall({})
    .then((res) => {
      if (res?.errors) {
        handleUnauthorized(redirectTo);

        observer.error(operation);

        return null;
      }

      // eslint-disable-next-line
      forwardOperation(observer, forward, operation, errorHandler, redirectTo, true);

      return null;
    })
    .catch(() => {
      handleUnauthorized(redirectTo);

      observer.error(operation);

      return null;
    });
};

// this version does the refresh token call, but does not retry to request
const refreshTokenAndReturn = (
  observer: ZenObservable.SubscriptionObserver<any>,
  operation: Operation,
  redirectTo?: string
) => {
  refreshTokenApiCall({})
    .then(() => {
      observer.error(operation);

      return null;
    })
    .catch(() => {
      handleUnauthorized(redirectTo);
      observer.error(operation);

      return null;
    });
};

const forwardOperation = (
  observer: ZenObservable.SubscriptionObserver<any>,
  forward: NextLink,
  operation: Operation,
  errorHandler: ErrorHandlerFn,
  redirectTo?: string,
  tokenRefreshed = false,
  doNotRefreshToken = false
) => {
  let unauthorizedResponse;

  forward(operation).subscribe({
    next: (result) => {
      if (result?.errors) {
        unauthorizedResponse = getUnauthorizedResponse(result);
        const listsFeatureDisabledResponse = getListsFeatureDisabledResponse(result?.errors);

        // if we need to fire refreshToken query
        if (!doNotRefreshToken && !tokenRefreshed && unauthorizedResponse) {
          return refreshTokenAndRetry(observer, forward, operation, errorHandler, redirectTo);
        }

        if (listsFeatureDisabledResponse) {
          // if any request returns this error, we need to refresh the tokens
          // which will update the UserContext.tokens state, which will recompute
          // the permissions and redirect the user to the correct page
          // with the lists feature hidden
          return refreshTokenAndReturn(observer, operation, redirectTo);
        }

        if (unauthorizedResponse) {
          handleUnauthorized(redirectTo);
          observer.error(result);

          return null;
        }

        // the current implementations setup the errorHandler
        // is setup to pass the error to Sentry for logging
        // and also outputs the result to console.error
        // see Sentry.ts captureGqlAndNetworkErrors
        errorHandler({
          graphQLErrors: result.errors,
          response: result,
        });
      }

      observer.next(result);

      return null;
    },
    error: (networkError) => {
      // Handle Maintenance mode error
      if (networkError?.statusCode === 503) {
        const maintenanceType = networkError?.bodyText === SCHEDULED_MAINTENANCE ? 'scheduled' : 'unscheduled';

        window.location.replace(`${getEnvironment().BASE_NAME}/maintenance?mode=${maintenanceType}`);
      }

      unauthorizedResponse = getUnauthorizedResponse(networkError);

      // if we need to fire refreshToken query
      if (!doNotRefreshToken && !tokenRefreshed && unauthorizedResponse) {
        return refreshTokenAndRetry(observer, forward, operation, errorHandler, redirectTo);
      }

      if (unauthorizedResponse) {
        handleUnauthorized(redirectTo);
        observer.error(networkError);

        return null;
      }

      errorHandler({
        networkError,
        graphQLErrors: networkError && networkError.result && networkError.result.errors,
      });

      observer.error(networkError);

      return null;
    },
    complete: () => {
      if (unauthorizedResponse && !tokenRefreshed) return;

      observer.complete();
    },
  });
};

// doNotRefreshToken - use for not unified login, when we have no refreshToken
export const authErrorLink = (errorHandler: ErrorHandlerFn, redirectTo?: string, doNotRefreshToken = false) => {
  return new ApolloLink((operation, forward) => {
    return new Observable((observer) =>
      forwardOperation(observer, forward, operation, errorHandler, redirectTo, false, doNotRefreshToken)
    );
  });
};

export const DEFAULT_ERROR_HANDLER: ErrorHandlerFn = (error) => {
  // Handle any error which is not 401
  captureGqlAndNetworkErrors(error);
  console.error('apollo error link', { error });
};

interface BuiltAuthErrorLinkParams {
  errorHandler?: ErrorHandlerFn;
  redirectTo?: string;
}

export const buildAuthErrorLink = ({ errorHandler = DEFAULT_ERROR_HANDLER, redirectTo }: BuiltAuthErrorLinkParams) =>
  authErrorLink(errorHandler, redirectTo);
