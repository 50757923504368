import colors from '../../colors';
import { Color } from '../../TSTypes/button';

const linkColorState = {
  default: {
    color: colors.primary.darkGrey,
    backgroundColor: colors.buttons.link.default,
    border: 'none',
    textDecoration: 'underline',
  },
  hover: {
    color: colors.primary.darkGrey,
    backgroundColor: colors.buttons.link.hover,
    border: 'none',
    textDecoration: 'underline',
  },
  focus: {
    color: colors.primary.darkGrey,
    backgroundColor: colors.buttons.link.hover,
    border: 'none',
    textDecoration: 'underline',
  },
  active: {
    color: colors.primary.darkGrey,
    backgroundColor: colors.buttons.link.active,
    border: 'none',
    textDecoration: 'underline',
  },
  disabled: {
    color: colors.primary.grey2,
    backgroundColor: colors.buttons.link.disabled,
    border: 'none',
    textDecoration: 'underline',
  },
};

const LinkColor: Color = {
  outlined: {
    true: {
      state: linkColorState,
    },
    false: {
      state: linkColorState,
    },
  },
};

export default LinkColor;
