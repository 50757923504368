import Password from './validators/Password';
import Email from './validators/Email';
import Phone from './validators/Phone';
import HasPattern from './validators/HasPattern';
import MaxLength from './validators/MaxLength';
import Media from './validators/Media';
import MinLength from './validators/MinLength';
import Required from './validators/Required';

const Validator = {
  Password, // exports validatePassword, ERROR_CODES, validatePasswordWithArray
  Email, // exports validateEmail, validateWhiteSpaceString
  Phone, // exports isValidPhoneNumber
  Media, // exports validateMedia
  HasPattern,
  MaxLength,
  MinLength,
  Required,
};

export default Validator;
