import React, { useEffect, FC } from 'react';
import styled from 'styled-components';
import { useZendesk } from '@blocs.zendesk';
import { BasicContainer, FlexDynamic } from './Containers';

const StyledFixedFooter = styled(BasicContainer)`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 4rem;
  z-index: 1001;
  box-shadow: 0.0145rem 0.029rem 0.174rem rgba(27, 40, 50, 0.01698), 0.0335rem 0.067rem 0.402rem rgba(27, 40, 50, 0.024),
    0.0625rem 0.125rem 0.75rem rgba(27, 40, 50, 0.03), 0.1125rem 0.225rem 1.35rem rgba(27, 40, 50, 0.036),
    0.2085rem 0.417rem 2.502rem rgba(27, 40, 50, 0.04302), 0.5rem 1rem 6rem rgba(27, 40, 50, 0.06),
    0 0 0 0.0625rem rgba(27, 40, 50, 0.015);
`;

const StyledFlexDynamic = styled(FlexDynamic)`
  max-width: 1440px;
  margin: auto;
`;

export type FixedFooterProps = {
  StartSlot?: React.ReactNode;
  EndSlot?: React.ReactNode;
  'data-testid'?: string;
  'data-qa-id'?: string;
};

export const FixedFooter: FC<FixedFooterProps> = ({
  'data-testid': dataTestId,
  'data-qa-id': dataQaId,
  StartSlot,
  EndSlot,
}) => {
  const { hide: hideZendesk, show: showZendesk } = useZendesk({ shouldHide: true });

  useEffect(() => {
    // When this component is rendered we hide zendesk
    hideZendesk();

    return () => {
      // When unmounted we re-show zendesk
      showZendesk();
    };
    // Adding all  dependencies in this effect causes zendesk to flicker
  }, []);

  return (
    <>
      <StyledFixedFooter
        data-testid={dataTestId || 'fixed-footer-container'}
        data-qa-id={dataQaId}
        bgColor="card"
        padding={3}
        customBorders={{ top: { borderColor: 'default', borderSize: 0.06 } }}
      >
        <StyledFlexDynamic
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
          gap="4"
          mdProps={{ direction: 'row' }}
        >
          {/*
          the balance here between grow/shrink and the max width / width settings of the start
          and end slots is to ensure that the end slot will grow to allow buttons to take up
          as much space as they need while the start slot will shrink to accomodate

          while also allowing the start slot to grow when there is available space
          so that the label text that normally goes in that slot does not get cramped

          there are separate accomodations for mobile so that the buttons will expande to
          take up the full width of the container
           */}
          <FlexDynamic maxW="max" grow={1} shrink={4} data-testid="start-slot">
            {StartSlot}
          </FlexDynamic>
          <FlexDynamic w="full" grow={2} shrink={1} data-testid="end-slot" mdProps={{ maxW: 'max' }}>
            {EndSlot}
          </FlexDynamic>
        </StyledFlexDynamic>
      </StyledFixedFooter>
      <div style={{ height: '4rem' }} />
    </>
  );
};
