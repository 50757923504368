import _merge from 'lodash/merge';
import _cloneDeep from 'lodash/cloneDeep';
import { THEME_NAMES } from '../constants';
import { Theme } from '../TSTypes/theme';
import EnterpriseDarkTheme from '../EnterpriseDarkTheme';
import button from './button';
import typography from './typography';
import { designSystem } from './designSystem';

const UniqueThemeFields = {
  name: THEME_NAMES.studio,
  button,
  typography,
  designSystem,
};

export const StudioDarkTheme: Theme = _merge(_cloneDeep(EnterpriseDarkTheme), UniqueThemeFields);
