import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PrimaryButton } from './NamedButtons';

export type ButtonAsLinkProps = {
  to: string;
  target?: '_blank';
};

export const ButtonAsLink: FC<ButtonAsLinkProps & Omit<React.ComponentProps<typeof PrimaryButton>, 'onClick'>> = ({
  to,
  target,
  disabled,
  /**
   * ButtonAsLink is normally an outlined style button
   * which is opposite of the typical button
   * */
  variant = 'secondary',
  ...buttonProps
}) => {
  const as = useMemo(() => {
    // We want this to be a real button if it's disabled, this prevents any clicking and behaves like a button
    if (disabled) return undefined;

    if (to.startsWith('https://')) {
      return 'a';
    }

    return Link;
  }, [disabled, to]);

  const resolvedTo = useMemo(() => {
    if (!disabled && !to.startsWith('https://')) {
      return to;
    }

    return undefined;
  }, [disabled, to]);

  const resolvedHref = useMemo(() => {
    if (!disabled && to.startsWith('https://')) {
      return to;
    }

    return undefined;
  }, [disabled, to]);

  return (
    <PrimaryButton
      {...buttonProps}
      disabled={disabled}
      as={as}
      to={resolvedTo}
      href={resolvedHref}
      target={target}
      variant={variant}
    />
  );
};
