import { gql } from '@apollo/client';
import {
  gqlAccountStatusEnum,
  gqlAuthToken,
  gqlQueryVerifyAccountArgs,
  gqlSystemRoleCode,
} from '@minecraft.graphql-types';

export type LoginInput = gqlQueryVerifyAccountArgs;

export type B21Token = {
  loginType: 'B21';
  token: {
    access: string;
    refresh: string;
  };
};

export type OnboardingToken = {
  loginType: 'Onboarding';
  token: string;
  accountStatusCode: gqlAccountStatusEnum;
};

export interface LoginOutput {
  verifyAccount: B21Token | OnboardingToken;
}

export const QUERY_B21_AND_BAU_LOGIN = gql`
  query verifyAccount($input: VerifyAccountInput!) {
    verifyAccount(input: $input)
  }
`;

export type SwitchContextInput = {
  refreshToken: string;
  role?: gqlSystemRoleCode;
  artistId?: number;
  organizationId?: number;
  app?: string;
};

export type SwitchContextOutput = { switchAuthContext: gqlAuthToken };

export const SWITCH_AUTH_CONTEXT = gql`
  mutation switchAuthContext($refreshToken: String!, $role: SystemRoleCode, $artistId: Int, $organizationId: Int) {
    switchAuthContext(refreshToken: $refreshToken, role: $role, artistId: $artistId, organizationId: $organizationId) {
      access
      refresh
    }
  }
`;
