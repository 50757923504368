import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from '@blocs.i18n';

/* ----- Styled Components ----- */
const StyledMobileLogout = styled.div`
  width: 100%;
  padding: 2rem 2rem 0;
  background-color: ${(props) => props.theme.globalHeader.colors.mobileLinkBackground};
  font-size: 1rem;
`;

const StyledLinkWrapper = styled.div`
  padding: 1.5rem 0;
  border-top: 1px solid ${(props) => props.theme.globalHeader.colors.border};
`;

const StyledLink = styled.a`
  display: block;
  padding: 0.5rem 0;

  &:not([href]):not([tabindex]):hover {
    text-decoration: none;
  }
  &&& {
    &,
    &:hover,
    &:active,
    &:focus {
      color: ${(props) => props.theme.globalHeader.colors.mobileLink};
    }
  }
`;

/* ----- Types ----- */
type Props = {
  onClick: () => void;
};

/* ----- Main Component ----- */
const MobileLogout: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <StyledMobileLogout>
      <StyledLinkWrapper>
        <StyledLink onClick={onClick}>{t('common:mainNav.logOut')}</StyledLink>
      </StyledLinkWrapper>
    </StyledMobileLogout>
  );
};

export default MobileLogout;
