import { gqlAccountStatusEnum as AccountStatus } from '@minecraft.graphql-types';
import { TranslateFn } from '../../I18N/types';

interface ErrorInput {
  data?: {
    errorData?: {
      status?: string;
      statusCode?: number;
    };
  };
}
type MessageInput = ErrorInput | string;

export const getErrorAccountStatus = (obj: MessageInput): string | number | undefined => {
  return (
    (obj as ErrorInput)?.data?.errorData?.status || (obj as ErrorInput)?.data?.errorData?.statusCode || (obj as string)
  );
};

const getClosedOrLockedErrorMessage = (
  t: TranslateFn,
  errorOrStatus: MessageInput,
  isLoginPage = true
): string | JSX.Element => {
  const status = getErrorAccountStatus(errorOrStatus);

  if (status === AccountStatus.CLOSED) {
    if (isLoginPage) {
      return t('common:globalError.accountClosedLogin');
    }

    return t('common:globalError.accountClosed');
  }

  if (status === AccountStatus.LOCKED) {
    return t('common:globalError.accountLocked');
  }

  return '';
};

export default getClosedOrLockedErrorMessage;
