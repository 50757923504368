import React, { FC, memo, MouseEventHandler } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import { useTranslation } from '@blocs.i18n';
import { media, EnterpriseTheme as lightTheme, EnterpriseDarkTheme as darkTheme } from '@minecraft.themes';
import { useThemeSwitcherContext } from '@minecraft.theme-switcher';
import Button from '@minecraft.button';
import { ButtonProps, ButtonsWrapper } from '@minecraft.atoms';
import Icon from '@minecraft.icon';
import { Loading } from '@minecraft.loader';
import { Button as ModernButton } from '../../atoms/Button/Button';

export const StyledModal = styled(Modal)`
  margin: 0;
  height: 100%;
  width: 100vw;
  & > div {
    min-height: 100%;
    width: 100%;
  }

  .modal-content {
    background-color: ${(props): string => props.theme.modal.backgroundColor};
    color: ${(props): string => props.theme.modal.color};
  }

  ${media.sm`
    margin: 1.75rem auto;
    height: auto;
    width: auto;
    max-width: 31.25rem;
  `}

  ${media.md`
    max-width:  ${(props) => {
      if (props.$modalSize === 'small') {
        return '31.25rem';
      }

      if (props.$modalSize === 'medium') {
        return '50rem';
      }

      if (props.$modalSize === 'large') {
        return '50rem';
      }

      return '';
    }}
  `};

  ${media.lg`
    max-width:  ${(props) => {
      if (props.$modalSize === 'small') {
        return '31.25rem';
      }

      if (props.$modalSize === 'medium') {
        return '50rem';
      }

      if (props.$modalSize === 'large') {
        return '60rem';
      }

      return '';
    }}
  `};
`;

export const StyledCloseButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${(props): string => props.theme.modal.color};
  cursor: pointer;
  padding: 1rem;
  line-height: 1;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

  ${media.xsm`
    padding-top: 1.6875rem;
  `}
  ${media.sm`
    padding-top: 1rem;
  `}
`;

export const CloseIcon = styled(Icon)`
  top: 1.6875rem;
  right: 1rem;
  height: 1.5rem;
  width: 1.5rem;

  ${media.sm`
    height: 1rem;
    width: 1rem;
  `}
`;

type CustomPadding = { top?: number; left?: number; right?: number; bottom?: number };
const calculatePadding = (customPadding?: CustomPadding & { mdProps?: CustomPadding }, moreMd = false): string => {
  const { mdProps, top, left, bottom, right } = customPadding || {};
  const combinedSizes: CustomPadding = moreMd ? { top, left, bottom, right, ...mdProps } : { top, left, bottom, right };

  if (
    typeof combinedSizes?.top === 'number' &&
    typeof combinedSizes?.bottom === 'number' &&
    typeof combinedSizes?.left === 'number' &&
    typeof combinedSizes?.right === 'number'
  ) {
    return `${combinedSizes.top}rem ${combinedSizes.right}rem ${combinedSizes.bottom}rem ${combinedSizes.left}rem`;
  }

  return moreMd ? '2rem 2rem 2rem 1.5rem' : '1.6875rem 2rem 1.8125rem 1.5rem';
};

export const StyledModalHeader = styled(ModalHeader)`
  border-bottom: none;
  margin: 0 1rem 0 1rem;
  padding: ${({ $customHeaderPadding }) => calculatePadding($customHeaderPadding, true)};
  border-bottom: 0.0625rem solid ${(props): string => props.theme.modal.borderColor};
  h5 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  ${({ $centerTitle }) => ($centerTitle ? 'display: flex; justify-content: center;' : '')}

  ${media.sm`
    margin: 0;
    padding: ${({ $customHeaderPadding }) => calculatePadding($customHeaderPadding, false)};
    border: none;
    h5 {
      font-size: 1.5rem;
    }
  `}
`;

export const StyledModalBody = styled(ModalBody)<{ $padHeading?: boolean }>`
  font-size: 1rem;
  font-weight: 400;
  padding: ${({ $padHeading }) => {
      if ($padHeading) {
        return '3.5rem 1rem 1.5rem 1rem';
      }

      return '0 1rem 1.5rem 1rem';
    }}
    ${media.sm`
    padding: ${({ $padHeading }) => {
      if ($padHeading) {
        return '1.5rem 1rem 1.5rem 1rem';
      }

      return '0 2rem 1.5rem 1.5rem';
    }}
  `};
`;

export const StyledModalFooter = styled(ModalFooter)`
  border-top: none;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 1rem 1rem 1rem;

  ${media.sm`
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 2rem 1.5rem 1.5rem;
  `}
`;

export const StyledSubmitButton = styled(Button)<{ $reverseButtonOrder: boolean }>`
  width: 100%;
  padding: 0.375rem 0.5rem;
  height: auto;
  font-size: 1rem;
  border: ${(props): string =>
    props.$reverseButtonOrder ? `0.0625rem solid ${props.theme.colors.primary.primary}` : 'none'};
  color: ${(props): string =>
    props.$reverseButtonOrder ? props.theme.colors.primary.primary : props.theme.colors.primary.white};
  text-decoration: none;
  && {
    margin-left: 0;
  }

  ${media.sm`
    width: auto;
    border: none;
    order: ${(props): number => (props.$reverseButtonOrder ? 1 : 2)};
    text-decoration: ${(props): string => (props.$reverseButtonOrder ? 'underline' : 'none')};
    && {
      margin-left: 0.25rem;
    }
  `}
`;

export const StyledCancelButton = styled.button<{ $reverseButtonOrder: boolean }>`
  cursor: pointer;
  font-size: 1rem;
  padding: 0.375rem 0.5rem;
  margin-top: 1rem;
  width: 100%;
  border: 0.0625rem solid ${(props): string => props.theme.colors.primary.primary};
  border-radius: 0.25rem;
  color: ${(props): string =>
    props.$reverseButtonOrder ? props.theme.colors.primary.white : props.theme.colors.primary.primary};
  font-weight: 600;
  text-decoration: none;
  background-color: ${(props): string =>
    props.$reverseButtonOrder ? props.theme.colors.primary.primary : 'transparent'};

  && {
    margin-right: 0;
  }

  ${media.sm`
    margin-top: 0;
    width: auto;
    border: none;
    color: ${(props): string =>
      props.$reverseButtonOrder ? props.theme.colors.primary.white : props.theme.colors.primary.primary};
    order: ${(props): number => (props.$reverseButtonOrder ? 2 : 1)};
    font-weight: 400;
    text-decoration:  ${(props): string => (props.$reverseButtonOrder ? 'none' : 'underline')};
    && {
      margin-right: 0.25rem;
    }
  `}
`;

export const StyledModalLoaderContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background-color: ${(props): string => props.theme.modal.backgroundColor};
  }
`;

export const StyledModalLoader = styled(Loading)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`;

export interface SubmitButton {
  onClick: () => void;
  label: string;
  disabled: boolean;
}

export type ModalProps = {
  className?: string;
  bodyProps?: {
    className?: string;
  };
  isOpen?: boolean;
  isFooterHidden?: boolean;
  isHeaderHidden?: boolean;
  isCloseHidden?: boolean;
  title: string | React.ReactNode;
  centerTitle?: boolean;
  modalSize?: 'small' | 'medium' | 'large';
  children?: React.ReactNode;
  cancelLabel?: string;
  submitLabel?: string;
  'data-qa-id'?: string;
  'data-testid'?: string;
  modalBodyTestId?: string;
  handleToggleClick: MouseEventHandler<HTMLButtonElement>;
  handleCancelClick?: MouseEventHandler<HTMLButtonElement>;
  handleSubmitClick?: (value?: any) => void;
  submitDisabled?: boolean;
  overlayLoading?: boolean;
  reverseButtonOrder?: boolean;
  returnFocusAfterClose?: boolean;
  /** set custom padding for the modal header, unit in rem */
  customHeaderPadding?: CustomPadding & { mdProps?: CustomPadding };
  /**
   * @deprecated use buttons instead as that uses the modern PrimaryButton component
   */
  footerButtons?: SubmitButton[];
  buttons?: Array<ButtonProps>;
};

const CniModal: FC<ModalProps> = ({
  className = '',
  bodyProps,
  isOpen = true,
  isFooterHidden = false,
  isHeaderHidden = false,
  isCloseHidden = false,
  title = '',
  centerTitle = false,
  modalSize = 'large',
  handleToggleClick,
  children,
  cancelLabel = '',
  submitLabel,
  handleCancelClick,
  handleSubmitClick,
  submitDisabled,
  'data-qa-id': qaId,
  'data-testid': testId,
  modalBodyTestId = '',
  overlayLoading,
  reverseButtonOrder = false,
  returnFocusAfterClose = true,
  footerButtons,
  buttons,
  customHeaderPadding,
}) => {
  const { t } = useTranslation();
  const { isDarkTheme } = useThemeSwitcherContext();

  const closeBtn = !isCloseHidden ? (
    <StyledCloseButton aria-label={t('common:button.close')} data-testid={`${qaId}-close`} onClick={handleToggleClick}>
      <CloseIcon inheritColor name="close" />
    </StyledCloseButton>
  ) : (
    <></>
  );
  const submitId = `${qaId}-button-submit`;
  const cancelId = `${qaId}-button-cancel`;

  const submitButtons: SubmitButton[] = footerButtons?.length
    ? footerButtons
    : [
        {
          onClick: handleSubmitClick,
          label: submitLabel || t('common:button.ok'),
          disabled: submitDisabled,
        },
      ];

  return (
    <StyledModal
      className={className}
      data-qa-id={qaId}
      data-testid={testId}
      $modalSize={modalSize}
      isOpen={isOpen}
      returnFocusAfterClose={returnFocusAfterClose}
      backdrop="static"
      backdropTransition={{
        style: {
          backgroundColor: isDarkTheme
            ? darkTheme.modal.overlayBackgroundColor
            : lightTheme.modal.overlayBackgroundColor,
        },
        timeout: 0,
      }}
    >
      {!isHeaderHidden ? (
        <StyledModalHeader
          toggle={handleToggleClick}
          close={closeBtn}
          data-qa-id={`${qaId}-close`}
          data-testid={`${testId}-modal-header`}
          $centerTitle={centerTitle}
          $customHeaderPadding={customHeaderPadding}
        >
          {title}
        </StyledModalHeader>
      ) : (
        <>{closeBtn}</>
      )}
      <StyledModalBody {...bodyProps} data-testid={modalBodyTestId} $padHeading={isHeaderHidden && !isCloseHidden}>
        {children}
      </StyledModalBody>
      {!isFooterHidden && (
        <>
          {buttons?.length ? (
            <ButtonsWrapper m="6">
              {buttons.map((btn, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ModernButton {...btn} isSmall={btn?.isSmall === undefined} key={index} />
              ))}
            </ButtonsWrapper>
          ) : (
            <StyledModalFooter data-testid="modal-footer">
              {!!cancelLabel && (
                <StyledCancelButton
                  data-qa-id={cancelId}
                  data-testid={cancelId}
                  id={cancelId}
                  onClick={handleCancelClick || handleToggleClick}
                  $reverseButtonOrder={reverseButtonOrder}
                >
                  {cancelLabel}
                </StyledCancelButton>
              )}
              {submitButtons.map((button, index) => (
                <StyledSubmitButton
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${submitId}-${index}`}
                  $reverseButtonOrder={reverseButtonOrder}
                  disabled={button.disabled}
                  color={reverseButtonOrder ? 'link' : 'primary'}
                  size="small"
                  data-qa-id={submitId}
                  data-testid={submitId}
                  id={submitId}
                  onClick={button.onClick}
                >
                  {button.label}
                </StyledSubmitButton>
              ))}
            </StyledModalFooter>
          )}
        </>
      )}
      {overlayLoading && (
        <StyledModalLoaderContainer>
          <StyledModalLoader />
        </StyledModalLoaderContainer>
      )}
    </StyledModal>
  );
};

export default memo(CniModal);
