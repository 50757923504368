import styled, { css } from 'styled-components';
import { media } from '@minecraft.themes';
import { DropdownItem, ButtonDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import Icon from '@minecraft.icon';
import { Link } from 'react-router-dom';

export const StyledDropdownMenu = styled(DropdownMenu)`
  // Need to use important styles because this dropdown component adds inline styles dynamically on toggle click and even overriding inline styles doesn't work...
  left: unset !important;
  right: 0 !important;
  transform: translate3d(0, 50px, 0) !important;
  min-width: 100%;
  padding: 0.5rem 0 0.5rem 0;

  @media screen and (max-width: 991px) {
    // important is used to override the default styling
    position: static !important;
    transform: translate3d(0, 0, 0) !important;
    display: inline-block !important;
    margin-top: 0;
    padding: 0 !important;
    border-radius: 0 !important;
    border: none;
    border-top: 1px solid ${(props): string => props.theme.globalHeader.colors.border};
    background: ${(props): string => props.theme.colors.primary.white};
  }

  &:focus {
    outline: none;
  }
`;

export const StyledDropdownToggle = styled(DropdownToggle)`
  padding: 0.2rem 0.75rem;
  border-radius: 0.25rem;
  background-color: ${(props): string => props.theme.globalHeader.colors.backgroundColor};
  color: ${(props): string => props.theme.globalHeader.colors.link};
  &:hover {
    color: ${(props): string => props.theme.globalHeader.colors.link};
  }
  border: solid 0.0625rem ${(props): string => props.theme.colors.primary.ltGrey1};
  @media screen and (max-width: 991px) {
    display: none;
  }
`;

export const StyledDivisionSwitcherItemWrapper = styled.div<{ $withOnHover?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 1.5rem 0 1rem;
  user-select: none;
  min-height: 3rem;

  ${media.lg`
    min-height: auto;
  `}

  &:hover {
    background-color: ${({ $withOnHover, theme }) => ($withOnHover ? theme.colors.accent.greyBlue3 : 'transparent')};
  }
`;

export const LogoCommonStyled = css<{ $isPC?: boolean }>`
  width: ${(props): string => (props.$isPC ? '2.5rem' : '5.25rem')};
  height: 2.5rem;
`;

export const StyledLogoPlaceholder = styled.div`
  ${LogoCommonStyled};
  font-size: 0.875rem;
  font-weight: 600;
  text-align: center;
  padding-top: 0.6875rem;
  color: ${(props): string => props.theme.colors.primary.white};
  background-color: ${(props): string => props.theme.colors.primary.grey2};
`;

export const StyledDivisionSwitcherItemName = styled.div<{
  $iconPresent?: boolean;
  $isSelected?: boolean;
  $isPC?: boolean;
}>`
  padding-left: ${(props) => (props.$iconPresent ? '0.5rem' : '0')};
  color: ${(props) => props.theme.colors.primary.darkGrey};
  font-weight: normal;
  padding: 0.5rem;
`;

export const StyledDivisionSwitcherLogoItemName = styled.div<{
  $isPrimary?: boolean;
  $isPC?: boolean;
}>`
  color: ${(props) => {
    if (props.$isPC) return props.theme.colors.primary.darkGrey;

    if (props.$isPrimary) return props.theme.colors.primary.white;

    return props.theme.colors.primary.darkGrey;
  }};
`;

export const StyledDivisionSwitcherDivider = styled(DropdownItem)`
  margin: 0;
  display: ${(props): string => (props.$isSelected ? 'none' : 'block')};
  @media screen and (max-width: 991px) {
    display: block;
  }
`;

export const StyledDivisionSwitcherDropdownItem = styled(DropdownItem)`
  padding: 0;
  display: inline-block;
  cursor: pointer;
  display: block;
  @media screen and (max-width: 991px) {
    // important is used to override the default styling
    // height: 3.5rem !important;
    background: ${(props): string => props.theme.colors.primary.white};
    &:not(:last-of-type) {
      border-bottom: 0.0625rem solid ${(props): string => props.theme.colors.primary.ltGrey1} !important;
    }
    display: block;
  }

  &:focus,
  &:hover,
  &:active,
  &:visited {
    background-color: transparent;
    outline: none;
  }
`;

export const ClaimAccountButton = styled(StyledDivisionSwitcherDropdownItem)`
  color: ${(props): string => props.theme.colors.primary.darkGrey};

  &:hover {
    color: ${(props): string => props.theme.colors.primary.darkGrey};
    text-decoration: none;
    background-color: none;
  }
`;

export const StyledDivisionSwitcherLogoItemDetails = styled.div<{ $isPrimary: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  padding-left: 1rem;
  font-size: ${(props) => (props.$isPrimary ? '0.75rem' : 'auto')};
`;

export const StyledDivisionSwitcherLogoItemUser = styled.div<{ $isPC: boolean }>`
  font-weight: 600;
  color: ${(props) => (props.$isPC ? props.theme.colors.primary.darkGrey : props.theme.colors.primary.white)};
`;

export const StyledDivisionSwitcherItemIconContainer = styled.div<{ $isPC: boolean }>`
  width: ${(props): string => (props.$isPC ? '2.5rem' : '4.25rem')};
  height: 2.5rem;
  padding: 0.375rem 0.625rem;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledDivisionSwitcherItemIcon = styled(Icon)`
  width: 1.3125rem;
  height: 1.375rem;
`;

export const StyledDivisionSwitcherLink = styled(Link)`
  color: ${(props) => props.theme.colors.primary.darkGrey};

  &:hover,
  &:active,
  &:focus,
  &:visited {
    color: ${(props): string => props.theme.colors.primary.darkGrey};
    text-decoration: none;
    background-color: none;
  }
`;

export const StyledDivisionSwitcherItemWithMenu = styled.div`
  position: relative;
`;

export const StyledDivisionSwitcherLogoItemWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

export const StyledDivisionSwitcherSubMenu = styled.div`
  background-color: ${(props) => props.theme.colors.primary.white};
  border: solid 0.0625rem ${(props): string => props.theme.colors.primary.ltGrey1};

  ${media.sm`
    position: relative;
    min-width: 100%;
    padding: 0 0.5rem;
  `}

  ${media.lg`
    position: absolute;
    right: 100%;
    top: 0;
    min-width: 15rem;
    padding: 0.75rem 0.5rem;
  `}
`;

export const StyledButtonDropdown = styled(ButtonDropdown)<{ $isPC: boolean }>`
  ${media.xlg`
    button {
      width: ${(props) => (props.$isPC ? '16rem' : '100%')};
    }
  `}
  &:focus {
    outline: none;
  }
`;

export const StyledDivisionSwitcherCaretIcon = styled(Icon)`
  position: absolute;
  right: 1rem;
  width: 0.75rem;
`;
