import { createContext } from 'react';

export interface UlaPermissions {
  useStudioTheme: boolean;
}

export interface PermissionsValue {
  permissions: UlaPermissions;
}

const PermissionsContext = createContext<PermissionsValue>({
  permissions: {
    useStudioTheme: false,
  },
});

export { PermissionsContext };
