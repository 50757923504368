import React, { FC } from 'react';
import styled from 'styled-components';
import { H2Text } from './Typography';

const StyledBlock = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.designSystem.sectionBreak.page};
`;

export type PageHeadingProps = {
  title: string;
};

/**
 * A heading for most "major" pages
 * [Figma Design](https://www.figma.com/file/AiDAD1WpprgfbZI2XV6hU1/Casting-Networks-Design-System?node-id=1143%3A3&t=Q5QF7ColgZMIcWX0-0)
 * See "Page Title" section
 *
 * @example
 * <PageHeading title="Page Title" />
 */
export const PageHeading: FC<PageHeadingProps> = ({ title }) => {
  return (
    <StyledBlock className="cn_atom_p-4">
      <H2Text as="h1">{title}</H2Text>
    </StyledBlock>
  );
};
