import React, { FC, useCallback, useMemo, HTMLAttributeAnchorTarget, HTMLAttributeReferrerPolicy } from 'react';
import { Link as RouterLink, useLocation, LinkProps as RouterLinkProps } from 'react-router-dom';
import { getEnvironment } from '@minecraft.environment';
import styled from 'styled-components';

const StyledRouterLink = styled(RouterLink)<{ $disabled?: boolean; $asWrapper?: boolean }>`
  margin: 0;
  padding: 0;
  color: ${({ theme, $disabled, $asWrapper }) => {
    if ($asWrapper) return 'inherit';

    return $disabled ? theme.designSystem.textColors.disabled : theme.designSystem.textColors.link;
  }};
  outline: none;
  outline-style: none;
  text-decoration: none;
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};

  &:visited {
    color: ${({ theme, $disabled, $asWrapper }) => {
      if ($asWrapper) return 'inherit';

      return $disabled ? theme.designSystem.textColors.disabled : theme.designSystem.textColors.link;
    }};
    cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  }

  &:focus {
    outline: ${({ theme, $disabled }) =>
      $disabled ? 'none' : `2px solid ${theme.designSystem.borderColors.linkFocus}`};
    outline-style: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
    text-decoration: ${({ $disabled, $asWrapper }) => {
      if ($asWrapper) return 'none';

      return $disabled ? 'none' : 'underline';
    }};
  }

  @media (any-pointer: fine) {
    &:hover {
      color: ${({ theme, $disabled, $asWrapper }) => {
        if ($asWrapper) return 'inherit';

        return $disabled ? theme.designSystem.textColors.disabled : theme.designSystem.textColors.linkHover;
      }};
      text-decoration: ${({ $disabled, $asWrapper }) => {
        if ($asWrapper) return 'none';

        return $disabled ? 'none' : 'underline';
      }};
    }
  }

  &:active {
    color: ${({ theme, $disabled, $asWrapper }) => {
      if ($asWrapper) return 'inherit';

      return $disabled ? theme.designSystem.textColors.disabled : theme.designSystem.textColors.linkActive;
    }};
    text-decoration: ${({ $disabled, $asWrapper }) => {
      if ($asWrapper) return 'none';

      return $disabled ? 'none' : 'underline';
    }};
  }
`;

export interface LinkProps {
  /* The internal route to push onto the router history */
  to: RouterLinkProps['to'];
  /* use target="_blank" to open in a new window
   * use target="_self" to open in the same window
   * use target="_parent" to open in the parent frame
   * use target="_top" to open in the full body of the window
   *
   * Default: _self
   * when setting target="_blank" then rel="noopener noreferrer" is set automatically
   */
  target?: HTMLAttributeAnchorTarget;
  /* The referrer policy for the link, this should not be needed in most cases */
  rel?: HTMLAttributeReferrerPolicy;
  /* The aria-label for the link if the content doesn't set the value correctly
   * Default: undefined
   * In most cases this should not be needed, but if the link is an icon or image
   * then this should be set to the appropriate value to give screen readers context
   * for the link
   */
  'aria-label'?: string;
  /* The data-testid for the link */
  'data-testid'?: string;
  /* The data-qa-id for the link */
  'data-qa-id'?: string;
  /* If the link should be disabled
   * Default: false
   * If true, the link will not be clickable and will not navigate
   */
  disabled?: boolean;
  /* Use asWrapper when you are making a large section of elements that are
   * already styled on their own into an element that will be clickable as a whole
   * Default: false
   * If true, the link will be styled as a wrapper and will not set text color or text decoration
   * It will still set the cursor to pointer, and provide an outline on focus
   */
  asWrapper?: boolean;
}

const EXTERNAL_PROTOCOLS = ['http', 'https', 'mailto', 'tel', 'sms'];

export const Link: FC<LinkProps> = ({
  to,
  target = '_self',
  rel,
  'aria-label': ariaLabel,
  'data-testid': dataTestId,
  'data-qa-id': dataQaId,
  disabled = false,
  children,
  asWrapper = false,
}) => {
  const location = useLocation();
  const relProp = useMemo(() => (target === '_blank' ? 'noopener noreferrer' : rel), [rel, target]);
  const isExternalLink = useMemo(() => {
    return typeof to === 'string' && EXTERNAL_PROTOCOLS.includes(to?.split(':')?.[0]);
  }, [to]);
  const isRelativeLinkToNewTab = !isExternalLink && target === '_blank';
  const onClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (disabled || isExternalLink || isRelativeLinkToNewTab) e.preventDefault();

      if (disabled || typeof to !== 'string') {
        return;
      }

      if (isExternalLink) {
        window.open(to, '_blank', 'noopener noreferrer');
      }

      if (isRelativeLinkToNewTab) {
        if (to.startsWith('/')) {
          window.open(`/${getEnvironment().AUDIENCE}${to}`, '_blank', 'noopener noreferrer');
        } else {
          const currentPathParts = location?.pathname?.split('/');
          currentPathParts.pop();
          window.open(
            `/${getEnvironment().AUDIENCE}${currentPathParts.join('/')}/${to}`,
            '_blank',
            'noopener noreferrer'
          );
        }
      }
    },
    [to, disabled, isExternalLink, isRelativeLinkToNewTab, location]
  );

  if (!to) {
    return <>{children}</>;
  }

  return (
    <StyledRouterLink
      $asWrapper={asWrapper}
      $disabled={disabled}
      aria-disabled={disabled}
      aria-label={ariaLabel}
      data-qa-id={dataQaId}
      data-testid={dataTestId}
      onClick={onClick}
      rel={relProp}
      tabIndex={disabled ? -1 : 0}
      target={target}
      to={to}
    >
      {children}
    </StyledRouterLink>
  );
};
