import React from 'react';
import { EnterpriseTheme } from '@minecraft.themes';
import {
  StyledDivisionSwitcherItemWrapper,
  StyledDivisionSwitcherItemIcon,
  StyledDivisionSwitcherItemName,
  StyledDivisionSwitcherItemIconContainer,
  StyledDivisionSwitcherCaretIcon,
} from '../DivisionSwitcherStyles';

type DivisionSwitcherItemProps = {
  isPC: boolean;
  text: string;
  leftIcon?: string;
  onClick?: () => void;
  dataQaId?: string;
  isSubMenu?: boolean;
  as?: React.ElementType;
};

const DivisionSwitcherIconItem = ({
  text,
  leftIcon,
  isSubMenu,
  onClick,
  isPC,
  dataQaId,
  as,
}: DivisionSwitcherItemProps): JSX.Element => {
  return (
    <StyledDivisionSwitcherItemWrapper $withOnHover data-qa-id={dataQaId} onClick={onClick} as={as}>
      <>
        {leftIcon && (
          <StyledDivisionSwitcherItemIconContainer $isPC={isPC}>
            <StyledDivisionSwitcherItemIcon name={leftIcon} color={EnterpriseTheme.colors.primary.darkGrey} />
          </StyledDivisionSwitcherItemIconContainer>
        )}
        <StyledDivisionSwitcherItemName $iconPresent={Boolean(leftIcon)}>{text}</StyledDivisionSwitcherItemName>
        {isSubMenu && (
          <StyledDivisionSwitcherCaretIcon name="caret-right" color={EnterpriseTheme.colors.primary.darkGrey} />
        )}
      </>
    </StyledDivisionSwitcherItemWrapper>
  );
};

export default DivisionSwitcherIconItem;
