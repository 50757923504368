import styled, { keyframes } from 'styled-components';
import BaseAnimation, {BaseAnimationProps} from './BaseAnimation';

const HideOutAnimation = keyframes`
  0% {
    opacity: 1;
  }
  99% {
    opacity: 1;
    visibility: visible;
    display: inherit;
  }
  100% {
    opacity: 0;
    overflow: hidden;
    visibility: hidden;
    display: none;
    height: 0;
  }
`;

const HideOut = styled(BaseAnimation)<BaseAnimationProps>`
  animation-name: ${HideOutAnimation};
`;

export default HideOut;
