import React, { FC, PropsWithChildren, useEffect } from 'react';
import initI18N from './initI18N';
import { AppKey } from './types';

type InternationalizationBase = {
  appName?: AppKey;
  namespaces?: string[];
};

const InternationalizationProvider: FC<PropsWithChildren<InternationalizationBase>> = ({
  children,
  appName,
  namespaces,
}): JSX.Element => {
  const [isReady, setIsReady] = React.useState(false);

  useEffect(() => {
    initI18N(appName, namespaces)
      .then(() => setIsReady(true))
      .catch(() => {
        console.error('Internationalization failed to load');
      });
  }, [appName, namespaces]);

  return <>{isReady ? children : null}</>;
};

InternationalizationProvider.displayName = 'InternationalizationProvider';

export { InternationalizationProvider };
