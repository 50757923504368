/**
 * MinLength
 * @param min
 * @param value
 * @return {boolean}
 */

export const MinLength = (min, value) => {
  if (value.length < min) {
    return true;
  }
  return false;
};

export default MinLength;
