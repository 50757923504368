import * as React from 'react';
import { CoreTheme } from '@minecraft.themes';
import debounce from 'lodash/debounce';

/* ----- Types ----- */
export type WidthData = {
  width: number | void;
  lessSm: boolean;
  moreSm: boolean;
  lessMd: boolean;
  moreMd: boolean;
  lessLg: boolean;
  moreLg: boolean;
  lessXlg: boolean;
  moreXlg: boolean;
  lessXxlg: boolean;
  moreXxlg: boolean;
  lessXxxlg: boolean;
  moreXxxlg: boolean;
};

type Breakpoints = {
  xsm?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xlg?: number;
  xxlg?: number;
  xxxlg?: number;
};

type BreakpointsNames = 'xsm' | 'sm' | 'md' | 'lg' | 'xlg' | 'xxlg' | 'xxxlg';

/* ----- Helpers ----- */
const breakpoints: Breakpoints = Object.keys(CoreTheme.grid.breakpoints as Record<string, string>).reduce(
  (acc: Breakpoints, key: BreakpointsNames): Breakpoints => {
    acc[key] = Number(CoreTheme.grid.breakpoints[key].slice(0, -2));

    return acc;
  },
  {}
);

/* ----- Context ----- */
export const WindowWidthContext = React.createContext<WidthData>({
  width: 0,
  lessSm: true,
  moreSm: false,
  lessMd: true,
  moreMd: false,
  lessLg: true,
  moreLg: false,
  lessXlg: true,
  moreXlg: false,
  lessXxlg: true,
  moreXxlg: false,
  lessXxxlg: true,
  moreXxxlg: false,
});

const getWidthData = (): WidthData => {
  const isClient: boolean = typeof window === 'object';

  return {
    width: isClient ? window.innerWidth : undefined,
    lessSm: isClient && window.innerWidth < breakpoints.sm,
    moreSm: isClient && window.innerWidth >= breakpoints.sm,
    lessMd: isClient && window.innerWidth < breakpoints.md,
    moreMd: isClient && window.innerWidth >= breakpoints.md,
    lessLg: isClient && window.innerWidth < breakpoints.lg,
    moreLg: isClient && window.innerWidth >= breakpoints.lg,
    lessXlg: isClient && window.innerWidth < breakpoints.xlg,
    moreXlg: isClient && window.innerWidth >= breakpoints.xlg,
    lessXxlg: isClient && window.innerWidth < breakpoints.xxlg,
    moreXxlg: isClient && window.innerWidth >= breakpoints.xxlg,
    lessXxxlg: isClient && window.innerWidth < breakpoints.xxxlg,
    moreXxxlg: isClient && window.innerWidth >= breakpoints.xxxlg,
  };
};

export const mockWidthData = (screenSize: number): WidthData => {
  return {
    width: screenSize,
    lessSm: screenSize < breakpoints.sm,
    moreSm: screenSize >= breakpoints.sm,
    lessMd: screenSize < breakpoints.md,
    moreMd: screenSize >= breakpoints.md,
    lessLg: screenSize < breakpoints.lg,
    moreLg: screenSize >= breakpoints.lg,
    lessXlg: screenSize < breakpoints.xlg,
    moreXlg: screenSize >= breakpoints.xlg,
    lessXxlg: screenSize < breakpoints.xxlg,
    moreXxlg: screenSize >= breakpoints.xxlg,
    lessXxxlg: screenSize < breakpoints.xxxlg,
    moreXxxlg: screenSize >= breakpoints.xxxlg,
  };
};

/* ----- Provider HOC ----- */
export const withWidthDataProvider = <Props extends object>(Component: React.ComponentType<Props>) => {
  return function useWithWidthDataProvider(props: Props) {
    const [state, setState] = React.useState<WidthData>(getWidthData());

    const debouncedResizeHandler = React.useCallback(
      debounce(() => {
        setState((previousWidthData) => {
          const widthData = getWidthData();

          // Only update state if the width has changed (ignoring height changes)
          if (widthData.width !== previousWidthData.width) {
            return widthData;
          }

          return previousWidthData;
        });
      }, 100),
      []
    );

    React.useEffect(() => {
      window.addEventListener('resize', debouncedResizeHandler, false);

      return () => {
        window.removeEventListener('resize', debouncedResizeHandler, false);
      };
    }, []);

    return (
      <WindowWidthContext.Provider value={state}>
        <Component {...props} />
      </WindowWidthContext.Provider>
    );
  };
};

/* ----- Context Hook (for Function Components) ----- */

/**
 * @deprecated use `useIsMobile` instead.
 *
 * This hook is primarily used to determine if the window is smaller than the
 * `md` breakpoint. The `useIsMobile` hook is a shorthand for the same and the
 * migration to `useIsMobile` will allow us more flexibility to refactor this
 * logic.
 */
export const useWidthData = (): WidthData => React.useContext(WindowWidthContext);

/* ----- Context HOC (for Class Components) ----- */
export const withWidthData = <Props extends object>(Component: React.ComponentType<Props>) =>
  function withWidthDataReturnedFunc(props: Props) {
    return (
      <WindowWidthContext.Consumer>
        {(widthData: WidthData) => <Component {...props} widthData={widthData} />}
      </WindowWidthContext.Consumer>
    );
  };

export const useIsMobile = () => useWidthData().lessMd;
