import { getEnvironment } from '@minecraft.environment';
import getRedirectQueryParams from './getRedirectQueryParams';

const redirectToLogin = (shouldRedirectBack = true, backUrl = '', clearStorage = true, ulaRedirectUrl = ''): void => {
  const environment = getEnvironment();
  const ulaUrl = environment['ULA_URL'];

  // in case when we have some specific ulaRedirectUrl
  // we don't pass query params
  if (ulaRedirectUrl) {
    window.location.replace(`${ulaUrl}/${ulaRedirectUrl}`);

    return;
  }

  const queryParams = getRedirectQueryParams(clearStorage, shouldRedirectBack, backUrl);

  const redirectUrl = `${ulaUrl}/?${queryParams}`;

  // using window because login is a separate application
  window.location.replace(redirectUrl);
};

export default redirectToLogin;
