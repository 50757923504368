import React, { FC } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { DesignSystemBgColor } from '@minecraft.themes';

const StyledListCardsGroup = styled.ul`
  list-style-type: none;
`;

const StyledListCard = styled.li<{
  $bordered: boolean;
  $bgColor: DesignSystemBgColor;
  $clearStyles: boolean;
}>`
  background-color: ${({ theme, $bgColor }) => theme.designSystem.bgColors[$bgColor]};
  border: ${({ theme, $bordered }) => ($bordered ? `1px solid ${theme.designSystem.borderColors.default}` : 'none')};

  /** See "owl" selector, it's pretty awesome: **/
  & + li {
    ${({ $clearStyles }) => ($clearStyles ? '' : 'margin-top: 1rem;')}
  }
`;

export type ListCardsGroupProps = {
  className?: string;
};

export type ListCardItemProps = {
  /** custom classes to apply to the `li` card element */
  className?: string;
  /** whether to include a border around the list card */
  bordered?: boolean;
  /** background color of the list card (defaults to 'card') */
  bgColor?: DesignSystemBgColor;
  /** clears margin and padding around and within the card */
  clearStyles?: boolean;
  /** test id for the list card */
  'data-testid'?: string;
  /** qa id for the list card */
  'data-qa-id'?: string;
};

/**
 * Renders an unordered list that is intended to be a list of 'card' shapes
 * [Figma Design](https://www.figma.com/file/AiDAD1WpprgfbZI2XV6hU1/Casting-Networks-Design-System?node-id=1143%3A3&t=Q5QF7ColgZMIcWX0-0)
 * See "Project Details" section
 *
 * This is useful for mobile views of data instead of a single column table to help accessibility
 *
 * @example
 * <ListCardsGroup>
 *   <ListCard>Card 1</ListCard>
 *   <ListCard>Card 2</ListCard>
 * </ListCardsGroup>
 */
export const ListCardsGroup: FC<ListCardsGroupProps> = ({ children, className }) => {
  return (
    <StyledListCardsGroup className={classNames('cn_m-0', 'cn_atom_p-0', 'cn_w-full', className)}>
      {children}
    </StyledListCardsGroup>
  );
};

export const ListCardItem: FC<ListCardItemProps> = ({
  children,
  className,
  bordered = true,
  bgColor = 'card',
  clearStyles = false,
  'data-testid': testId,
  'data-qa-id': qaId,
}) => {
  const defaultClass = clearStyles ? '' : 'cn_atom_p-4';

  return (
    <StyledListCard
      $bgColor={bgColor}
      $bordered={bordered}
      $clearStyles={clearStyles}
      className={classNames(defaultClass, className)}
      data-testid={testId}
      data-qa-id={qaId}
    >
      {children}
    </StyledListCard>
  );
};
