import React, { FC } from 'react';
import UncontrolledTooltip from '../../components/UncontrolledTooltip';
import { ButtonProps } from './types';
import { Button } from './Button';

type ClickableIconProps = {
  name: string;
  // This will show the tooltip on hover, useful since clickable icons aren't always obvious
  // This uses the Label property as the tooltip content by default
  withTooltip?: boolean;
  // Required if withTooltip is true to give this component a unique id for the
  // tooltip to target
  tooltipId?: string;
};

/**
 * Used when you simply need an icon that's clickable.  This uses the GhostButton foundation
 * The name property translates to the `startIcon` property of the GhostButton
 * The label property translates to the `aria-label` property of the GhostButton
 *
 * @example
 * <ClickableIcon name="close" label="Close" onClick={() => undefined} />
 */
export const ClickableIcon: FC<Omit<ButtonProps, 'children' | 'startIcon' | 'endIcon'> & ClickableIconProps> = ({
  name,
  label,
  'aria-label': ariaLabel,
  block,
  withTooltip,
  tooltipId,
  ...buttonProps
}) => {
  const labelText = typeof label === 'string' ? label : label?.name;

  return (
    <div
      id={`clickable-icon-${tooltipId}`}
      data-testid={`clickable-icon-${tooltipId}`}
      className={block || block === undefined ? 'cn_block' : 'cn_inline-block'}
    >
      <Button
        iconOnly
        color="textMatch"
        data-testid="clickable-icon-button"
        data-qa-id="close-remove-button"
        block={block}
        {...buttonProps}
        label=""
        aria-label={ariaLabel || labelText}
        startIcon={{ name, justify: 'end' }}
      />
      {withTooltip && <UncontrolledTooltip target={`clickable-icon-${tooltipId}`}>{labelText}</UncontrolledTooltip>}
    </div>
  );
};
