import { UncontrolledTooltip } from 'reactstrap';
import styled from 'styled-components';

const StyledUncontrolledTooltip = styled(UncontrolledTooltip)`
  .tooltip {
    &[x-placement] {
      opacity: 1;
    }

    &[x-placement^='top'] {
      & .arrow::before {
        border-top-color: ${(props): string => props.theme.tooltip.borderColor};
      }
    }

    &[x-placement^='bottom'] {
      & .arrow::before {
        border-bottom-color: ${(props): string => props.theme.tooltip.borderColor};
      }
    }

    &[x-placement^='left'] {
      & .arrow::before {
        border-left-color: ${(props): string => props.theme.tooltip.borderColor};
      }
    }

    &[x-placement^='right'] {
      & .arrow::before {
        border-right-color: ${(props): string => props.theme.tooltip.borderColor};
      }
    }

    .tooltip-inner {
      border: 0.0625rem solid ${(props): string => props.theme.tooltip.borderColor};
      background-color: ${(props): string => props.theme.tooltip.backgroundColor};
      max-width: none;
    }
  }
`;

export default StyledUncontrolledTooltip;
