import { getEnvironment } from '@minecraft.environment';

export const APPLICATIONS = {
  reps: 'reps',
  casting: 'casting',
  talent: 'talent',
  login: 'login',
  admin: 'admin',
} as const;

export type ApplicationValue = typeof APPLICATIONS[keyof typeof APPLICATIONS];

export const THEME_KEY = 'theme';

const studioApps: ApplicationValue[] = [APPLICATIONS.login, APPLICATIONS.casting];

export const shouldUseStudioTheme = () => {
  const ENV = getEnvironment();
  const url = new URL(window.location.href);

  if (
    (studioApps.includes(ENV.AUDIENCE as ApplicationValue) && url.searchParams.get(THEME_KEY) === 'studio') ||
    localStorage.getItem(THEME_KEY) === 'studio'
  ) {
    if (!localStorage.getItem(THEME_KEY)) {
      localStorage.setItem(THEME_KEY, 'studio');
    }

    return true;
  }

  return false;
};
