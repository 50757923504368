import * as React from 'react';
import Alert from '@minecraft.alert';
import ErrorContext from 'src/contexts/ErrorContext';

const ErrorContextProvider: React.FunctionComponent = ({ children }) => {
  const [errorMsg, setErrorMsg] = React.useState<string>('');

  // remove error message after animation
  React.useEffect(() => {
    if (errorMsg)
      setTimeout(() => {
        setErrorMsg('');
      }, 5000);
  }, [errorMsg]);

  const showError = (error: string, delay?: number) => {
    if (delay) {
      setTimeout(() => {
        setErrorMsg(error);
      }, delay);
    } else setErrorMsg(error);
  };
  const errorContextValue = React.useMemo(() => ({ errorMsg, showError }), [errorMsg]);

  return (
    <ErrorContext.Provider value={errorContextValue}>
      <>
        {errorMsg && (
          <Alert type="error" size="fullWidth">
            {errorMsg}
          </Alert>
        )}
        {children}
      </>
    </ErrorContext.Provider>
  );
};

export default ErrorContextProvider;
