import colors from '../../colors';

const modal = {
  backgroundColor: colors.primary.grey4,
  borderColor: colors.primary.ltGrey1,
  color: colors.primary.white,
  overlayBackgroundColor: colors.primary.white,
};

export default modal;
