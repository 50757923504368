import { ApolloLink, makeVar } from '@apollo/client';
import { gqlCountryEnum as countryType } from '@minecraft.graphql-types';

export const userCountryFromHeaders = makeVar(countryType.US);

export const userCountryLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const cloudFrontViewerCountryHeader = context?.response?.headers?.get('cloudfront-viewer-country');

    // User ISO country code is set from headers in the response injected by cloudfront
    if (cloudFrontViewerCountryHeader) {
      userCountryFromHeaders(cloudFrontViewerCountryHeader);
    }

    return response;
  });
});
