import React, { useContext, useMemo } from 'react';
import { shouldUseStudioTheme } from '@minecraft.themes';
import { PermissionsContext, PermissionsValue, UlaPermissions } from 'src/contexts/PermissionsContext';

const STUDIO_PATH = '/studio';

const shouldHaveStudioTheme = () =>
  shouldUseStudioTheme() || window?.location?.pathname?.toLowerCase()?.slice(0, STUDIO_PATH.length) === STUDIO_PATH;

const definePermissions: () => PermissionsValue = () => {
  const permissions: UlaPermissions = {
    useStudioTheme: false,
  };

  if (shouldHaveStudioTheme()) {
    permissions.useStudioTheme = true;
  }

  return { permissions };
};

const PermissionsProvider: React.FunctionComponent = ({ children }) => {
  const contextValue = useMemo(() => definePermissions(), []);

  return <PermissionsContext.Provider value={contextValue}>{children}</PermissionsContext.Provider>;
};

const usePermissionsContext = () => useContext(PermissionsContext);

export { PermissionsProvider, usePermissionsContext };
