import { gqlSystemRoleCode, gqlAccountStatusEnum } from '@minecraft.graphql-types';
import parseBase64 from './parseBase64';

export type TokenApp = 'talent' | 'representative' | 'casting';

export interface TokenParsed {
  email: string;
  exp: number;
  iat: number;
  roles: gqlSystemRoleCode[];
  status?: gqlAccountStatusEnum;
  organizations?: number[];
  organizationId?: number;
  artistId?: number;
  role: {
    code: gqlSystemRoleCode;
  };
  id: string | number;
  loginAttributes?: { id: number; service: string };
  app?: TokenApp; // this will be undefined for users who should go to the disambiguation page
  sessionId: string;
  hd?: string;
  rules?: string[];
  storageLimitMb: number | null;
}

export const parseJwt = (token: string): TokenParsed | null => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const parsed = parseBase64(base64);
    parsed.storageLimitMb = parsed.storageLimitMb && parsed.storageLimitMb > 0 ? parsed.storageLimitMb : null;

    return parsed;
  } catch (e) {
    return null;
  }
};
