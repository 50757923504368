import { THEME_NAMES } from '../constants';
import colors from '../colors';
import { media, grid } from '../grid';
import { Theme } from '../TSTypes/theme';
import alert from './alert';
import button from './button';
import { checkbox, radio } from './checkboxAndRadio';
import common from './common';
import filters from './filters';
import form from './form';
import globalHeader from './globalHeader';
import meatballMenu from './meatballMenu';
import modal from './modal';
import tooltip from './tooltip';
import typography from './typography';
import { designSystem } from './designSystem';

const theme: Theme = {
  colors,
  media,
  grid,
  alert,
  button,
  checkbox,
  radio,
  common,
  filters,
  form,
  globalHeader,
  meatballMenu,
  modal,
  tooltip,
  typography,
  designSystem,
  name: THEME_NAMES.core,
};

export default theme;
