/**
 * Simply returns the currency symbol for a given currency code
 */
const currencySymbol = (currencyCode: string) => {
  // hard code en-US so we know we will have a decimal point (not comma)
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    // Setting the currency code is what actually puts in the correct symbol
    currency: currencyCode,
    // Display the narrow form so we can parse out the numbers
    currencyDisplay: 'narrowSymbol',
  })
    .format(0)
    .replace('0.00', '');
};

export const customFormatters = [
  {
    key: 'currencySymbol',
    formatter: currencySymbol,
  },
];
