import React, { FC, useEffect } from 'react';
import { useTranslation, getLanguageFromLocalStorage, setLanguageToLocalStorage } from '@blocs.i18n';

type LanguageSetterData = {
  locale?: string;
};

const getLocaleBase = (locale: string): string => {
  return locale?.split('-')?.[0];
};

const getLanguageToSet = (authenticated: boolean, acctLng?: LanguageSetterData): string => {
  const useKlingon = process.env.KLINGON;
  if (useKlingon) {
    return 'klingon';
  }

  if (authenticated && acctLng?.locale) {
    return getLocaleBase(acctLng?.locale);
  }

  if (authenticated) {
    // if we are in an authenticated context with no
    // account language set, show english
    return 'en';
  }

  // in unauthenticated contexts (login page, public profile)
  // check for a cached value from previous login
  const cachedLng = getLanguageFromLocalStorage();

  if (cachedLng) {
    return getLocaleBase(cachedLng);
  }

  // otherwise use the browsers preferred value
  const browserLng = navigator.language;

  return getLocaleBase(browserLng) === 'es' ? 'es' : 'en';
};

interface LanguageSetterProps {
  loaded: boolean;
  authenticated: boolean;
  acctLng?: LanguageSetterData;
}
const LanguageSetter: FC<LanguageSetterProps> = ({ authenticated, loaded, acctLng, children }) => {
  const { i18n, ready } = useTranslation();
  const { language, changeLanguage } = i18n;
  const supportedLngs = i18n?.options?.supportedLngs;

  useEffect(() => {
    if (loaded && ready) {
      const supported: string[] = Array.isArray(supportedLngs) ? supportedLngs : [];
      let lngToSet = getLanguageToSet(authenticated, acctLng);

      const lngIsSupported = supported?.includes(lngToSet);

      if (!lngIsSupported) {
        lngToSet = 'en'; // fallback to english for non-supported languages
      }

      if (lngToSet !== language) {
        window.document.documentElement.lang = lngToSet;
        changeLanguage(lngToSet);

        if (authenticated) {
          // cache language onto localstorage for subsequent
          // logins in unauthenticated context
          setLanguageToLocalStorage(lngToSet);
        }
      }
    }
  }, [acctLng, authenticated, changeLanguage, language, loaded, ready, supportedLngs]);

  return <>{children}</>;
};

export default LanguageSetter;
