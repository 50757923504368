import colors from '../../colors';
import { Color } from '../../TSTypes/button';

const AdditionalColor: Color = {
  outlined: {
    true: {
      state: {
        default: {
          color: colors.primary.grey3,
          backgroundColor: colors.buttons.additionalOutlined.default,
          border: `1px solid ${colors.primary.grey3}`,
        },
        hover: {
          color: colors.buttons.additional.active,
          backgroundColor: colors.buttons.additionalOutlined.hover,
          border: `1px solid ${colors.buttons.additional.active}`,
        },
        active: {
          color: '#4B5156',
          backgroundColor: colors.buttons.additionalOutlined.active,
          border: `1px solid #4B5156`,
        },
        focus: {
          color: colors.buttons.additional.active,
          backgroundColor: colors.buttons.additionalOutlined.hover,
          border: `1px solid ${colors.buttons.additional.active}`,
        },
        disabled: {
          color: colors.primary.grey2,
          backgroundColor: colors.buttons.additionalOutlined.disabled,
          border: `1px solid ${colors.primary.grey2}`,
        },
      },
    },
    false: {
      state: {
        default: {
          color: colors.primary.white,
          backgroundColor: colors.buttons.additional.default,
          border: 'none',
        },
        hover: {
          color: colors.primary.white,
          backgroundColor: colors.buttons.additional.hover,
          border: 'none',
        },
        focus: {
          color: colors.primary.white,
          backgroundColor: colors.buttons.additional.hover,
          border: 'none',
        },
        active: {
          color: colors.primary.white,
          backgroundColor: colors.buttons.additional.active,
          border: 'none',
        },
        disabled: {
          color: colors.primary.grey2,
          backgroundColor: colors.buttons.additional.disabled,
          border: 'none',
        },
      },
    },
  },
};

export default AdditionalColor;
