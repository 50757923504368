import React, { FC } from 'react';
import Icon from '@minecraft.icon';
import { BasicContainer, FlexRow } from './Containers';
import { BodyText } from './Typography';

export type NoticeBlockProps = {
  color?: 'danger' | 'warning' | 'info';
  message: string | JSX.Element;
  'data-testid'?: string;
  fontWeight?: string;
};

export const NoticeBlock: FC<NoticeBlockProps> = ({ color, message, 'data-testid': testId, fontWeight }) => {
  const iconMap: Record<string, string> = {
    danger: 'info-filled',
    warning: 'alert',
    info: 'info',
  };

  return (
    <BasicContainer
      borderPlacement="all"
      borderRadius="md"
      padding={4}
      bgColor={color ?? 'warning'}
      borderColor={color ?? 'warning'}
      className="cn_w-full"
      data-testid={testId ?? 'notice-block-container'}
    >
      <FlexRow gap="4" alignItems="center" wrap="nowrap">
        <div>
          <Icon className="cn_h-4" name={iconMap[color ?? 'warning']} themeColor={color ?? 'warning'} size="md" />
        </div>
        <BodyText fontWeight={fontWeight ?? 'regular'}>{message}</BodyText>
      </FlexRow>
    </BasicContainer>
  );
};
