import * as React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Alert from '@minecraft.alert';
import styled from 'styled-components';
import parse from 'html-react-parser';
import { Trans } from '@blocs.i18n';
import { GET_GLOBAL_ALERT, HIDE_GLOBAL_ALERT } from './graphqls/globalAlert';

/* ----- Styled Components ----- */
const StyledAlert = styled(Alert)`
  position: fixed;
  top: 0;
  z-index: 9999999;
  margin: 0 -2rem;
`;

/* ----- Main Component ----- */
/**
 * @deprecated use `useGlobalNotification` instead
 */
const GlobalAlert = (): null | JSX.Element => {
  const [hideGlobalAlert] = useMutation(HIDE_GLOBAL_ALERT);

  const { data, loading } = useQuery(GET_GLOBAL_ALERT);

  if (loading || !data) {
    return null;
  }

  const {
    globalAlert: { text, isCloseable, isShown, isTransTag, isHtmlTag = false, ...alertProps },
  } = data;

  if (!isShown) {
    return null;
  }

  const isCloseableString = isCloseable ? 'true' : 'false';

  if (isTransTag) {
    let generatedComponents;

    if (Array.isArray(text.components)) {
      [generatedComponents] = text.components.map((component) => parse(component));
    }

    return (
      <StyledAlert
        data-testid="global-alert-container"
        close={isCloseableString}
        {...alertProps}
        onClose={hideGlobalAlert}
      >
        <Trans i18nKey={text.key} components={generatedComponents} />
      </StyledAlert>
    );
  }

  return (
    <StyledAlert
      data-testid="global-alert-container"
      close={isCloseableString}
      {...alertProps}
      onClose={hideGlobalAlert}
    >
      {isHtmlTag ? parse(text) : text}
    </StyledAlert>
  );
};

export default GlobalAlert;
