import { useCallback, useEffect, useRef } from 'react';
import { usePermissions } from '@minecraft.permissions';

export type UseZendeskProps = {
  /**
   * Use when you want the zendesk script to not load manually
   * This is useful for specific situations where you do not want to show the zendesk widget
   * such as Share Selects
   */
  shouldHide?: boolean;
};

export type UseZendesk = {
  hide: () => void;
  show: () => void;
};

const ZENDESK_SCRIPT = 'https://static.zdassets.com/ekr/snippet.js?key=a0addfbd-c6e0-4b90-8ae3-bc54b7d4d4f8';
const ZENDESK_ID = 'ze-snippet';
let checkLoadedTimer: number;
let loadAttempts = 0;

const tryToAddPrintClass = () => {
  // The following code injects a class onto the zendesk chat widget to hide it on print preview screens
  // Zendesk does not provide a callback to tie into when the widget is fully loaded, so we are manually
  // waiting for the widget to appear to add the right class to it (and not just wait for the "load" event from the script)
  // Zendesk docs: https://developer.zendesk.com/documentation/zendesk-web-widget-sdks/sdks/web/sdk_api_reference/
  const zenDeskButton = document.querySelector('iframe[title="Button to launch messaging window"]');

  if (zenDeskButton) {
    zenDeskButton.classList.add('zendesk-container');
    clearTimeout(checkLoadedTimer);
  } else if (loadAttempts < 20) {
    loadAttempts += 1;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    checkLoadedTimer = setTimeout(tryToAddPrintClass, 200);
  } else {
    clearTimeout(checkLoadedTimer);
    // Report an error that we are unable to find the zendesk script
  }
};

/**
 * Manages the zendesk widget, this will only inject a single widget script tag into the dom on initialization
 */
const useZendesk = ({ shouldHide }: UseZendeskProps = {}): UseZendesk => {
  const { ability } = usePermissions();
  const body = useRef(document.querySelector('body'));
  const isVisible = useRef(false);

  const hide = useCallback(() => {
    if (isVisible.current) {
      // This allows any page to hide zendesk on-demand.  Typically
      // you'd want to lean into the per-route basis but there are situations
      // like the MediaList where having the sticky footer appear you'd want to hide it
      // https://developer.zendesk.com/api-reference/widget-messaging/web/core/
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.zE?.('messenger', 'hide');
      isVisible.current = false;
    }
  }, []);

  const show = useCallback(() => {
    if (!isVisible.current) {
      // This allows any page to show zendesk on-demand.
      // Similar to the hide technique this should only be done in rare cases.
      // Also show will only work if the route is setup to have it in the first place (the script is loaded)
      // https://developer.zendesk.com/api-reference/widget-messaging/web/core/
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.zE?.('messenger', 'show');
      isVisible.current = true;
    }
  }, []);

  const handleScroll = useCallback(() => {
    if (shouldHide) {
      return;
    }

    if (window.innerHeight - 30 >= body.current.scrollHeight) {
      // Always show if the window is bigger than the scrollable area:
      show();
    } else if ((body.current.scrollTop || window.scrollY) + window.innerHeight >= body.current.scrollHeight - 30) {
      // If we've scrolled to the bottom:
      hide();
    } else {
      show();
    }
  }, [hide, show, shouldHide]);

  useEffect(() => {
    const existingZendeskScript = document.getElementById(ZENDESK_ID);

    // check if the zendesk script exists and if hide is true, remove it and remove the event listeners
    if (shouldHide || !ability.can('display', 'zendesk-support')) {
      // hide the zendesk widget
      hide();
      if (existingZendeskScript) {
        document.body.removeChild(existingZendeskScript);
      }
    } else {
      // If the script does not exist, add it to the dom
      if (!existingZendeskScript) {
        const script = document.createElement('script');
        script.id = ZENDESK_ID;
        script.src = ZENDESK_SCRIPT;
        script.async = true;
        script.onload = () => {
          tryToAddPrintClass();
        };
        body.current.appendChild(script);
      }

      // If the script exists, show the zendesk widget
      if (existingZendeskScript) {
        show();
      }

      // Add the scroll event listener to hide the zendesk widget when the user scrolls
      document.querySelector('body').addEventListener('scroll', handleScroll);
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      document.querySelector('body').removeEventListener('scroll', handleScroll);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [shouldHide, ability, handleScroll, hide, show]);

  return {
    hide,
    show,
  };
};

export default useZendesk;
