import { FC } from 'react';
import useZendesk, { UseZendeskProps } from './useZendesk';

/**
 * A simple "component" that renders nothing but pulls in the zendesk hook
 * This is used to add the zendesk script in the stack of providers typically found in App.tsx
 *
 * @example
 * <ProviderA>
 *   <AuthenticationProvider>
 *     <Zendesk />
 *     <ActualApp />
 */
const Zendesk: FC<UseZendeskProps> = (props) => {
  useZendesk(props);

  return null;
};

export default Zendesk;
