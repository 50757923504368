import { Color } from '../../TSTypes/button';
import { DESIGN_SYSTEM_COLORS } from '../../constants';

const PrimaryColor: Color = {
  outlined: {
    true: {
      state: {
        default: {
          color: DESIGN_SYSTEM_COLORS.blue['500'],
          backgroundColor: DESIGN_SYSTEM_COLORS.grayscale['0'],
          border: `1px solid ${DESIGN_SYSTEM_COLORS.blue['500']}`,
        },
        hover: {
          color: DESIGN_SYSTEM_COLORS.grayscale['0'],
          backgroundColor: DESIGN_SYSTEM_COLORS.blue['600'],
          border: `1px solid ${DESIGN_SYSTEM_COLORS.blue['600']}`,
        },
        active: {
          color: DESIGN_SYSTEM_COLORS.grayscale['0'],
          backgroundColor: DESIGN_SYSTEM_COLORS.blue['650'],
          border: `1px solid ${DESIGN_SYSTEM_COLORS.blue['650']}`,
        },
        focus: {
          color: DESIGN_SYSTEM_COLORS.blue['500'],
          backgroundColor: DESIGN_SYSTEM_COLORS.grayscale['0'],
          border: `1px solid ${DESIGN_SYSTEM_COLORS.blue['500']}`,
          boxShadow: `0px 0px 0px 0.2rem ${DESIGN_SYSTEM_COLORS.blue['400']}`,
        },
        disabled: {
          color: DESIGN_SYSTEM_COLORS.grayscale['650'],
          backgroundColor: DESIGN_SYSTEM_COLORS.grayscale['350'],
          border: `1px solid ${DESIGN_SYSTEM_COLORS.grayscale['350']}`,
        },
      },
    },
    false: {
      state: {
        default: {
          color: DESIGN_SYSTEM_COLORS.grayscale['0'],
          backgroundColor: DESIGN_SYSTEM_COLORS.blue['500'],
          border: `1px solid ${DESIGN_SYSTEM_COLORS.blue['500']}`,
        },
        hover: {
          color: DESIGN_SYSTEM_COLORS.grayscale['0'],
          backgroundColor: DESIGN_SYSTEM_COLORS.blue['600'],
          border: `1px solid ${DESIGN_SYSTEM_COLORS.blue['600']}`,
        },
        active: {
          color: DESIGN_SYSTEM_COLORS.grayscale['0'],
          backgroundColor: DESIGN_SYSTEM_COLORS.blue['650'],
          border: `1px solid ${DESIGN_SYSTEM_COLORS.blue['650']}`,
        },
        focus: {
          color: DESIGN_SYSTEM_COLORS.grayscale['0'],
          backgroundColor: DESIGN_SYSTEM_COLORS.blue['500'],
          border: `1px solid ${DESIGN_SYSTEM_COLORS.blue['500']}`,
          boxShadow: `0px 0px 0px 0.2rem ${DESIGN_SYSTEM_COLORS.blue['400']}`,
        },
        disabled: {
          color: DESIGN_SYSTEM_COLORS.grayscale['650'],
          backgroundColor: DESIGN_SYSTEM_COLORS.grayscale['350'],
          border: `1px solid ${DESIGN_SYSTEM_COLORS.grayscale['350']}`,
        },
      },
    },
  },
};

export default PrimaryColor;
