import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import { getEnvironment } from '@minecraft.environment';
import { PRODUCTION } from '../Auth/constants';
import { AppKey } from './types';
import { ES_ENABLED_APPS } from './config';
import { customFormatters } from './customFormatters';

// resource files for local dev;
import enCommon from './lang/en/common.json';
import enAdmin from './lang/en/admin.json';
import enTalent from './lang/en/talent.json';
import enLogin from './lang/en/login.json';
import enReps from './lang/en/reps.json';
import enCasting from './lang/en/casting.json';
import esCommon from './lang/es/common.json';
import esAdmin from './lang/es/admin.json';
import esTalent from './lang/es/talent.json';
import esLogin from './lang/es/login.json';
import esReps from './lang/es/reps.json';
import esCasting from './lang/es/casting.json';
import klnCommon from './lang/klingon/common.json';
import klnAdmin from './lang/klingon/admin.json';
import klnTalent from './lang/klingon/talent.json';
import klnLogin from './lang/klingon/login.json';
import klnReps from './lang/klingon/reps.json';
import klnCasting from './lang/klingon/casting.json';

const backendResources = {
  resources: {
    en: {
      common: enCommon,
      talent: enTalent,
      login: enLogin,
      reps: enReps,
      casting: enCasting,
      admin: enAdmin,
    },
    es: {
      common: esCommon,
      talent: esTalent,
      login: esLogin,
      reps: esReps,
      casting: esCasting,
      admin: esAdmin,
    },
    klingon: {
      common: klnCommon,
      talent: klnTalent,
      login: klnLogin,
      reps: klnReps,
      casting: klnCasting,
      admin: klnAdmin,
    },
  },
};

const EN_ONLY = ['en'];
const ES_ENABLED = ['en', 'es'];
const ES_ENABLED_DEV = ['en', 'es', 'klingon'];

const LNGS_FOR_CONFIG = (isProd: boolean, isEsEnabled: boolean) => {
  if (isProd) {
    return isEsEnabled ? ES_ENABLED : EN_ONLY;
  }

  return isEsEnabled ? ES_ENABLED_DEV : EN_ONLY;
};

const initI18N = async (appName?: AppKey, namespaces: string[] = []): Promise<void> => {
  const environment = getEnvironment();
  const httpBackendOptions = {
    backend: {
      loadPath: (lngs: string[], nss: string[]) => {
        // since we are not configured for multi-resource lookup, lngs and nss will only have 1 item each
        const lookupLng = lngs?.[0];
        const lookupNs = nss?.[0];

        return backendResources?.resources?.[lookupLng]?.[lookupNs];
      },
    },
  };
  const ENV = environment?.ENV;
  const isDeployed = process.env.DEPLOYED;
  const isProduction = ENV === PRODUCTION;
  const isTest = process.env.TEST;
  const backendOptions = isTest || !isDeployed || !appName ? backendResources : httpBackendOptions;
  const supportedLngs = LNGS_FOR_CONFIG(isProduction, ES_ENABLED_APPS[appName]) || EN_ONLY;

  // we init i18n with our full list of supported languages but set the language to "en"
  // the <LanguageSetter /> in <AuthenticatedRoute /> will set spanish if feature flag and browser say so
  // the <LanguageSetter /> in <UnauthenticatedRoute /> will set spanish if the localStorage key is set
  const options: any = {
    language: 'en',
    fallbackLng: 'en',
    ns: ['common'].concat(namespaces),
    defaultNS: 'common',
    supportedLngs: isTest ? ES_ENABLED_DEV : supportedLngs,
    // debug: !isProduction && !isTest,
    interpolation: {
      escapeValue: false, // react already escapes by default => https://www.i18next.com/translation-function/interpolation#unescape
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],
    },
    ...backendOptions,
  };
  let initFn = i18n.use(initReactI18next);

  if (isDeployed) {
    initFn = initFn.use(HttpApi);
  }

  await initFn.init(options);

  // Add any additional custom formatters
  // https://www.i18next.com/translation-function/formatting#adding-custom-format-function
  customFormatters.forEach((customFormatterConfig) => {
    initFn.services.formatter.add(customFormatterConfig.key, customFormatterConfig.formatter);
  });
};

export default initI18N;
