import React from 'react';
import { Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import { UnauthenticatedRoute as Route, useCouponCodeCatcher } from '@blocs.auth';
import { useRemoveSplashLoader } from '@minecraft.utils';
import Loader from '@minecraft.loader';
import ComponentWithHeader from 'src/layout/ComponentWithHeader';

const LoginPage = loadable(() => import(/* webpackChunkName: "ula-login" */ 'src/routes/login-page'));
const ProxyLoginPage = loadable(() => import(/* webpackChunkName: "ula-login" */ 'src/routes/proxy-login-page'));
const SignupRolePage = loadable(
  () => import(/* webpackChunkName: "ula-signup-role" */ 'src/routes/signup/sign-up-role')
);
const SignupPage = loadable(() => import(/* webpackChunkName: "ula-signup" */ 'src/routes/signup/sign-up-page'));
const ForgotPasswordPage = loadable(
  () => import(/* webpackChunkName: "ula-forgot-pass" */ 'src/routes/forgot-password-page')
);
const ForgotUserNamePage = loadable(
  () => import(/* webpackChunkName: "ula-forgot-username" */ 'src/routes/forgot-username-page')
);
const DisambiguationPage = loadable(
  () => import(/* webpackChunkName: "ula-disambiguation" */ 'src/routes/disambiguation-page')
);
const PasswordResetPage = loadable(
  () => import(/* webpackChunkName: "ula-pswrd-reset" */ 'src/routes/password-reset-page')
);
const PasswordResetTokenExpiredPage = loadable(
  () => import(/* webpackChunkName: "ula-pswrd-reset" */ 'src/routes/password-reset-token-expired-page')
);
const CreatePassword = loadable(() => import(/* webpackChunkName: "ula-pswrd-create" */ 'src/routes/create-password'));
const PasswordSuccess = loadable(
  () => import(/* webpackChunkName: "ula-pswrd-success" */ 'src/routes/password-success')
);
const VerifyLinkPage = loadable(() => import(/* webpackChunkName: "ula-reset-email" */ 'src/routes/verify-link-page'));
const PCLastStep = loadable(() => import(/* webpackChunkName: "ula-pc-last-step" */ 'src/routes/pc-last-step-page'));
const VerifyEmail = loadable(() => import(/* webpackChunkName: "ula-verify-email" */ 'src/routes/verify-email'));
const TokenExpired = loadable(() => import(/* webpackChunkName: "ula-token-exp" */ 'src/routes/token-expired'));
const Maintenance = loadable(
  () => import(/* webpackChunkName: "ula-maint-screeen" */ 'src/routes/splash-screens/maintenance')
);
const NoOrgs = loadable(() => import(/* webpackChunkName: "ula-no-org" */ 'src/routes/no-org-page'));
const LegacyRedirect = loadable(
  () => import(/* webpackChunkName: "ula-legacy-redirect" */ 'components/LegacyRedirect')
);

const NotFound = loadable(/* webpackChunkName: "ula-not-found" */ () => import('@blocs.not-found'), {
  resolveComponent: (components) => components.NotFound,
});

const Routes = (): JSX.Element => {
  useRemoveSplashLoader();
  const { complete } = useCouponCodeCatcher();

  if (!complete) {
    return null;
  }

  return (
    <Switch>
      <Route exact path="/">
        <ComponentWithHeader fullHeight hideLogout routeComponent={<LoginPage fallback={<Loader />} />} />
      </Route>
      <Route exact path="/proxy">
        <ComponentWithHeader fullHeight hideLogout routeComponent={<ProxyLoginPage fallback={<Loader />} />} />
      </Route>
      <Route exact path="/maintenance">
        <Maintenance fallback={<Loader />} />
      </Route>
      <Route exact path="/signup">
        <ComponentWithHeader
          hideLogout
          showCancelRegistration
          routeComponent={<SignupRolePage fallback={<Loader />} />}
        />
      </Route>
      <Route exact path="/signup/:type">
        <ComponentWithHeader showCancelRegistration routeComponent={<SignupPage fallback={<Loader />} />} />
      </Route>
      <Route exact path="/forgot-password">
        <ComponentWithHeader hideLogout routeComponent={<ForgotPasswordPage fallback={<Loader />} />} />
      </Route>
      <Route exact path={['/forgot-username/:fromLogin', '/forgot-username']}>
        <ComponentWithHeader hideLogout routeComponent={<ForgotUserNamePage fallback={<Loader />} />} />
      </Route>
      <Route exact path="/disambiguation">
        <ComponentWithHeader routeComponent={<DisambiguationPage fallback={<Loader />} />} />
      </Route>
      <Route exact path="/password-reset">
        <ComponentWithHeader routeComponent={<PasswordResetPage fallback={<Loader />} />} />
      </Route>
      <Route exact path="/password-success">
        <ComponentWithHeader routeComponent={<PasswordSuccess fallback={<Loader />} />} />
      </Route>
      <Route exact path="/create-password">
        <ComponentWithHeader routeComponent={<CreatePassword fallback={<Loader />} />} />
      </Route>
      <Route exact path="/reset-email">
        <ComponentWithHeader hideLogout routeComponent={<VerifyLinkPage fallback={<Loader />} />} />
      </Route>
      <Route exact path="/last-step">
        <ComponentWithHeader hideLogout routeComponent={<PCLastStep fallback={<Loader />} />} />
      </Route>
      <Route exact path="/verify-email">
        <ComponentWithHeader hideLogout routeComponent={<VerifyEmail fallback={<Loader />} />} />
      </Route>
      <Route exact path="/no-org">
        <ComponentWithHeader routeComponent={<NoOrgs fallback={<Loader />} />} />
      </Route>
      <Route exact path="/token-expired">
        <ComponentWithHeader routeComponent={<TokenExpired fallback={<Loader />} />} />
      </Route>
      <Route exact path="/password-reset-token-expired">
        <ComponentWithHeader routeComponent={<PasswordResetTokenExpiredPage fallback={<Loader />} />} />
      </Route>
      <Route exact path="/redirect">
        <ComponentWithHeader routeComponent={<LegacyRedirect fallback={<Loader />} />} />
      </Route>
      <Route path="*">
        <ComponentWithHeader routeComponent={<NotFound fallback={<Loader />} />} />
      </Route>
    </Switch>
  );
};

export default Routes;
