import { createContext } from 'react';

interface ErrContext {
  errorMsg: string;
  showError: (error: string, delay?: number) => void;
}
const ErrorContext = createContext<ErrContext>({
  errorMsg: '',
  showError: () => undefined,
});

export default ErrorContext;
