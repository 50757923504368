import colors from '../../colors';

const form = {
  backgroundColor: colors.primary.white,
  borderColor: colors.input.border,
  inputTextColor: colors.primary.darkGrey,
  placeholderColor: colors.primary.grey1,
  labelColor: colors.primary.darkGrey,
  state: {
    error: {
      borderColor: colors.accent.red,
    },
    active: {
      borderColor: colors.primary.primary,
    },
    selected: {
      backgroundColor: colors.accent.ltGrey8,
    },
    disabled: {
      backgroundColor: colors.primary.ltGrey1,
      inputTextColor: colors.primary.grey2,
    },
  },
  option: {
    state: {
      selected: {
        backgroundColor: colors.accent.ltGrey2,
      },
    },
  },
};

export default form;
