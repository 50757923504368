import React, { ReactNode } from 'react';
import { Subject, SubjectBasedActionMap } from './ability';
import { usePermissions } from './PermissionsProvider';

type ViewProps = {
  view: boolean;
};

type AbilityProps<T extends Subject> = {
  action: SubjectBasedActionMap[T];
  subject: T;
};

export type Props<T extends Subject> = { children?: ReactNode } & (AbilityProps<T> | ViewProps);

/**
 * It can take an action and subject props that will get used inside the component so that users will be able to do <Can ability="read" subject="project" /> and internally the component will use the usePermissions hook and pass those prop values into the ability.can() function for the user.
 * It is using view prop as a priority over the ability and subject props to support backwards compatibility.
 *
 * @example
 * <Can action="publish" subject="role">
 *  <div>Only users with the publish role can see this</div>
 * </Can>
 *
 * <Can view={true}>
 * <div>Everyone can see this</div>
 * </Can>
 *
 * <Can view={false}>
 * <div>No one can see this</div>
 * </Can>
 *
 * <Can subject="role" action="publish" view={true}>
 * <div>Everyone can see this</div>
 * </Can>
 *
 * <Can subject="role" action="publish" view={false}>
 * <div>No one can see this</div>
 * </Can>
 *
 * <Can subject="role" action="publish">
 * <div>Only users with the publish role permission can see this</div>
 * </Can>
 *
 */

export const Can = <T extends Subject>(props: Props<T>) => {
  const { ability } = usePermissions();
  const { children } = props;

  if ('view' in props) {
    const { view = false } = props;

    return view && <>{children}</>;
  }

  const { action, subject } = props;

  return ability.can(action, subject) && <>{children}</>;
};
