import { Color } from '../../TSTypes/button';
import { DESIGN_SYSTEM_COLORS } from '../../constants';

const warning: Color = {
  outlined: {
    true: {
      state: {
        default: {
          color: DESIGN_SYSTEM_COLORS.red['500'],
          backgroundColor: DESIGN_SYSTEM_COLORS.grayscale['0'],
          border: `1px solid ${DESIGN_SYSTEM_COLORS.red['500']}`,
        },
        hover: {
          color: DESIGN_SYSTEM_COLORS.grayscale['0'],
          backgroundColor: DESIGN_SYSTEM_COLORS.red['500'],
          border: `1px solid ${DESIGN_SYSTEM_COLORS.red['500']}`,
        },
        focus: {
          color: DESIGN_SYSTEM_COLORS.grayscale['0'],
          backgroundColor: DESIGN_SYSTEM_COLORS.red['500'],
          border: `1px solid ${DESIGN_SYSTEM_COLORS.red['500']}`,
        },
        active: {
          color: DESIGN_SYSTEM_COLORS.grayscale['0'],
          backgroundColor: DESIGN_SYSTEM_COLORS.red['500'],
          border: `1px solid ${DESIGN_SYSTEM_COLORS.red['500']}`,
        },
        disabled: {
          color: DESIGN_SYSTEM_COLORS.grayscale['650'],
          backgroundColor: DESIGN_SYSTEM_COLORS.grayscale['350'],
          border: `1px solid ${DESIGN_SYSTEM_COLORS.grayscale['350']}`,
        },
      },
    },
    false: {
      state: {
        default: {
          color: DESIGN_SYSTEM_COLORS.grayscale['0'],
          backgroundColor: DESIGN_SYSTEM_COLORS.red['500'],
          border: 'none',
        },
        hover: {
          color: DESIGN_SYSTEM_COLORS.grayscale['0'],
          backgroundColor: DESIGN_SYSTEM_COLORS.red['500'],
          border: 'none',
        },
        focus: {
          color: DESIGN_SYSTEM_COLORS.grayscale['0'],
          backgroundColor: DESIGN_SYSTEM_COLORS.red['500'],
          border: 'none',
        },
        active: {
          color: DESIGN_SYSTEM_COLORS.grayscale['0'],
          backgroundColor: DESIGN_SYSTEM_COLORS.red['500'],
          border: 'none',
        },
        disabled: {
          color: DESIGN_SYSTEM_COLORS.grayscale['650'],
          backgroundColor: DESIGN_SYSTEM_COLORS.grayscale['350'],
          border: `1px solid ${DESIGN_SYSTEM_COLORS.grayscale['350']}`,
        },
      },
    },
  },
};

export default warning;
