import { useToggle, UseToggleResult } from '@minecraft.utils';

export interface UseModalResult<T = Record<string, any>> extends Omit<UseToggleResult<T>, 'toggle'> {
  toggleModal(context?: T): void;
}

// Functions as an alias of useToggle
export const useModal = <T = Record<string, any>>(defaultState = false): UseModalResult<T> => {
  const { isOpen, toggle, open, close, modalContext, setContext } = useToggle<T>(defaultState);

  return { isOpen, modalContext, toggleModal: toggle, open, close, setContext };
};
