import colors from '../../colors';

const modal = {
  backgroundColor: colors.primary.white,
  borderColor: colors.primary.ltGrey1,
  color: colors.primary.darkGrey,
  overlayBackgroundColor: colors.primary.darkGrey,
};

export default modal;
