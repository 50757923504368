import React, { FC } from 'react';
import _isEmpty from 'lodash/isEmpty';
import Alert from './Alert';

function array<T>(value: T | T[]): T[] {
  return Array.isArray(value) ? value : [value];
}

export type Props = {
  size?: 'fullWidth' | 'inPage' | 'cbPublish';
  sticky?: boolean;
  errorMessages?: string[];
  successMessages?: string[];
  onClose?: () => void;
  qaId?: string;
};

const ValidationAlerts: FC<Props> = ({
  size = 'inPage',
  sticky = false,
  errorMessages = [],
  successMessages = [],
  onClose = () => undefined,
  qaId = 'alert',
}) => (
  <>
    {!_isEmpty(errorMessages) && (
      <Alert type="error" key="error-alert" size={size} sticky={sticky} onClose={onClose} data-qa-id={`${qaId}-error`}>
        {array(errorMessages).map((message) => (
          <div key={message}>{message}</div>
        ))}
      </Alert>
    )}

    {!_isEmpty(successMessages) && (
      <Alert
        type="success"
        key="success-alert"
        size={size}
        sticky={sticky}
        onClose={onClose}
        data-qa-id={`${qaId}-success`}
      >
        {array(successMessages).map((message) => (
          <div key={message}>{message}</div>
        ))}
      </Alert>
    )}
  </>
);

export default ValidationAlerts;
