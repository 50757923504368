import { getEnvironment } from '@minecraft.environment';
import { makeVar, throwServerError } from '@apollo/client';
import { TokenParsed } from '@minecraft.utils';
import { getRefreshToken, setRefreshToken, setAccessToken, getUnauthorizedResponse, parseJwt } from '../utils';

// this is used so that when the tokens get automatically refreshed
// by authErrorLink, we can update the UserContext.tokens which is
// where permissions are sourced from
export const authTokensVar = makeVar<{ access: string; refresh: string; accessParsed: TokenParsed }>({
  access: null,
  refresh: null,
  accessParsed: null,
});

let refreshInProgress;

/**
 * this function intentionally does not use the apollo client to make the refreshToken request
 * because the apollo client will try to attach the Authorization header to the request
 * by default, which will cause the request to fail if the token has expired
 * and the whole point of this mutation is to get a new token when the old one has expired
 */
export const refreshTokenApiCall = ({ skipTokensVarUpdate = false }: { skipTokensVarUpdate?: boolean } = {}) => {
  if (refreshInProgress) return refreshInProgress;

  const opts = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      operationName: 'refreshToken',
      variables: {
        refreshToken: getRefreshToken(),
      },
      query: `mutation refreshToken($refreshToken: String!) {
          refreshToken(refreshToken: $refreshToken) {
            access
            refresh
            __typename
          }
        }`,
    }),
  };

  refreshInProgress = fetch(getEnvironment().API_URL, opts)
    .then((res) => res.json())
    .then((res) => {
      refreshInProgress = null;
      const unauthorizedError = getUnauthorizedResponse(res);

      if (unauthorizedError) throwServerError({ status: 401 } as any, unauthorizedError, 'Unauthorized');

      const refresh = res?.data?.refreshToken?.refresh;
      const access = res?.data?.refreshToken?.access;

      if (refresh && access) {
        setRefreshToken(refresh);
        setAccessToken(access);

        if (!skipTokensVarUpdate) {
          authTokensVar({
            access,
            refresh,
            accessParsed: parseJwt(access),
          });
        }
      }

      return res;
    });

  return refreshInProgress;
};
