import React, { ChangeEventHandler } from 'react';
import styled from 'styled-components';
import { atBreakpoint } from '@minecraft.themes';
import { FlexDynamic } from './Containers';
import { BodyText } from './Typography';

const CheckboxWrapper = styled.label`
  display: inline-flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  margin: 0;
`;

const CheckboxInput = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`;

const CheckboxSlider = styled.div`
  width: 3rem;
  height: 1.8rem;
  background-color: ${(props) => props.theme.colors.primary.grey2};
  border-radius: 625rem;

  &:after {
    content: '';
    position: absolute;
    top: 0.2rem;
    left: -18px;
    background-color: ${(props) => props.theme.colors.primary.white};
    border: 1px solid ${(props) => props.theme.colors.primary.grey2};
    border-radius: 625rem;
    height: 1.4rem;
    width: 1.4rem;
    transition: all 0.3s;
    transform: translateX(100%);
    border-color: ${(props) => props.theme.colors.primary.white};
  }

  ${CheckboxInput}:checked + & {
    background-color: #1a79af;
    &:after {
      left: 0px;
    }
  }
`;

const CheckboxLabel = styled(BodyText)`
  ${atBreakpoint.sm`
  font-size: 4.3vw !important;
  `}
`;

export interface ToggleCheckboxProps {
  checked: boolean;
  isLocked?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  toggleOnText?: string;
  toggleOffText?: string;
  children?: React.ReactNode;
  ariaLabel?: string;
  dataQaId?: string;
}

export const ToggleCheckbox: React.FC<ToggleCheckboxProps> = ({
  checked,
  onChange,
  className = '',
  toggleOnText = '',
  toggleOffText = '',
  isLocked = false,
  children = null,
  ariaLabel = 'ToggleCheckbox',
  dataQaId = 'toggle-checkbox',
}) => {
  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isLocked) {
      onChange(event);
    }
  };
  return (
    <FlexDynamic
      className={`cn_w-full cn_md:w-auto ${className}`}
      direction="row"
      gap="1"
      alignItems="center"
      justifyContent="space-between"
      grow={0}
      mdProps={{
        gap: '3',
        justifyContent: 'flex-end',
        direction: 'row',
        alignItems: 'center',
      }}
    >
      {toggleOffText && (
        <CheckboxLabel fontWeight="medium" color={checked ? 'disabled' : 'default'}>
          {toggleOffText}
        </CheckboxLabel>
      )}
      <CheckboxWrapper>
        <CheckboxInput
          type="checkbox"
          checked={checked}
          onChange={handleToggleChange}
          aria-label={ariaLabel}
          data-qa-id={dataQaId}
        />
        <CheckboxSlider />
      </CheckboxWrapper>
      {toggleOnText && (
        <CheckboxLabel color={checked ? 'default' : 'disabled'} fontWeight="medium">
          {toggleOnText}
        </CheckboxLabel>
      )}
      {children}
    </FlexDynamic>
  );
};
