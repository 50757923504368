import React, { FC } from 'react';
import styled from 'styled-components';
import Icon from '@minecraft.icon';
import { ButtonBase } from './Button/ButtonBase';

const Square = styled(ButtonBase)`
  border: none;
  width: 1.25rem;
  height: 1.25rem;
  min-width: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${(props) => props.theme.designSystem.bgColors.iconSquareBg};
  padding: 0;

  &:active,
  &:not(:disabled):not(.disabled):active,
  :hover {
    background-color: ${(props) => props.theme.designSystem.bgColors.iconSquareHover};
    border: none;
  }
`;

const InnerIcon = styled(Icon)`
  display: block;
  width: 0.5rem;
  height: 0.5rem;
`;

export const IconSquare: FC<{ name: string }> = ({ name, ...props }) => {
  return (
    <Square {...props}>
      <InnerIcon name={name} />
    </Square>
  );
};
