import { appConfig } from '@minecraft.app-config';
import { gqlMediaTypeCodeEnum as MEDIA_TYPE } from '@minecraft.graphql-types';
import {
  extractMetaData,
  validateMetadata,
  isValidFileExtension,
  isValidMinimumFileSize,
  isValidMaximumFileSize,
} from '../helpers';

type MediaSettings = {
  types: Array<string>;
  extensions: Array<any>;
  minSize: string;
  maxSize: string;
  metaData: { minWidth?: number; sampleRate?: string };
  name: string;
};

const mediaSettings = {
  photo: {
    types: appConfig.photoExtensions,
    extensions: appConfig.photoExtensions,
    minSize: '50_KB',
    maxSize: '30_MB',
    metaData: {
      minWidth: 500,
    },
    name: 'Photo',
  },
  video: {
    types: appConfig.videoTypes,
    extensions: (appConfig as any).videoExtensions,
    minSize: '50_KB',
    maxSize: '1_GB',
    metaData: {
      minWidth: 396,
    },
    name: 'Video',
  },
  audio: {
    types: (appConfig as any).audioExtensions,
    extensions: (appConfig as any).audioExtensions,
    minSize: '50_KB',
    maxSize: '10_MB',
    metaData: {
      sampleRate: '8',
    },
    name: 'Audio',
  },
  document: {
    types: (appConfig as any).documentExtensions,
    extensions: (appConfig as any).documentExtensions,
    minSize: '1_KB',
    maxSize: '10_MB',
    name: 'Document',
    metaData: {},
  },
};

const getMediaSettings = (mediaType): MediaSettings => {
  switch (mediaType) {
    case MEDIA_TYPE.AUDIO:
      return mediaSettings.audio;
    case MEDIA_TYPE.VIDEO:
      return mediaSettings.video;
    case MEDIA_TYPE.PHOTO:
      return mediaSettings.photo;
    case MEDIA_TYPE.DOCUMENT:
      return mediaSettings.document;
    default:
      return null;
  }
};

const validationError = (mediaSetting) => ({
  FILE_TYPE_VALIDATION: `${mediaSetting.name} must be a ${mediaSetting.extensions
    .map((extension) => `${extension.toUpperCase()}`)
    .join(' ')}`,
  MIN_FILE_SIZE_VALIDATION: `${mediaSetting.name} must be larger than ${mediaSetting.minSize.replace('_', ' ')}`,
  MAX_FILE_SIZE_VALIDATION: `${mediaSetting.name} must be smaller than ${mediaSetting.maxSize.replace('_', ' ')}`,
  MIN_HEIGHT_VALIDATION: `${mediaSetting.name} must be at least ${mediaSetting.metaData.minHeight}px tall.`,
  MIN_WIDTH_VALIDATION: `${mediaSetting.name} must be at least ${mediaSetting.metaData.minWidth}px wide.`,
  MIN_RATE_VALIDATION: `Sample rate must be at least ${mediaSetting.metaData.sampleRate}kHz.`,
});

const validateMedia = (mediaType, file) => {
  const errors = [];

  const mediaSetting: MediaSettings = getMediaSettings(mediaType);

  if (!isValidFileExtension(file.type, mediaSetting.types) || !mediaSetting) {
    errors.push(validationError(mediaSetting).FILE_TYPE_VALIDATION);
  }

  return new Promise((resolve) => {
    if (!errors.length) {
      if (!isValidMinimumFileSize(file.size, mediaSetting.minSize)) {
        errors.push(validationError(mediaSetting).MIN_FILE_SIZE_VALIDATION);
      }

      if (!isValidMaximumFileSize(file.size, mediaSetting.maxSize)) {
        errors.push(validationError(mediaSetting).MAX_FILE_SIZE_VALIDATION);
      }

      extractMetaData(mediaType, file)
        .then((metaData) => {
          const metaDataErrors = validateMetadata(metaData, mediaSetting.metaData);

          metaDataErrors.forEach((error) => errors.push(validationError(mediaSetting)[error]));
          resolve(errors);
        })
        .catch((e) => console.error('Extract meta data error', { error: e }));
    } else {
      resolve(errors);
    }
  });
};

const Media = {
  validateMedia,
};

export default Media;
