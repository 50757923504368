import React from 'react';
import truncate from 'lodash/truncate';
import { useTranslation, TranslateFn } from '@blocs.i18n';
import CloudinaryPhoto from '@minecraft.cloudinary-photo';
import { AccountOrgFragment, SystemRoleCode } from '@minecraft.graphql-operations';
import {
  StyledDivisionSwitcherLogoItemDetails,
  StyledDivisionSwitcherLogoItemName,
  StyledDivisionSwitcherLogoItemUser,
  StyledDivisionSwitcherItemWrapper,
  StyledDivisionSwitcherDropdownItem,
  StyledLogoPlaceholder,
} from '../DivisionSwitcherStyles';

const CollaboratorSvg = () => {
  const { t } = useTranslation();
  const title = t('common:mainNav.projectsShared');

  return (
    <svg
      aria-labelledby="#collab-icon"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="collab-icon">{title} icon</title>
      <path
        d="M6.96525 10.0833H15.1197C15.3745 10.0833 15.6293 9.80833 15.6293 9.53333C15.6293 7.05833 13.8456 5.5 11.0425 5.5C8.23938 5.5 6.4556 7.05833 6.4556 9.53333C6.4556 9.80833 6.71042 10.0833 6.96525 10.0833Z"
        fill="#3A3A3A"
      />
      <path
        d="M11.0425 4.95C12.3166 4.95 13.3359 3.85 13.3359 2.475C13.3359 1.1 12.3166 0 11.0425 0C9.76834 0 8.74903 1.1 8.74903 2.475C8.74903 3.85 9.76834 4.95 11.0425 4.95Z"
        fill="#3A3A3A"
      />
      <path
        d="M4.58687 17.4167C1.78378 17.4167 0 18.975 0 21.45C0 21.725 0.254826 22 0.509653 22H8.66409C8.91892 22 9.17375 21.725 9.17375 21.45C9.17375 18.975 7.38996 17.4167 4.58687 17.4167Z"
        fill="#3A3A3A"
      />
      <path
        d="M4.58687 16.8667C5.861 16.8667 6.88031 15.7667 6.88031 14.3917C6.88031 13.0167 5.861 11.9167 4.58687 11.9167C3.31274 11.9167 2.29344 13.0167 2.29344 14.3917C2.29344 15.7667 3.31274 16.8667 4.58687 16.8667Z"
        fill="#3A3A3A"
      />
      <path
        d="M17.4131 16.8667C18.6873 16.8667 19.7066 15.7667 19.7066 14.3917C19.7066 13.0167 18.6873 11.9167 17.4131 11.9167C16.139 11.9167 15.1197 13.0167 15.1197 14.3917C15.1197 15.7667 16.139 16.8667 17.4131 16.8667Z"
        fill="#3A3A3A"
      />
      <path
        d="M13.5907 17.2107C13.7606 17.0274 13.7606 16.7524 13.5907 16.569L11.5521 14.3917V11.344C11.5521 11.069 11.2973 10.794 11.0425 10.794C10.7876 10.794 10.5328 11.069 10.5328 11.344V14.3917L8.66409 16.569C8.49421 16.7524 8.49421 17.119 8.66409 17.3024C8.83398 17.4857 9.17374 17.4857 9.34363 17.3024L11.0425 15.5833L12.9112 17.3024C13.166 17.4857 13.4208 17.4857 13.5907 17.2107Z"
        fill="#3A3A3A"
      />
      <path
        d="M17.4131 17.4167C14.61 17.4167 12.8263 18.975 12.8263 21.45C12.8263 21.725 13.0811 22 13.3359 22H21.4903C21.7452 22 22 21.725 22 21.45C22 18.975 20.2162 17.4167 17.4131 17.4167Z"
        fill="#3A3A3A"
      />
    </svg>
  );
};

const buildName = (org: AccountOrgFragment, t: TranslateFn) => {
  if (org?.systemRoleCode === SystemRoleCode.SharedProjectUser) {
    return t('common:mainNav.projectsShared');
  }

  const selectedDivisionName = org?.name || org?.parentOrganization?.name || '';

  return truncate(selectedDivisionName, { length: 22 });
};

const getLogoLetters = (org: AccountOrgFragment, name: string) => {
  // in case of reps app + divisions
  if (org?.parentOrganization) return org?.parentOrganization?.name?.charAt(0).toUpperCase() || '';

  // in case of ECD app - CD or PC
  if (name) {
    const array = name ? name.split(' ') : [];

    return array.reduce((result, item) => result.concat(item.charAt(0).toUpperCase()), '');
  }

  return '';
};

interface DivisionSwitcherLogoProps {
  org: AccountOrgFragment;
  qaId: string;
  name: string;
}

const DivisionSwitcherLogo = ({ org, qaId, name }: DivisionSwitcherLogoProps) => {
  if (org?.systemRoleCode === SystemRoleCode.SharedProjectUser) {
    return (
      <StyledLogoPlaceholder data-qa-id={`${qaId}-division-name-logo`} data-testid="switcher-account-initials">
        <CollaboratorSvg />
      </StyledLogoPlaceholder>
    );
  }

  if (org?.logo) {
    return (
      <CloudinaryPhoto
        data-qa-id={`${qaId}-division-name-logo`}
        type="logo"
        fileKey={org?.logo.fileKey}
        autoRotateDimensions={false}
      />
    );
  }

  const logoLetters = getLogoLetters(org, name);

  return (
    <StyledLogoPlaceholder data-qa-id={`${qaId}-division-name-logo`} data-testid="switcher-account-initials">
      {logoLetters}
    </StyledLogoPlaceholder>
  );
};

type DivisionSwitcherLogoItemProps = {
  org: AccountOrgFragment;
  onClick?: () => void;
  isPC?: boolean;
  username?: string;
  isPrimary?: boolean;
  qaId?: string;
  'data-testid'?: string;
};

const DivisionSwitcherLogoItem = ({
  onClick,
  org,
  isPC,
  username,
  isPrimary,
  qaId,
  'data-testid': testId,
}: DivisionSwitcherLogoItemProps): JSX.Element => {
  const { t } = useTranslation();
  const name = buildName(org, t);

  return (
    <>
      <StyledDivisionSwitcherDropdownItem onClick={onClick} tag="div" data-testid={testId}>
        <StyledDivisionSwitcherItemWrapper>
          <DivisionSwitcherLogo org={org} name={name} qaId={qaId} />
          <StyledDivisionSwitcherLogoItemDetails $isPrimary={isPrimary}>
            {username && (
              <StyledDivisionSwitcherLogoItemUser $isPC={isPC} data-testid="switcher-account-name">
                {username}
              </StyledDivisionSwitcherLogoItemUser>
            )}
            <StyledDivisionSwitcherLogoItemName
              $isPrimary={isPrimary}
              $isPC={isPC}
              data-qa-id={`${qaId}-division-name`}
              data-testid="switcher-org-name"
            >
              {name}
            </StyledDivisionSwitcherLogoItemName>
          </StyledDivisionSwitcherLogoItemDetails>
        </StyledDivisionSwitcherItemWrapper>
      </StyledDivisionSwitcherDropdownItem>
    </>
  );
};

export default DivisionSwitcherLogoItem;
