import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';
import { useSessionStorage } from '@minecraft.utils';

const sessionStorageKey = 'CurrentTheme';

export enum ThemeEnum {
  light = 'light',
  dark = 'dark',
}

type ThemeSwitcherProviderType = {
  defaultTheme?: ThemeEnum;
  children: JSX.Element;
};

type ThemeSwitcherContextType = {
  isDarkTheme: boolean;
  isLightTheme: boolean;
  currentTheme: ThemeEnum;
  handleThemeSwitch: () => void;
};

const ThemeSwitcherContext = createContext<ThemeSwitcherContextType>({
  isDarkTheme: false,
  isLightTheme: false,
  currentTheme: ThemeEnum.light,
  handleThemeSwitch: () => undefined,
});

export const useThemeSwitcherContext = (): ThemeSwitcherContextType => {
  return useContext(ThemeSwitcherContext);
};

const ThemeSwitcherProvider = ({
  defaultTheme = ThemeEnum.light,
  children,
}: ThemeSwitcherProviderType): JSX.Element => {
  const sessionStorage = useSessionStorage({ primaryKey: sessionStorageKey });

  const [currentTheme, setTheme] = useState(sessionStorage.read() || defaultTheme);

  const handleThemeSwitch = useCallback((): void => {
    if (currentTheme === ThemeEnum.light) {
      setTheme(ThemeEnum.dark);
      sessionStorage.write(ThemeEnum.dark);

      return;
    }

    setTheme(ThemeEnum.light);
    sessionStorage.write(ThemeEnum.light);
  }, [currentTheme]);

  const providerValue = useMemo(
    () => ({
      isDarkTheme: currentTheme === ThemeEnum.dark,
      isLightTheme: currentTheme === ThemeEnum.light,
      currentTheme,
      handleThemeSwitch,
    }),
    [currentTheme, handleThemeSwitch]
  );

  return <ThemeSwitcherContext.Provider value={providerValue}>{children}</ThemeSwitcherContext.Provider>;
};

export default ThemeSwitcherProvider;
