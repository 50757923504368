/**
 * MaxLength
 * @param value
 * @param max
 * @return {isMatch: boolean}
 */

export const MaxLength = (max, value) => {
  if (value.length > max) {
    return true;
  }
  return false;
};

export default MaxLength;
