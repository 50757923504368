import { designSystem } from '../designSystem';
import { Color } from '../../TSTypes/button';
import { DESIGN_SYSTEM_COLORS } from '../../constants';

const DangerColorState = {
  default: {
    color: designSystem.textColors.inverted,
    backgroundColor: DESIGN_SYSTEM_COLORS.red['400'],
    border: 'transparent',
    textDecoration: 'none',
  },
  hover: {
    color: designSystem.textColors.inverted,
    backgroundColor: DESIGN_SYSTEM_COLORS.red['300'],
    border: 'transparent',
    textDecoration: 'none',
  },
  focus: {
    color: designSystem.textColors.inverted,
    backgroundColor: DESIGN_SYSTEM_COLORS.red['400'],
    border: 'transparent',
    textDecoration: 'none',
    boxShadow: `0px 0px 0px 0.2rem ${DESIGN_SYSTEM_COLORS.blue['400']}`,
  },
  active: {
    color: designSystem.textColors.inverted,
    backgroundColor: DESIGN_SYSTEM_COLORS.red['400'],
    border: 'transparent',
    textDecoration: 'none',
  },
  disabled: {
    color: DESIGN_SYSTEM_COLORS.grayscale['650'],
    backgroundColor: DESIGN_SYSTEM_COLORS.grayscale['350'],
    border: `1px solid ${DESIGN_SYSTEM_COLORS.grayscale['350']}`,
  },
};

const DangerSecondaryColorState = {
  default: {
    color: DESIGN_SYSTEM_COLORS.red['400'],
    backgroundColor: 'transparent',
    border: `1px solid ${DESIGN_SYSTEM_COLORS.red['400']}`,
    textDecoration: 'none',
  },
  hover: {
    color: designSystem.textColors.inverted,
    backgroundColor: DESIGN_SYSTEM_COLORS.red['300'],
    border: 'transparent',
    textDecoration: 'none',
    boxShadow: `0px 0px 0px 0.2rem ${DESIGN_SYSTEM_COLORS.blue['400']}`,
  },
  focus: {
    color: DESIGN_SYSTEM_COLORS.red['400'],
    backgroundColor: 'transparent',
    border: `1px solid ${DESIGN_SYSTEM_COLORS.red['400']}`,
    textDecoration: 'none',
    boxShadow: `0px 0px 0px 0.2rem ${DESIGN_SYSTEM_COLORS.blue['400']}`,
  },
  active: {
    color: DESIGN_SYSTEM_COLORS.red['400'],
    backgroundColor: 'transparent',
    border: `1px solid ${DESIGN_SYSTEM_COLORS.red['400']}`,
    textDecoration: 'none',
  },
  disabled: {
    color: DESIGN_SYSTEM_COLORS.grayscale['650'],
    backgroundColor: DESIGN_SYSTEM_COLORS.grayscale['350'],
    border: `1px solid ${DESIGN_SYSTEM_COLORS.grayscale['350']}`,
  },
};

const DangerColor: Color = {
  outlined: {
    true: {
      state: DangerSecondaryColorState,
    },
    false: {
      state: DangerColorState,
    },
  },
};

export default DangerColor;
