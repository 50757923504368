export enum BrazeEvents {
  RegistrationPageComplete = 'registration_page_complete',
  PCRegistrationPageComplete = 'pc_registration_page_complete',
  PCEmailVerificationComplete = 'pc_email_verification_complete',
  ProfileCreationComplete = 'profile_creation_complete',
  PCProfileCreationComplete = 'pc_profile_creation_complete',
  UploadedPhoto = 'uploaded_photo',
  UploadedVideo = 'uploaded_video',
  UploadedAudio = 'uploaded_audio',
  SubmittedToRole = 'submitted_to_role',
  AudioScheduleReceived = 'audition_schedule_received',
  MembershipExpired = 'membership_expired',
  PushPrimer = 'prime-for-push',
  SignupVariantControl = 'talent_sign_up_test_control',
  SignupVariantEqAffordance = 'talent_sign_up_test_ea',
  SignupVariantUpdatedDesign = 'talent_sign_up_test_ud',
  MediaRequestViewedByTalent = 'media_request_viewed_talent',
  SavedSearchCreated = 'saved_search_created',
  SavedSearchNotificationOptIn = 'saved_search_notification_optin',
  TalentTapsGlobalUpgradeButton = 'talent_taps_global_upgrade_button',
  TalentScoutSaveButton = 'talent_scout_save_button',
}
