import { ACCESS_TOKEN, DYNATRACE_USER, REFRESH_TOKEN, ONBOARDING_TOKEN, MARKETING_REDIRECT } from '../constants';
import { parseJwt } from './token';

/**
 * Dynatrace functions below are local to this file only and should not be re-used or expanded if more dynatrace
 * functionality is needed.
 * Instead dynatrace client library should be implemented in every app as a provider like Appcues in ECD.
 */
const setDynatraceUser = (accessToken: string, isOnboarding = false): void => {
  const tokenInfo = parseJwt(accessToken);

  if (tokenInfo?.email) {
    localStorage.setItem(DYNATRACE_USER, tokenInfo.email);
  }
  // as onboarding user doesn't have email in token and it can be he doesn't have email at all
  // we use id
  else if (isOnboarding) {
    localStorage.setItem(DYNATRACE_USER, `Onboarding(${tokenInfo?.accountId})`);
  }
};

const deleteDynatraceUser = (): void => {
  localStorage.removeItem(DYNATRACE_USER);
};

export const getAccessToken = (): string => {
  return localStorage.getItem(ACCESS_TOKEN);
};

export const setAccessToken = (token: string): void => {
  localStorage.setItem(ACCESS_TOKEN, token);
  setDynatraceUser(token);
};

export const deleteAccessToken = (): void => {
  localStorage.removeItem(ACCESS_TOKEN);
  deleteDynatraceUser();
};

export const getRefreshToken = (): string => {
  return localStorage.getItem(REFRESH_TOKEN);
};

export const setRefreshToken = (token: string): void => {
  localStorage.setItem(REFRESH_TOKEN, token);
};

export const deleteRefreshToken = (): void => {
  localStorage.removeItem(REFRESH_TOKEN);
};

export const clearStorage = (): void => {
  deleteAccessToken();
  deleteRefreshToken();
  sessionStorage.clear();
};

// onboarding
export const getOnboardingToken = (): string => {
  return localStorage.getItem(ONBOARDING_TOKEN);
};

export const setOnboardingToken = (token: string): void => {
  localStorage.setItem(ONBOARDING_TOKEN, token);
  setDynatraceUser(token);
};

export const deleteOnboardingToken = (deleteDynatrace = true): void => {
  localStorage.removeItem(ONBOARDING_TOKEN);

  if (deleteDynatrace) deleteDynatraceUser();
};

// marketing redirect
export const getMarketingRedirect = (): string => {
  return localStorage.getItem(MARKETING_REDIRECT);
};

export const removeMarketingRedirect = (): void => {
  localStorage.removeItem(MARKETING_REDIRECT);
};

export const saveMarketingRedirect = (redirectUrl: string): void => {
  localStorage.setItem(MARKETING_REDIRECT, redirectUrl);
};
