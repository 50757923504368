import additional from '../../CoreTheme/button/additionalColor';
import link from '../../CoreTheme/button/linkColor';
import modernLink from '../../CoreTheme/button/modernLinkColor';
import { Button } from '../../TSTypes/button';
import primary from './primary';
import warning from './warning';

const button: Button = {
  borderRadius: '0.25rem',
  height: '2rem',
  height40: '2.5rem',
  color: {
    primary,
    additional,
    link,
    warning,
    modernLink,
  },
  size: {
    small: {
      fontWeight: '600',
      fontSize: '0.75rem',
      padding: '0.25rem 1.25rem',
      minWidth: '6.875rem',
    },
    large: {
      fontWeight: '500',
      fontSize: '0.875rem',
      padding: '0.3125rem 2rem',
      minWidth: '8.75rem',
    },
  },
};

export default button;
