import { LDSingleKindContext } from 'launchdarkly-js-client-sdk';
import { gqlSystemRoleCode as SYSTEM_ROLE } from '@minecraft.graphql-types';
import { TokenParsed } from '@minecraft.utils';
import { getAccessToken } from '../Auth/utils/authStorage';
import { parseJwt } from '../Auth/utils/token';

export enum FEATURE_FLAGS {
  ALACARTE_MEDIA_PURCHASE = 'a-la-carte-media-purchase',
  AUDITIONS = 'web-auditions',
  BE_COUPON_CODE = 'be-apply-account-coupon-code',
  CASTING_NOTIFICATION_SETTINGS = 'casting-notification-settings',
  CAST_IT_STUDIO_PHASE_1 = 'web-cast-it-studio-phase-1',
  ENABLE_ESSENTIAL_PLAN = 'web-enable-essential-plan-paywall',
  FE_COUPON_CODE = 'web-account-coupon-code',
  GLOBAL_MATTERHORN_P1 = 'global-matterhorn-p1',
  GLOBAL_SELF_TAPES_PIVOT = 'global-self-tapes-pivot',
  IMPACTED_SERVICE = 'web-impacted-service',
  QA_VIEW_MAINTENANCE = 'web-qa-maintenance-flag',
  ROLE_SUBMISSION_MEDIA_SESSION = 'role-submission-media-session',
  SCHEDULED_MAINTENANCE = 'web-scheduled-maintenance-flag',
  SUBMISSION_TRENDS = 'web-submission-trends',
  TALENT_SCOUT_PHASE_1 = 'web-talent-scout-phase-1',
  ULTIMATE_SUBSCRIPTION_PLAN = 'ultimate-subscription-plan',
  UNSCHEDULED_MAINTENANCE = 'web-unscheduled-maintenance-flag',
  VIRTUAL_AUDITIONS = 'web-virtual-auditions',
  WEB_AUDITION_TYPE = 'web-audition-type',
  WEB_AUGEO_BENEFITS = 'web-augeo-benefits',
  WEB_CASTING_COLLABORATORS = 'web-casting-collaborators',
  WEB_CASTING_PICKS_REPORT_TALENT = 'web-casting-picks-report-talent',
  WEB_CUSTOM_URL = 'web-custom-url',
  WEB_DRAFT_REQUESTS = 'web-draft-requests',
  WEB_FORMS = 'web-forms',
  WEB_NEW_MOBILE_REGISTRATION = 'web-new-mobile-registration-flow-experiment',
  WEB_ONBOARDING_DEPRECATION = 'web-onboarding-deprecation',
  WEB_OPEN_CALLS = 'web-open-call-role',
  WEB_PRESENTATION_FORMS_EXPORT = 'web-presentation-forms-export',
  WEB_PROJECT_CREATE_WIZARD = 'web-project-create-wizard',
  WEB_REPS_ALL_TALENT_REPORT = 'web-reps-all-talent-report',
  WEB_ROLE_TRACKER = 'web-role-tracker',
  WEB_SESSION_NAVIGATION = 'web-sessions-navigation',
  WEB_SHOW_SOFT_LIMIT_FOR_PREMIUM = 'web-show-soft-limit-for-premium',
  WEB_TECH_HIGH_BANDWIDTH_VIDEO_FIX = 'web-tech-high-bandwitdh-video-transform',

  NORMALIZE_VIDEO_TRANSFORMATIONS = 'web-normalize-video-transformations',
}

// These are features that have been shipped and are in transition of being removed
// During this time we want to be sure users are getting the absolute value of the flag
// even if they have turned off the LD client calls in their browser
// These should only be set when the feature has been shipped and is in the process of being removed
//
// These are set to "true" and pushed to production. Then the code can be incrementally removed with less risk
export const MIGRATED_FEATURE_FLAGS = {
  // Example: `[SUBMISSION_TRENDS]: true` would permanently set that flag to true and tests would still pass
  [FEATURE_FLAGS.GLOBAL_MATTERHORN_P1]: true,
};

export const MAINTENANCE_OVERRIDE_COOKIE_VALUE = 'maintenance_override';

export const canSkipMaintenanceGuard = () => {
  // Getting cookie that will allow super-user to bypass splash screen for tests
  const maintModeCookie = Object.fromEntries(
    document.cookie
      .split(/; */)
      .filter((row) => row.startsWith(MAINTENANCE_OVERRIDE_COOKIE_VALUE))
      .map((cookie) => cookie.split('=', 2))
  );

  return maintModeCookie?.[MAINTENANCE_OVERRIDE_COOKIE_VALUE] === 'true';
};

const getSecondaryKey = (parsedToken: TokenParsed) => {
  // secondary attribute for a TALENT is artistId
  if (parsedToken?.role?.code === SYSTEM_ROLE.TALENT) {
    return parsedToken?.artistId?.toString();
  }

  // for all other users, user their organizationId
  return parsedToken?.organizationId?.toString();
};

// this looks up the user's access token out of localstorage by default
export const buildFeatureUser = (token?: string) => {
  const accessToken = token || getAccessToken();
  const user: LDSingleKindContext = {
    kind: 'user',
    anonymous: !accessToken,
    isQa: canSkipMaintenanceGuard(),
  };

  if (accessToken) {
    const parsed = parseJwt(accessToken);

    user.email = parsed?.email;
    user.key = parsed?.id?.toString();
    user.secondary = getSecondaryKey(parsed);
    user.organizationId = parsed?.organizationId?.toString();
    user.artistId = parsed?.artistId?.toString();
    user.role = parsed?.role?.code;
  }

  user.windowWidth = window.innerWidth;

  return user;
};
