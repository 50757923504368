import {
  init,
  setTag,
  setContext,
  setUser,
  reactRouterV5Instrumentation,
  captureMessage,
  captureException,
} from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { setRouterHistory } from '@minecraft.utils';
import { getEnvironment } from '@minecraft.environment';
import { PRODUCTION, STAGING } from '../Auth/constants';
import { parseJwt } from '../Auth/utils/token';
import { getAccessToken } from '../Auth/utils/authStorage';

const ignoreErrors = [
  'Invalid username/password',
  'ResizeObserver loop limit exceeded',
  'TypeError: Failed to fetch',
  'TypeError: Cancelled',
  'An account with this email already exists',
  "We couldn't find an account associated with that email.",
  'GraphQL error: Username or Password is incorrect',
  'Username or Password is incorrect',
  'This account has not been claimed yet. Please claim your account to reset password or login.',
  'GraphQL error: emailAlreadyInUseError',
  'emailAlreadyInUseError',
  'Email link expired',
];

const initSentry = () => {
  const environment = getEnvironment();

  const history = setRouterHistory({
    basename: environment.BASE_NAME,
  });

  init({
    beforeSend(event) {
      // Prevents from logs to be sent in development.
      if (event.environment === 'dev') return null;

      const tokenInfo = parseJwt(getAccessToken());

      setTag('Audience', environment.AUDIENCE);
      setContext('Window Resolution', {
        innerHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
        innerWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
      });
      setUser(tokenInfo);

      return event;
    },
    dsn: environment.SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: reactRouterV5Instrumentation(history),
      }),
    ],
    environment: environment.ENV,
    release: environment.B21_RELEASE,
    maxBreadcrumbs: 10,
    tracesSampleRate: 0.05,
    normalizeDepth: 5,
    ignoreErrors,
    enabled: [PRODUCTION, STAGING].includes(environment.ENV),
  });
};

export function captureGqlAndNetworkErrors(error) {
  if (!error) return;

  if (error.graphQLErrors) {
    error.graphQLErrors.map(({ message }) => captureMessage(message));
  }

  if (error.networkError) {
    captureException(error.networkError);
  }
}

export const captureExceptionEvent = (error: any, tags: Record<string, string | number> = {}) => {
  const tokenInfo = parseJwt(getAccessToken());

  captureException(error, {
    tags: {
      accountId: tokenInfo?.id,
      artistId: tokenInfo?.artistId,
      organizationId: tokenInfo?.organizationId,
      role: tokenInfo?.role?.code,
      email: tokenInfo?.email,
      ...tags,
    },
  });
};

export default initSentry;
