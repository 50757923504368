import { css } from 'styled-components';
import { Medias, Grid } from '../TSTypes/grid';

export const grid: Grid = {
  breakpoints: {
    xsm: '0px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xlg: '1200px',
    xxlg: '1440px',
    xxxlg: '1600px',
  },
};

export const media: Medias = Object.keys(grid.breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${grid.breakpoints[label]}) {
      ${
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/ban-ts-comment
        // @ts-ignore
        css(...args)
      };
    }
  `;

  return acc;
}, {} as Medias);

export const atBreakpoint: Medias = Object.keys(grid.breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${grid.breakpoints[label]}) {
      ${
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/ban-ts-comment
        // @ts-ignore
        css(...args)
      };
    }
  `;

  return acc;
}, {} as Medias);
