import * as braze from '@braze/web-sdk';
import { getEnvironment } from '@minecraft.environment';
import { getAccessToken } from '../Auth/utils/authStorage';
import { parseJwt } from '../Auth/utils/token';
import { PRODUCTION } from '../Auth/constants';
import { BrazeEvents } from './BrazeEvents';
import './braze-service.worker';

export const PUSH_PRIMER_ID = 'push-primer';

/* Please refer to the:
    https://www.braze.com/docs/developer_guide/platform_integration_guides/web/push_notifications/integration/#soft-push-prompts
 */
function initBrazeSoftPushNotifications(): void {
  braze.subscribeToInAppMessage((inAppMessage) => {
    let shouldDisplay = true;

    if (inAppMessage instanceof braze.FullScreenMessage || inAppMessage instanceof braze.ModalMessage) {
      // Read the key-value pair for msg-id
      const msgId = inAppMessage.extras['msg-id'];

      // If this is our push primer message
      if (msgId === PUSH_PRIMER_ID) {
        // We don't want to display the soft push prompt to users on browsers that don't support push, or if the user
        // has already granted/blocked permission
        if (!braze.isPushSupported() || braze.isPushPermissionGranted() || braze.isPushBlocked()) {
          shouldDisplay = false;
        }

        if (inAppMessage.buttons[0] !== null) {
          // Prompt the user when the first button is clicked
          inAppMessage.buttons[0].subscribeToClickedEvent(() => {
            braze.requestPushPermission();
          });
        }
      }
    }

    // Display the message
    if (shouldDisplay) {
      braze.showInAppMessage(inAppMessage);
    }
  });
}

// Returning an object so we can chain the methods
// Example: initBraze().initializeInAppMessaging()
const brazeEvents = {
  // we want this to be true by default when running tests,
  // otherwise it should initialize to false
  isBrazeReady: false,

  initBraze() {
    const environment = getEnvironment();
    brazeEvents.isBrazeReady = braze.initialize(environment.BRAZE_API_KEY, {
      baseUrl: environment.BRAZE_API_ENDPOINT,
      enableLogging: environment.ENV !== PRODUCTION,
      serviceWorkerLocation: `/${environment.AUDIENCE}/braze-service.worker.js`,
      safariWebsitePushId: 'web.com.castingnetworks',
      allowUserSuppliedJavascript: true,
      openCardsInNewTab: true,
      openInAppMessagesInNewTab: true,
    });

    return brazeEvents;
  },
  /**
   * Initialize the Braze tracking service
   * This will attempt to setup the user as well using the current access token
   */
  initializeInAppMessaging() {
    if (brazeEvents.isBrazeReady) {
      initBrazeSoftPushNotifications();

      brazeEvents.initializeUser();

      brazeEvents.trackBrazeEvent(BrazeEvents.PushPrimer);
    } else {
      console.warn('Braze WebSDK was not initialized');
    }

    return brazeEvents;
  },

  /**
   * Initialize the user based on the current access token
   * @param [accessToken: string] - Optional accessToken to get the userId from
   */
  initializeUser(accessToken: string | null = null) {
    if (brazeEvents.isBrazeReady) {
      const uId: string = parseJwt(accessToken ?? getAccessToken())?.id?.toString();
      braze.changeUser(uId);

      braze.openSession();
    } else {
      console.warn('Braze WebSDK was not initialized');
    }

    return brazeEvents;
  },

  trackBrazeEvent(event: BrazeEvents, eventProperties = {}): void {
    brazeEvents.isBrazeReady && braze.logCustomEvent(event, eventProperties);
  },

  /**
   * Set a custom user attribute, this attribute simply carries around with the user
   * Documentation: https://www.braze.com/docs/developer_guide/platform_integration_guides/web/analytics/setting_custom_attributes/#assigning-custom-user-attributes
   */
  setCustomUserAttribute(attributeName: string, value: string | number | boolean | Date | string[]) {
    brazeEvents.isBrazeReady && braze.getUser()?.setCustomUserAttribute(attributeName, value);
  },

  getUnreadContentCardsCount(successCb: (count: number) => void): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!brazeEvents.isBrazeReady) {
        console.warn('Braze WebSDK was not initialized');
        resolve();
      }

      braze.requestContentCardsRefresh(
        () => {
          if (brazeEvents.isBrazeReady) {
            const unreadCount = braze.getCachedContentCards().getUnviewedCardCount();
            successCb(unreadCount);
          }
          resolve();
        },
        () => {
          reject(new Error('failed to refresh content cards data'));
        }
      );
    });
  },

  toggleContentCards(cardWrapperId: string) {
    brazeEvents.isBrazeReady && braze.toggleContentCards(document.getElementById(cardWrapperId));
  },
};

export const {
  initBraze,
  initializeUser,
  trackBrazeEvent,
  setCustomUserAttribute,
  getUnreadContentCardsCount,
  toggleContentCards,
} = brazeEvents;
