import { useMemo } from 'react';
import UAParser from 'ua-parser-js';

export enum LoginType {
  USERNAME = 'Username',
  EMAIL = 'Email',
}

export const getDeviceInfo = (device: UAParser.IDevice) => {
  try {
    const vendor = device?.vendor || '';
    const model = device?.model || '';
    return [vendor, model].filter(Boolean).join(' ').trim() || 'Unknown device';
  } catch (error) {
    console.error('Error getting device info:', error);
    return 'Unknown device';
  }
};

export const getBrowserInfo = (browser: UAParser.IBrowser) => {
  try {
    const name = browser.name || '';
    const version = browser.version || '';
    return [name, version].filter(Boolean).join(' v').trim() || 'Unknown browser';
  } catch (error) {
    console.error('Error getting browser info:', error);
    return 'Unknown browser';
  }
};

export const useDeviceAndBrowserInfo = () => {
  return useMemo(() => {
    try {
      const parser = new UAParser();
      const { browser, device } = parser.getResult();

      return {
        deviceInfo: getDeviceInfo(device),
        browserInfo: getBrowserInfo(browser),
      };
    } catch (error) {
      console.error('Error parsing device and browser info:', error);
      return {
        deviceInfo: 'Unknown device',
        browserInfo: 'Unknown browser',
      };
    }
  }, []);
};
