import 'config/environment';
import 'core-js/features/object/from-entries';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { getEnvironment } from '@minecraft.environment';
import initSentry from '@blocs.sentry';
import { initBraze } from '@blocs.braze';
import App from './App';

const environment = getEnvironment();

initSentry();
initBraze();

TagManager.initialize({
  gtmId: environment.GOOGLE_TAG_MANAGER_ID,
  auth: environment.GOOGLE_TAG_MANAGER_ENVIRONMENT_AUTH,
  preview: environment.GOOGLE_TAG_MANAGER_ENVIRONMENT_PREVIEW,
});

ReactDOM.render(<App />, document.getElementById('app'));
