import { useCallback } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { getEnvironment } from '@minecraft.environment';
import { initializeUser } from '@blocs.braze';
import { setHeapIdentity, addUserProperties, addEventProperties } from '@blocs.heap';
import { PRODUCTION, STAGING } from '../Auth/constants';
import { buildFeatureUser } from './utils';
import { TrackingEventName, TRACKING_EVENT } from './types';

interface FeatureExperimentUserHook {
  identify: (accessToken: string) => Promise<void>;
  track: (eventName: TrackingEventName, data?: Record<string, any>, flushEvents?: boolean) => Promise<void>;
}

export const useFeatureExperimentUser = (): FeatureExperimentUserHook => {
  const ldClient = useLDClient();
  const ldUserId = ldClient?.getContext()?.key as string;

  // updates the user context being used by launch darkly and re-evaluates
  // feature flags for the user
  const identify = useCallback(
    async (accessToken: string) => {
      const environment = getEnvironment();
      await ldClient
        ?.identify({ kind: 'user', ...buildFeatureUser(accessToken) })
        ?.catch((e) => console.error('identify feature user error', { error: e }));

      if (process.env.TEST || [PRODUCTION, STAGING].includes(environment.ENV)) {
        try {
          // Initialize Heap
          setHeapIdentity(accessToken);
          addUserProperties({ ldUserId });
          // Also setup Braze to track the correct user
          initializeUser(accessToken);
        } catch (e) {
          console.error(e);
        }
      }
    },
    [ldClient]
  );

  // fires a custom tracking event for metrics purposes
  // launch darkly batches events and sends them on an interval
  // if your event is firing before a page-change or redirect
  // you will need to set flushEvents = true
  // in order to send the events synchronously so they do not
  // get wiped out when the page redirects
  const track = useCallback(
    async (eventName: TrackingEventName, data = {}, flushEvents = false) => {
      const trackedEvent = TRACKING_EVENT[eventName] || eventName;

      ldClient?.track(trackedEvent, data);

      if (flushEvents) {
        await ldClient?.flush();
      }
    },
    [ldClient]
  );

  // get LaunchDarkly feature flags and attach as properties to all events
  const flagsToTrack = ['self-service-cancellation', 'essential-subscription-trial'];
  const ldProps = () => {
    // check if ldClient is available
    if (ldClient?.allFlags) {
      const allFlags = ldClient.allFlags();
      // Filter out flags that we don't want to track
      return (
        allFlags &&
        Object.entries(allFlags).reduce((acc, [key, value]) => {
          if (flagsToTrack.includes(key)) {
            acc[key] = value;
          }
          return acc;
        }, {})
      );
    }
    return {};
  };

  addEventProperties(ldProps());

  return {
    identify,
    track,
  };
};
