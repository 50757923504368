import { THEME_NAMES } from '../constants';
import EnterpriseTheme from '../EnterpriseTheme';
import colors from '../colors';

const PCTheme = {
  ...EnterpriseTheme,
  name: THEME_NAMES.pc,
  globalHeader: {
    colors: {
      ...EnterpriseTheme.globalHeader.colors,
      backgroundColor: colors.primary.white,
      brand: colors.primary.darkGrey,
      link: colors.primary.darkGrey,
      border: colors.primary.ltGrey1,
    },
  },
};

export default PCTheme;
