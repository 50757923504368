import React from 'react';
import { Button as ReactStrapButton, ButtonProps as ReactStrapButtonProps } from 'reactstrap';
import styled, { useTheme } from 'styled-components';
import { Theme } from '@minecraft.themes';
import Icon from '@minecraft.icon';
import { WindowWidthContext } from '@minecraft.utils';

/**
 * additional and link are deprecated
 */
export type ButtonColor = 'primary' | 'additional' | 'link' | 'warning' | 'modernLink';

export type ButtonSize = 'small' | 'large';

const SIZE: Record<string, ButtonSize> = {
  small: 'small',
  large: 'large',
};

export type ButtonProps = {
  color?: ButtonColor;
  isSecondary?: boolean;
  size?: ButtonSize;
  /**
   * @deprecated Use "isSecondary" instead
   */
  outlined?: boolean | string;
  theme?: Theme;
  children: React.ReactNode;
  /**
   * @deprecated
   */
  $isTall?: boolean;
  iconName?: string;
  fixedWidth?: boolean;
  fixedHeight?: boolean;
  mobileBlock?: boolean;
  /**
   * Set to true when you are using the `p-X` modern classes for padding and spacing
   * This removes any built-in padding that is provided by the theme
   */
  noPadding?: boolean;
  /**
   * Set to false to prevent text-decoration: underline being set (mainly by "link" version)
   */
  noUnderline?: boolean;
} & ReactStrapButtonProps;

interface StyledButtonProps {
  $isTall?: boolean;
  $outlined?: boolean | string;
  $size: ButtonSize;
  $color: ButtonColor;
  $fixedWidth: boolean;
  $fixedHeight: boolean;
  $noPadding?: boolean;
  $noUnderline?: boolean;
  theme: Theme;
}

const getMinWidth = ({ $fixedWidth, $size, theme }: Pick<StyledButtonProps, '$fixedWidth' | '$size' | 'theme'>) => {
  if (!$fixedWidth) {
    return '1px';
  }

  return $size === SIZE.large ? theme.button.size.large.minWidth : theme.button.size.small.minWidth;
};

const getHeight = ({ $fixedHeight, $isTall, theme }: Pick<StyledButtonProps, '$fixedHeight' | '$isTall' | 'theme'>) => {
  if (!$fixedHeight) {
    return 'auto';
  }

  return $isTall ? theme.button.height40 : theme.button.height;
};

const StyledButton = styled(ReactStrapButton)<StyledButtonProps>`
  border-radius: ${({ theme }): string => theme.button.borderRadius};
  height: ${({ theme, $isTall, $fixedHeight }): string => getHeight({ theme, $isTall, $fixedHeight })};
  min-width: ${({ theme, $size, $fixedWidth }) => getMinWidth({ theme, $size, $fixedWidth })};

  color: ${({ theme, $color, $outlined }): string =>
    theme.button.color[$color].outlined[String($outlined)].state.default.color};
  background-color: ${({ theme, $color, $outlined }): string =>
    theme.button.color[$color].outlined[String($outlined)].state.default.backgroundColor};
  border: ${({ theme, $color, $outlined }): string =>
    theme.button.color[$color].outlined[String($outlined)].state.default.border};

  ${({ theme, $color, $outlined, $noUnderline }): string =>
    theme.button.color[$color].outlined[String($outlined)].state.default.textDecoration &&
    !$noUnderline &&
    `
    text-decoration: ${theme.button.color[$color].outlined[String($outlined)].state.default.textDecoration};
  `}

  font-weight: ${({ theme, $size }): string => theme.button.size[$size].fontWeight};
  font-size: ${({ theme, $size }): string => theme.button.size[$size].fontSize};
  padding: ${({ theme, $noPadding, $size }): string => ($noPadding ? '0' : theme.button.size[$size].padding)};

  &:hover {
    color: ${({ theme, $color, $outlined }): string =>
      theme.button.color[$color].outlined[String($outlined)].state.hover.color};
    background-color: ${({ theme, $color, $outlined }): string =>
      theme.button.color[$color].outlined[String($outlined)].state.hover.backgroundColor};
    border: ${({ theme, $color, $outlined }): string =>
      theme.button.color[$color].outlined[String($outlined)].state.hover.border};

    ${({ theme, $color, $outlined }): string =>
      theme.button.color[$color].outlined[String($outlined)].state.hover.textDecoration &&
      `
      text-decoration: ${theme.button.color[$color].outlined[String($outlined)].state.hover.textDecoration};
    `}
  }

  &:focus {
    color: ${({ theme, $color, $outlined }): string =>
      theme.button.color[$color].outlined[String($outlined)].state.focus.color};
    background-color: ${({ theme, $color, $outlined }): string =>
      theme.button.color[$color].outlined[String($outlined)].state.focus.backgroundColor};
    border: ${({ theme, $color, $outlined }): string =>
      theme.button.color[$color].outlined[String($outlined)].state.focus.border};

    ${({ theme, $color, $outlined }): string =>
      theme.button.color[$color].outlined[String($outlined)].state.focus.boxShadow &&
      `
      box-shadow: ${theme.button.color[$color].outlined[String($outlined)].state.focus.boxShadow};
    `}
  }

  &:active,
  &:not(:disabled):not(.disabled):active {
    color: ${({ theme, $color, $outlined }): string =>
      theme.button.color[$color].outlined[String($outlined)].state.active.color};
    background-color: ${({ theme, $color, $outlined }): string =>
      theme.button.color[$color].outlined[String($outlined)].state.active.backgroundColor};
    border: ${({ theme, $color, $outlined }): string =>
      theme.button.color[$color].outlined[String($outlined)].state.active.border};

    ${({ theme, $color, $outlined }): string =>
      theme.button.color[$color].outlined[String($outlined)].state.active.textDecoration &&
      `
      text-decoration: ${theme.button.color[$color].outlined[String($outlined)].state.active.textDecoration};
    `}
  }

  &:disabled {
    color: ${({ theme, $color, $outlined }): string =>
      theme.button.color[$color].outlined[String($outlined)].state.disabled.color};
    background-color: ${({ theme, $color, $outlined }): string =>
      theme.button.color[$color].outlined[String($outlined)].state.disabled.backgroundColor};
    border: ${({ theme, $color, $outlined }): string =>
      theme.button.color[$color].outlined[String($outlined)].state.disabled.border};

    ${({ theme, $color, $outlined }): string =>
      theme.button.color[$color].outlined[String($outlined)].state.disabled.textDecoration &&
      `
      text-decoration: ${theme.button.color[$color].outlined[String($outlined)].state.disabled.textDecoration};
    `}
  }
`;

/**
 * @deprecated use the PrimaryButton etc from @minecraft.atoms
 */
const Button: React.FC<ButtonProps> = ({
  isSecondary,
  outlined = 'false',
  size = 'large',
  color = 'primary',
  isTall = false,
  iconName,
  className,
  noPadding = false,
  children,
  fixedWidth = true,
  fixedHeight = true,
  mobileBlock = false,
  block,
  noUnderline,
  ...rest
}) => {
  const { button } = useTheme() as Theme;
  const { lessMd } = WindowWidthContext.useWidthData();
  const outlinedProp = isSecondary == null ? outlined.toString() : isSecondary.toString();

  return (
    <StyledButton
      $fixedWidth={fixedWidth}
      $size={size}
      $color={color}
      $outlined={outlinedProp}
      $isTall={isTall}
      $fixedHeight={fixedHeight}
      $noUnderline={noUnderline}
      className={`cn_atom_p-1 ${className || ''}`}
      $noPadding={noPadding}
      block={block || (mobileBlock && lessMd)}
      {...rest}
      // these data-attributes can be used in test assertions
      // so that you dont need to assert against the styles
      // data-btn-color is the value of the color prop
      data-btn-color={color}
      // data-btn-outlined indicates if this is the "secondary" version of the button
      data-btn-outlined={outlinedProp}
    >
      {iconName && (
        <Icon
          inheritColor
          name={iconName}
          height={button.size[size].fontSize}
          width={button.size[size].fontSize}
          className={children ? 'cn_mr-3' : ''}
        />
      )}
      {children}
    </StyledButton>
  );
};

export default Button;
