import React, { FC } from 'react';
import { QueryResult } from '@apollo/client';
import Skeleton, { SkeletonProps } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ErrorMessage from '@minecraft.error-message';

type QueryResultData = any;

export type SkeletonLoaderProps<QueryResults = QueryResultData[]> = {
  queries: Array<QueryResult<any, Record<string, any>>>;
  children: (data: QueryResults) => React.ReactNode;
  LoaderComponent?: FC | React.ReactNode;
  defaultSkeletonProps?: SkeletonProps;
  ErrorComponent?: FC | React.ReactNode;
  defaultErrorProps?: any;
  defaultErrorMessage?: string;
};

/**
 * Get the status of an array of queries
 *
 * @example
 * const { isLoading, hasError, data } = useQueries([QUERY_1, QUERY_2]);
 *
 * if (isLoading) return <Loading />
 * if (hasError) return <Error />
 */
const getQueryStatus = (queries: Array<QueryResult<any, Record<string, any>>>) => {
  const isLoading = queries.some((query) => query.loading);
  const hasError = queries.some((query) => query.error);
  const allData = queries.map(({ data }) => data);

  return { data: allData, isLoading, hasError };
};

/**
 * Shows a skeleton style loader when any of the provided queries are loading.
 * Shows an error if any of the queries returns an error
 *
 * The children will be a callback method with the provided queries data in the order they are given
 *
 * @example:
 * <SkeletonLoader queries={[getProfileQuery, anotherQuery]}>
 *   {([getProfileQueryData, anotherQueryData]) => (
 *     <p>Rendered if all queries are successful<p>
 *   )}
 * </SkeletonLoader>
 */
const SkeletonLoader = <QueryResults extends QueryResultData[]>({
  queries,
  children,
  LoaderComponent,
  defaultSkeletonProps,
  defaultErrorMessage,
}: SkeletonLoaderProps<QueryResults>) => {
  const { isLoading, hasError } = getQueryStatus(queries);

  if (hasError) {
    return <ErrorMessage error={{ message: defaultErrorMessage }} />;
  }

  if (isLoading) {
    return <>{LoaderComponent || <Skeleton {...defaultSkeletonProps} />}</>;
  }

  return <div>{children([...queries.map((q) => q.data)] as QueryResults)}</div>;
};

export { getQueryStatus, SkeletonLoader };
