import React, { FC, useMemo } from 'react';
import { WindowWidthContext } from '@minecraft.utils';
import { TypographyProps } from './types';
import { BaseTypography } from './BaseTypography';

// see Typography system here: https://www.figma.com/file/AiDAD1WpprgfbZI2XV6hU1/Casting-Networks-Design-System?node-id=209%3A1589

export type DesignSystemTextProps = Omit<TypographyProps, 'fontSize'>;

export const XxlText: FC<DesignSystemTextProps> = (props) => {
  return <BaseTypography as="p" fontWeight="semibold" lineHeight="ultraCondensed" {...props} fontSize="xxl" />;
};

export const XlText: FC<DesignSystemTextProps> = (props) => {
  return <BaseTypography as="p" fontWeight="semibold" lineHeight="ultraCondensed" {...props} fontSize="xl" />;
};

export const H1Text: FC<DesignSystemTextProps> = (props) => {
  return <BaseTypography as="h1" fontWeight="semibold" lineHeight="ultraCondensed" {...props} fontSize="h1" />;
};

export const H2Text: FC<DesignSystemTextProps> = (props) => {
  return <BaseTypography as="h2" fontWeight="medium" lineHeight="ultraCondensed" {...props} fontSize="h2" />;
};

export const H3Text: FC<DesignSystemTextProps> = (props) => {
  return <BaseTypography as="h3" fontWeight="medium" lineHeight="ultraCondensed" {...props} fontSize="h3" />;
};

export const H4Text: FC<DesignSystemTextProps> = (props) => {
  return <BaseTypography as="h4" fontWeight="medium" lineHeight="ultraCondensed" {...props} fontSize="h4" />;
};

export const BodyTextLarge: FC<DesignSystemTextProps> = (props) => {
  return <BaseTypography as="p" fontWeight="regular" {...props} fontSize="bodyLarge" />;
};

export const BodyText: FC<DesignSystemTextProps> = (props) => {
  return <BaseTypography as="p" fontWeight="regular" {...props} fontSize="bodyRegular" />;
};

export const BodyTextSmall: FC<DesignSystemTextProps> = (props) => {
  return <BaseTypography as="p" fontWeight="regular" {...props} fontSize="bodySmall" />;
};

export const DetailTextLarge: FC<DesignSystemTextProps> = (props) => {
  return <BaseTypography as="span" fontWeight="regular" {...props} fontSize="detailLarge" />;
};

export const DetailText: FC<DesignSystemTextProps> = (props) => {
  return <BaseTypography as="span" fontWeight="regular" {...props} fontSize="detailRegular" />;
};

export const DetailTextSmall: FC<DesignSystemTextProps> = (props) => {
  return <BaseTypography as="span" fontWeight="regular" {...props} fontSize="detailSmall" />;
};

type TypographyPropsWOChildren = Omit<TypographyProps, 'children'>;

export interface TextDynamicProps extends TypographyProps {
  smProps?: TypographyPropsWOChildren;
  mdProps?: TypographyPropsWOChildren;
  lgProps?: TypographyPropsWOChildren;
  xlProps?: TypographyPropsWOChildren;
}

/**
 * Breakpoints (from packages/minecraft/src/components/themes/grid/index.ts):
 * xsm: '0px'
 * sm: '576px'
 * md: '768px'
 * lg: '992px'
 * xlg: '1200px'
 * xxlg: '1440px'
 * xxxlg: '1600px'
 */
export const TextDynamic: FC<TextDynamicProps> = ({ children, smProps, mdProps, lgProps, xlProps, ...props }) => {
  const { moreSm, moreMd, moreLg, moreXlg } = WindowWidthContext.useWidthData();

  const allProps = useMemo(() => {
    if (!moreSm) {
      return props;
    }

    if (moreSm && !moreMd) {
      return { ...props, ...smProps };
    }

    if (moreMd && !moreLg) {
      return { ...props, ...smProps, ...mdProps };
    }

    if (moreLg && !moreXlg) {
      return { ...props, ...smProps, ...mdProps, ...lgProps };
    }

    return { ...props, ...smProps, ...mdProps, ...lgProps, ...xlProps };
  }, [moreSm, moreMd, moreLg, moreXlg, props, smProps, mdProps, lgProps, xlProps]);

  return (
    <BaseTypography as="p" fontWeight="regular" {...props} fontSize="bodyRegular" {...allProps}>
      {children}
    </BaseTypography>
  );
};
