import colors from '../../colors';

const filters = {
  color: colors.primary.ltGrey2,
  overlay: {
    backgroundColor: colors.primary.darkGrey2,
    borderColor: colors.primary.ltGrey1,
  },
  basic: {
    backgroundColor: colors.primary.darkGrey2,
    borderColor: colors.primary.darkGrey,
  },
  breadbox: {
    borderColor: colors.primary.grey1,
  },
  pill: {
    backgroundColor: colors.accent.dkBlue3,
    color: colors.primary.white,
  },
};

export default filters;
