const getUnauthorizedResponse = (res) => {
  if (
    res?.errors?.[0]?.data?.httpStatusCode === 401 ||
    (res?.errors?.[0]?.data?.httpStatusCode === 404 && res?.errors?.[0]?.data.name === 'accountNotFoundError') ||
    res?.errors?.[0]?.data?.errorCode === 5
  ) {
    return res?.errors;
  }

  if (res?.statusCode === 401) {
    return res;
  }

  // get refresh token and retry operation (on inactivity or connection drop)
  // workaround due to CORS fail
  if ('TypeError: Failed to fetch'.includes(res)) return res;

  return null;
};

export default getUnauthorizedResponse;
