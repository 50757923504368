import colors from '../../colors';

export const checkbox = {
  backgroundColor: colors.primary.white,
  borderColor: colors.primary.grey1,
  checkmarkColor: colors.primary.white,
  labelColor: colors.primary.ltGrey2,
  state: {
    selected: {
      backgroundColor: colors.primary.grey1,
    },
    disabled: {
      backgroundColor: colors.primary.ltGrey1,
      borderColor: colors.primary.ltGrey1,
      labelColor: colors.primary.grey1,
    },
  },
};

export const radio = {
  backgroundColor: colors.primary.white,
  borderColor: colors.primary.grey1,
  checkmarkColor: colors.primary.white,
  labelColor: colors.primary.ltGrey2,
  state: {
    selected: {
      backgroundColor: colors.primary.grey1,
    },
    disabled: {
      backgroundColor: colors.primary.ltGrey1,
      borderColor: colors.primary.ltGrey1,
      labelColor: colors.primary.grey1,
    },
  },
};
