import colors from '../../colors';

const meatballMenu = {
  backgroundColor: colors.primary.grey4,
  color: colors.primary.ltGrey2,
  borderColor: colors.primary.ltGrey1,
  state: {
    disabled: {
      color: colors.primary.ltGrey1,
    },
  },
  item: {
    state: {
      hover: {
        backgroundColor: colors.accent.dkBlue4,
      },
    },
  },
};

export default meatballMenu;
