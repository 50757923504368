import { FONT_SIZES, FONT_WEIGHTS, LINE_HEIGHTS } from './typographyConfig';

export * from './BaseTypography';

export * from './TextAtoms';

export * from './types';

export const typeography = {
  fontSize: FONT_SIZES,
  fontWeight: FONT_WEIGHTS,
  lineHeight: LINE_HEIGHTS,
};
