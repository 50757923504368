import React, { FC, createContext, ReactNode, useContext, useEffect, useState, useCallback } from 'react';
import { Alert } from 'reactstrap';
import styled, { css } from 'styled-components';
import { CoreTheme } from '@minecraft.themes';

export type AlertTypes =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'paywallError'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark';

const handleBackgroundColor = (color: AlertTypes): string => {
  const colors: { [key in AlertTypes]?: string } = {
    danger: CoreTheme.colors.notifications.red[100],
    paywallError: CoreTheme.colors.notifications.red[900],
    warning: CoreTheme.colors.notifications.orange[100],
    success: CoreTheme.colors.notifications.green[100],
    primary: CoreTheme.colors.notifications.blue[900],
  };

  return colors[color] || colors.primary;
};

const StyledAlert = styled(Alert)`
  background-color: ${({ color }) => handleBackgroundColor(color)};
  border: 0;
  border-radius: 0;
  color: ${(props) =>
    props.color === 'paywallError' ? props.theme.colors.primary.white : props.theme.colors.primary.black};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0;
  padding: 0.5625rem;
  text-align: center;

  .close {
    font-weight: normal;
    padding: 0.21875rem 0.5rem;
  }
  ${({ sticky }) =>
    sticky &&
    css`
      position: sticky;
      top: 0;
      z-index: 99999;
    `}
`;

export type ShowMessageParams = {
  color?: AlertTypes;
  delay?: number;
  message: ReactNode;
  sticky?: boolean;
  dataQaId?: string;
};

export type NotificationContextType = {
  showMessage?: (params: ShowMessageParams) => void;
};

// Context is used to store only 1 single event no state should be stored
const NotificationContext = createContext<NotificationContextType>({});

const defaultParams: ShowMessageParams = {
  color: 'success',
  message: '',
  sticky: false,
};

export const NotificationProvider: FC = ({ children }) => {
  const [messageParams, setMessageParams] = useState<ShowMessageParams>(defaultParams);
  const handleClose = useCallback(() => {
    setMessageParams(defaultParams);
  }, []);

  useEffect(() => {
    const hasMessage = Boolean(messageParams?.message);

    if (hasMessage) {
      const delay = messageParams?.delay || 3000;
      const timeoutId = window.setTimeout(handleClose, delay);

      return () => {
        clearTimeout(timeoutId);
      };
    }

    return () => undefined;
  }, [messageParams, handleClose]);

  const showMessage = useCallback((params: ShowMessageParams) => {
    setMessageParams({
      ...defaultParams,
      ...params,
    });
  }, []);

  const { color, message, dataQaId, sticky } = messageParams;

  return (
    <>
      <StyledAlert
        color={color}
        isOpen={!!message}
        toggle={handleClose}
        data-qa-id={dataQaId}
        sticky={Boolean(sticky).toString()}
        data-testid="global-notification-alert"
      >
        {message}
      </StyledAlert>
      <NotificationContext.Provider value={{ showMessage }}>{children}</NotificationContext.Provider>
    </>
  );
};

/*
 * Used to show a global notification
 */
export const useGlobalNotification = () => {
  const context = useContext(NotificationContext);

  if (!context) throw new Error('useGlobalNotification must be used within the NotificationProvider');

  return context;
};
