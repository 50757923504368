import React, { FC } from 'react';
import styled from 'styled-components';
import { DesignSystemBgColor } from '@minecraft.themes';

// Due to the Bootstrap stylings that are overriding our custom styles
// we have to nest them into the root table element to compete with the specificity
// As we move away from the variety of tables out there, we can remove this
// and start using our utility classes

interface StyledTableProps {
  $bordered: boolean;
  $headerBgColor: DesignSystemBgColor;
  $cellsInheritVerticalAlign?: boolean;
}

const StyledTable = styled.table<StyledTableProps>`
  background-color: ${({ theme }) => theme.designSystem.bgColors.card};
  ${({ theme, $bordered }) =>
    $bordered ? `border: 1px solid ${theme.designSystem.borderColors.default}` : 'border: none'};

  thead {
    background-color: ${({ theme, $headerBgColor }) => theme.designSystem.bgColors[$headerBgColor]};
    ${({ theme, $bordered }) =>
      $bordered ? `border: 1px solid ${theme.designSystem.borderColors.default}` : 'border: none'};

    th {
      ${({ theme, $bordered }) =>
        $bordered ? `border-bottom: 2px solid ${theme.designSystem.borderColors.default}` : 'border: none'};
    }
  }

  th,
  td {
    ${({ theme, $bordered }) =>
      $bordered ? `border-top: 1px solid ${theme.designSystem.borderColors.default}` : 'border: none'};
    ${({ $cellsInheritVerticalAlign }) => $cellsInheritVerticalAlign && `vertical-align: inherit`};
  }
`;

const StyledRow = styled.tr<{ verticalAlign?: 'top' | 'middle' | 'bottom' | 'inherit' }>`
  vertical-align: ${({ verticalAlign }) => verticalAlign ?? 'top'};
`;

const StyledCell = styled.td<{
  verticalAlign?: 'top' | 'middle' | 'bottom' | 'inherit';
  maxWidth?: string | number;
  removePadding?: boolean;
}>`
  vertical-align: ${({ verticalAlign }) => verticalAlign ?? 'inherit'};
  max-width: ${({ maxWidth }) => (typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth)};
  && {
    padding: ${({ removePadding }) => (removePadding ? '0' : '0.5rem')};
  }
`;

export type SimpleTableProps = {
  /** screen reader caption for the table */
  caption: string;
  /** whether alternating rows have an offset color background */
  striped?: boolean;
  /** custom classes to apply to the `table` element */
  className?: string;
  /** whether to display the table caption or keep it screen-reader only */
  showCaption?: boolean;
  /** whether to include a border around the table, rows and cells */
  bordered?: boolean;
  /** background color of the table header row (defaults to 'tableHeader') */
  headerBgColor?: DesignSystemBgColor;
  /** whether to inherit the vertical alignment of the table cells from the table row */
  cellsInheritVerticalAlign?: boolean;
  /** test id for the table */
  'data-testid'?: string;
};

export type TableHeadingProps = {
  scope?: 'col' | 'row';
  width?: number;
  'aria-label'?: string;
};

export type TableCellProps = {
  className?: string;
  maxWidth?: number | string;
  verticalAlign?: 'top' | 'middle' | 'bottom' | 'inherit';
  removePadding?: boolean;
};

/**
 * An ultra simple table, please be aware of accessibility with tables:
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/table#accessibility_concerns
 *
 * The designs for this table can be found here:
 * https://www.figma.com/file/AiDAD1WpprgfbZI2XV6hU1/Casting-Networks-Design-System?node-id=1679%3A74149&t=7vmhiQ4nkJSmiEtm-0
 * and are intended for the "simple" versions of those designs (see "Talent - Edit Skills" section)
 *
 * Be sure to use Header and Heading to make the top of the table, this will
 * automatically make the column accessible as well
 *
 * The "caption" should describe the tables intention so screen reader users
 * can determine if they should skip the table (it is time-consuming and complicated
 * to use a table with a screen reader)
 *
 * Lastly be aware that tables do not work well on mobile sizes.  If you need to use this
 * table on mobile sizes, I would suggest switching between table and <ListGroup />
 *
 * @example
 * <SimpleTable caption="A simple table" striped>
 *   <Header>
 *     <Row>
 *       <Heading>Heading 1</Heading>
 *     </Row>
 *   </Header>
 *   <Body>
 *     <Row>
 *       <Cell>Cell 1</Cell>
 *      </Row>
 *   </Body>
 * </SimpleTable>
 */
export const SimpleTable: FC<SimpleTableProps> = ({
  caption,
  showCaption,
  striped,
  className,
  bordered = true,
  headerBgColor = 'tableHeader',
  children,
  cellsInheritVerticalAlign = false,
  'data-testid': dataTestId,
}) => {
  return (
    <StyledTable
      data-testid={dataTestId}
      $bordered={bordered}
      $headerBgColor={headerBgColor}
      $cellsInheritVerticalAlign={cellsInheritVerticalAlign}
      className={`table ${striped ? 'table-striped' : ''} ${className}`}
    >
      <caption className={`${showCaption ? '' : 'cn_sr_only'}`}>{caption}</caption>
      {children}
    </StyledTable>
  );
};

export const TableHead: FC = ({ children }) => {
  return <thead>{children}</thead>;
};

/**
 * The scope property is used to help screen readers determine the first row/column
 * For headings across the top of a table (typical use) use "col", this will help
 * screen readers describe the cell the user is visitiing.
 *
 * If you need to have a vertical heading column use scope="row"
 * @constructor
 */
export const TableHeader: FC<TableHeadingProps> = (
  { scope, children, width, 'aria-label': ariaLabel } = { scope: 'col' }
) => {
  return (
    <th scope={scope} style={width != null ? { width: `${width}%` } : null} aria-label={ariaLabel}>
      {children}
    </th>
  );
};

export interface TableRowProps {
  'data-rowid'?: string;
  /** test id for the list card */
  'data-testid'?: string;
  /** qa id for the list card */
  'data-qa-id'?: string;
  verticalAlign?: 'top' | 'middle' | 'bottom' | 'inherit';
}

export const TableRow: FC<TableRowProps> = ({
  children,
  'data-rowid': rowId,
  'data-testid': testId,
  'data-qa-id': qaId,
  verticalAlign,
}) => {
  return (
    <StyledRow data-testid={testId} data-qa-id={qaId} data-rowid={rowId} verticalAlign={verticalAlign}>
      {children}
    </StyledRow>
  );
};

export const TableBody: FC = ({ children }) => {
  return <tbody>{children}</tbody>;
};

export const TableCell: FC<TableCellProps> = ({
  children,
  className,
  maxWidth,
  verticalAlign = 'top',
  removePadding = false,
}) => {
  return (
    <StyledCell className={className} maxWidth={maxWidth} removePadding={removePadding} verticalAlign={verticalAlign}>
      {children}
    </StyledCell>
  );
};
