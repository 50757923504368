import colors from '../../colors';

const globalHeader = {
  colors: {
    backgroundColor: colors.primary.dkBlue1,
    brand: colors.primary.white,
    link: colors.primary.white,
    mobileLink: colors.primary.darkGrey,
    mobileLinkBackground: colors.primary.white,
    navbarToggler: colors.primary.white,
    border: colors.primary.dkBlue1,
    braze: colors.primary.primary,
    subHeader: colors.accent.ltBlue2,
  },
};

export default globalHeader;
