import colors from '../../colors';

const filters = {
  color: colors.primary.darkGrey,
  overlay: {
    backgroundColor: colors.primary.white,
    borderColor: colors.primary.ltGrey1,
  },
  basic: {
    backgroundColor: colors.accent.ltBlue2,
    borderColor: colors.primary.ltGrey1,
  },
  breadbox: {
    borderColor: colors.primary.ltGrey1,
  },
  pill: {
    backgroundColor: colors.primary.grey1,
    color: colors.primary.white,
  },
};

export default filters;
