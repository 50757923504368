import React from 'react';

const CastItLogo = () => {
  return (
    <svg
      width="259"
      height="33"
      viewBox="0 0 259 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-100 h-100"
    >
      <title>CastIt Logo</title>
      <path
        d="M42.1104 16.3403C42.1104 11.2447 45.8338 7.76465 50.4935 7.76465C53.4765 7.76465 55.4987 9.1812 56.8051 11.0456L55.0791 12.0161C54.1429 10.6244 52.4416 9.6042 50.4935 9.6042C46.9675 9.6042 44.2313 12.3875 44.2313 16.3403C44.2313 20.2683 46.9675 23.0765 50.4935 23.0765C52.4416 23.0765 54.1429 22.0812 55.0791 20.6646L56.8297 21.6333C55.4493 23.5226 53.4765 24.9143 50.4935 24.9143C45.8338 24.9161 42.1104 21.436 42.1104 16.3403Z"
        fill="white"
      />
      <path
        d="M73.6471 24.6174L72.1926 20.9383H63.8589L62.4044 24.6174H60.1354L66.7431 8.03833H69.2819L75.9143 24.6174H73.6471ZM68.0249 9.87789L64.4495 19.1005H71.5756L68.0249 9.87789Z"
        fill="white"
      />
      <path
        d="M80.403 20.7144C81.4872 21.9318 83.2626 23.0764 85.5809 23.0764C88.5146 23.0764 89.4525 21.4857 89.4525 20.2185C89.4525 18.2296 87.455 17.6822 85.2865 17.0868C82.649 16.3901 79.7136 15.6454 79.7136 12.3893C79.7136 9.65392 82.1307 7.79126 85.3605 7.79126C87.8764 7.79126 89.8227 8.63728 91.2049 10.1036L89.9726 11.6197C88.7402 10.2529 87.0143 9.63082 85.2142 9.63082C83.2414 9.63082 81.8363 10.699 81.8363 12.2666C81.8363 13.932 83.7351 14.4297 85.8313 15.0002C88.5181 15.72 91.5769 16.566 91.5769 20.0461C91.5769 22.4579 89.9497 24.9178 85.5122 24.9178C82.6526 24.9178 80.5563 23.8247 79.2006 22.2837L80.403 20.7144Z"
        fill="white"
      />
      <path d="M101.016 24.6174V9.87789H95.8134V8.03833H108.29V9.87789H103.063V24.6174H101.016Z" fill="white" />
      <path d="M122.296 24.6174V8.03833H124.343V24.6174H122.296Z" fill="white" />
      <path d="M134.723 24.6174V9.87789H129.52V8.03833H141.997V9.87789H136.77V24.6174H134.723Z" fill="white" />
      <path d="M33.8646 0H0V33H33.8646V0Z" fill="#592BE7" />
      <path d="M14.9998 0L8.10364 6.72015L12.5686 11.0729L23.9315 0H14.9998Z" fill="#1E0487" />
      <path d="M33.8645 10.0581L22.6967 20.9426L27.1616 25.2935L33.8645 18.7618V10.0581Z" fill="#1E0487" />
      <path
        d="M6.12463 27.2201C11.3793 32.3406 19.9298 32.3406 25.1845 27.2201L31.6466 20.923H22.7477L20.736 22.8852C17.9344 25.6152 13.3783 25.6152 10.5768 22.8852C7.77526 20.1552 7.77526 15.7153 10.5768 12.9853L12.594 11.0196V2.36746L12.5813 2.36035L6.12645 8.65038C0.869938 13.7691 0.869938 22.1014 6.12463 27.2201Z"
        fill="white"
      />
      <path
        d="M163.35 24.8C167.85 24.8 169.5 22.325 169.5 19.9C169.5 13.675 159.625 15.825 159.625 12.075C159.625 10.5 161.05 9.425 163.05 9.425C164.875 9.425 166.625 10.05 167.875 11.425L169.125 9.9C167.725 8.425 165.75 7.575 163.2 7.575C159.925 7.575 157.475 9.45 157.475 12.2C157.475 18.15 167.35 15.775 167.35 20.075C167.35 21.35 166.4 22.95 163.425 22.95C161.075 22.95 159.275 21.8 158.175 20.575L156.95 22.15C158.325 23.7 160.45 24.8 163.35 24.8ZM181.402 24.5V9.675H186.702V7.825H174.052V9.675H179.327V24.5H181.402ZM199.01 24.8C203.535 24.8 205.81 22.15 205.81 18V7.825H203.735V17.975C203.735 21.025 202.085 22.95 199.01 22.95C195.935 22.95 194.285 21.025 194.285 17.975V7.825H192.21V18.025C192.21 22.125 194.485 24.8 199.01 24.8ZM218.165 24.5C223.34 24.5 226.715 20.925 226.715 16.175C226.715 11.45 223.34 7.825 218.165 7.825H212.465V24.5H218.165ZM218.165 22.65H214.54V9.675H218.165C222.29 9.675 224.565 12.575 224.565 16.175C224.565 19.75 222.215 22.65 218.165 22.65ZM234.795 24.5V7.825H232.72V24.5H234.795ZM249.051 24.8C253.976 24.8 257.326 21.1 257.326 16.175C257.326 11.25 253.976 7.55 249.051 7.55C244.101 7.55 240.776 11.25 240.776 16.175C240.776 21.1 244.101 24.8 249.051 24.8ZM249.051 22.95C245.301 22.95 242.926 20.05 242.926 16.175C242.926 12.275 245.301 9.4 249.051 9.4C252.776 9.4 255.176 12.275 255.176 16.175C255.176 20.05 252.776 22.95 249.051 22.95Z"
        fill="white"
      />
    </svg>
  );
};

export const CastItLogoDark = () => {
  return (
    <svg
      width="259"
      height="33"
      viewBox="0 0 165 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-100 h-100"
    >
      <title>CastIt</title>
      <path d="M20.6418 0.40332H0V20.7H20.6418V0.40332Z" fill="#611EEF" />
      <path d="M9.14272 0.40332L4.93921 4.53656L7.66076 7.21371L14.5869 0.40332H9.14272Z" fill="#22008C" />
      <path d="M20.6414 6.58984L13.8342 13.2844L16.5558 15.9604L20.6414 11.9431V6.58984Z" fill="#22008C" />
      <path
        d="M3.73336 17.1455C6.9363 20.2949 12.1482 20.2949 15.3511 17.1455L19.29 13.2724H13.8658L12.6395 14.4793C10.9319 16.1584 8.15477 16.1584 6.44713 14.4793C4.73949 12.8002 4.73949 10.0695 6.44713 8.39039L7.67672 7.18135V1.85984L7.66894 1.85547L3.73447 5.72417C0.530426 8.87247 0.530426 13.9972 3.73336 17.1455Z"
        fill="white"
      />
      <path
        d="M25.4175 10.7776C25.4175 7.52586 27.8133 5.30371 30.8116 5.30371C32.7307 5.30371 34.0314 6.20746 34.8731 7.39827L33.762 8.01672C33.1595 7.12892 32.0644 6.47681 30.8116 6.47681C28.5434 6.47681 26.782 8.25417 26.782 10.7758C26.782 13.2832 28.5434 15.0748 30.8116 15.0748C32.0644 15.0748 33.1595 14.4404 33.762 13.5366L34.8891 14.1551C34.0013 15.3601 32.7307 16.2496 30.8116 16.2496C27.8133 16.2496 25.4175 14.0293 25.4175 10.7776Z"
        fill="black"
      />
      <path
        d="M45.709 16.0621L44.7734 13.7142H39.4112L38.4755 16.0621H37.0154L41.2665 5.47949H42.9003L47.1674 16.0621H45.709ZM42.0923 6.65436L39.7922 12.5411H44.3765L42.0923 6.65436Z"
        fill="black"
      />
      <path
        d="M50.056 13.5692C50.7542 14.3471 51.8972 15.0772 53.3874 15.0772C55.2747 15.0772 55.8789 14.0618 55.8789 13.252C55.8789 11.9832 54.5942 11.6341 53.1978 11.2531C51.5002 10.8083 49.613 10.3334 49.613 8.25482C49.613 6.50935 51.1671 5.32031 53.2457 5.32031C54.8636 5.32031 56.1164 5.85901 57.006 6.79642L56.2121 7.76396C55.4182 6.89211 54.3089 6.49517 53.15 6.49517C51.8812 6.49517 50.9757 7.17741 50.9757 8.17685C50.9757 9.24007 52.1966 9.55727 53.5452 9.92231C55.2747 10.383 57.2416 10.9217 57.2416 13.1421C57.2416 14.6803 56.1944 16.2521 53.3396 16.2521C51.4984 16.2521 50.1499 15.5539 49.2781 14.5704L50.056 13.5692Z"
        fill="black"
      />
      <path d="M63.3196 16.0621V6.65436H59.9722V5.47949H67.9995V6.65436H64.6362V16.0621H63.3196Z" fill="black" />
      <path d="M77.0122 16.0621V5.47949H78.3288V16.0621H77.0122Z" fill="black" />
      <path d="M85.0075 16.0621V6.65436H81.6602V5.47949H89.6875V6.65436H86.3242V16.0621H85.0075Z" fill="black" />
      <path
        d="M101.697 15.2431C104.567 15.2431 105.62 13.6642 105.62 12.1173C105.62 8.1461 99.3203 9.51766 99.3203 7.1254C99.3203 6.12065 100.229 5.43487 101.505 5.43487C102.669 5.43487 103.786 5.83358 104.583 6.71074L105.381 5.73789C104.488 4.79693 103.228 4.25469 101.601 4.25469C99.5117 4.25469 97.9488 5.45082 97.9488 7.20514C97.9488 11.0009 104.248 9.48576 104.248 12.2289C104.248 13.0423 103.642 14.063 101.744 14.063C100.245 14.063 99.0971 13.3293 98.3953 12.5479L97.6139 13.5526C98.491 14.5414 99.8466 15.2431 101.697 15.2431ZM113.213 15.0518V5.59435H116.594V4.41417H108.524V5.59435H111.889V15.0518H113.213ZM124.445 15.2431C127.332 15.2431 128.783 13.5526 128.783 10.9052V4.41417H127.46V10.8892C127.46 12.8349 126.407 14.063 124.445 14.063C122.484 14.063 121.431 12.8349 121.431 10.8892V4.41417H120.107V10.9211C120.107 13.5367 121.559 15.2431 124.445 15.2431ZM136.665 15.0518C139.966 15.0518 142.119 12.7711 142.119 9.74094C142.119 6.72669 139.966 4.41417 136.665 4.41417H133.029V15.0518H136.665ZM136.665 13.8716H134.352V5.59435H136.665C139.296 5.59435 140.748 7.44437 140.748 9.74094C140.748 12.0216 139.249 13.8716 136.665 13.8716ZM147.274 15.0518V4.41417H145.95V15.0518H147.274ZM156.368 15.2431C159.51 15.2431 161.647 12.8828 161.647 9.74094C161.647 6.5991 159.51 4.23874 156.368 4.23874C153.211 4.23874 151.089 6.5991 151.089 9.74094C151.089 12.8828 153.211 15.2431 156.368 15.2431ZM156.368 14.063C153.976 14.063 152.461 12.2129 152.461 9.74094C152.461 7.25299 153.976 5.41892 156.368 5.41892C158.745 5.41892 160.276 7.25299 160.276 9.74094C160.276 12.2129 158.745 14.063 156.368 14.063Z"
        fill="#161616"
      />
    </svg>
  );
};

export default CastItLogo;
