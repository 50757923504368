import React, { FC, PropsWithChildren } from 'react';
import Skeleton, { SkeletonProps } from 'react-loading-skeleton';
import ErrorMessage from '../components/ErrorMessage';
import { getQueryStatus } from '../components/SkeletonLoader';

export type LoaderProps = {
  loading?: boolean;
  error?: string | Error;
  skeletonProps?: SkeletonProps;
  LoaderComponent?: FC | React.ReactNode;
  'data-testid'?: string;
};

/**
 * A simple loader and error component for the common case of detecting and displaying
 * the loading skeleton and error block for children components.
 *
 * This can be combined with the getQueryStatus  to get the loading/error states of many
 * queries at the same time
 *
 * @example
 * const { isLoading, hasError, data } = useQueries([QUERY_1, QUERY_2]);
 *
 * return (
 *  <Loader loading={isLoading} error={hasError}>
 *    <p>Rendered if all queries are successful<p>
 *  </Loader>
 */
export const Loader: FC<PropsWithChildren<LoaderProps>> = ({
  loading,
  error,
  children,
  skeletonProps,
  LoaderComponent,
  'data-testid': dataTestId,
}) => {
  if (error) return <ErrorMessage error={{ message: typeof error === 'string' ? error : error.message }} />;

  if (loading) return <>{LoaderComponent ?? <Skeleton data-testid={dataTestId} {...skeletonProps} />}</>;

  return <>{children}</>;
};

export { getQueryStatus };
