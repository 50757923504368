import React, { FC } from 'react';
import { useTranslation } from '@blocs.i18n';
import Modal, { ModalProps } from './Modal';

type DiscardChangesModalProps = Omit<ModalProps, 'title' | 'cancelLabel' | 'submitLabel' | 'children'>;

const DiscardChangesModal: FC<DiscardChangesModalProps> = ({ children, ...props }) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('common:discardChangesModal.title')}
      cancelLabel={t('common:button.cancel')}
      submitLabel={t('common:button.yesDiscard')}
      {...props}
    >
      {children || t('common:discardChangesModal.bodyText')}
    </Modal>
  );
};

export default DiscardChangesModal;
