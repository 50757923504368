import colors from '../../colors';
import { Color } from '../../TSTypes/button';

const WarningColor: Color = {
  outlined: {
    true: {
      state: {
        default: {
          color: colors.buttons.warning.default,
          backgroundColor: colors.primary.white,
          border: `1px solid ${colors.buttons.warning.default}`,
        },
        hover: {
          color: colors.primary.white,
          backgroundColor: colors.buttons.warning.hover,
          border: `1px solid ${colors.buttons.warning.hover}`,
        },
        focus: {
          color: colors.primary.white,
          backgroundColor: colors.buttons.warning.hover,
          border: `1px solid ${colors.buttons.warning.hover}`,
        },
        active: {
          color: colors.primary.white,
          backgroundColor: colors.buttons.warning.active,
          border: `1px solid ${colors.buttons.warning.active}`,
        },
        disabled: {
          color: colors.primary.grey1,
          backgroundColor: colors.buttons.warning.disabled,
          border: `1px solid ${colors.buttons.warning.disabled}`,
        },
      },
    },
    false: {
      state: {
        default: {
          color: colors.primary.white,
          backgroundColor: colors.buttons.warning.default,
          border: 'none',
        },
        hover: {
          color: colors.primary.white,
          backgroundColor: colors.buttons.warning.hover,
          border: 'none',
        },
        focus: {
          color: colors.primary.white,
          backgroundColor: colors.buttons.warning.hover,
          border: 'none',
        },
        active: {
          color: colors.primary.white,
          backgroundColor: colors.buttons.warning.active,
          border: 'none',
        },
        disabled: {
          color: colors.primary.grey2,
          backgroundColor: colors.buttons.warning.disabled,
          border: 'none',
        },
      },
    },
  },
};

export default WarningColor;
