import React, { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { Theme, THEME_NAMES } from '@minecraft.themes';
import CastItLogo from './CastItLogo';

const StyledLogo = styled.img`
  height: 2.0625rem;
  margin-right: 0.5rem;
`;

type Props = {
  isPC: boolean;
  logoImage: string;
  logoTitle: string;
};

const formatLogoTitle = (str: string): JSX.Element[] => {
  return str.split(' ').map((word, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <span key={index} className="d-block">
      {word}
    </span>
  ));
};

export const HeaderLogo: FC<Props> = ({ isPC, logoImage, logoTitle }): JSX.Element => {
  const theme = useTheme() as Theme;

  if (theme?.name === THEME_NAMES.studio) {
    return <CastItLogo />;
  }

  return (
    <>
      <StyledLogo src={logoImage} alt={logoTitle} />
      {!isPC && <span className="d-block">{formatLogoTitle(logoTitle)}</span>}
    </>
  );
};
