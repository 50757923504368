import { useParams as useParamsRouter, useLocation } from 'react-router-dom';

type UseRouteParamOptions = {
  parse?: boolean;
}

type Params = {
  [key: string]: number | string | boolean
}

const shouldParseValues = (parse = true) => parse;

const parseUrlParam = (value: string): number | boolean | string => {
  try {
    return JSON.parse(value);
  } catch(e) {
    return value;
  }
};

export const useParams = <T extends Params>(options: UseRouteParamOptions): T => {
  const params = useParamsRouter();

  if (shouldParseValues(options.parse)) {
    Object.keys(params).forEach((key) => {
      params[key] = parseUrlParam(params[key])
    })
  }

  return params as T;
};

export const useQueryParams = <T = Params>(options: UseRouteParamOptions = {}): T => {
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const queryParams = {};
  const parse = shouldParseValues(options.parse);


  urlSearchParams.forEach((value, key) => {
    queryParams[key] = parse ? parseUrlParam(value) : value;
  });

  return queryParams as T;
};

export const useRouteParams = <T extends Params>(options: UseRouteParamOptions = {}): T => {
  const params = useParams<Partial<T>>(options);
  const queryParams = useQueryParams<Partial<T>>(options);

  return {
    ...params,
    ...queryParams
  } as T;
};
