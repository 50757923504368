import { designSystem } from '../designSystem';
import { Color } from '../../TSTypes/button';

const ModernLinkColorState = {
  default: {
    color: designSystem.textColors.link,
    backgroundColor: 'transparent',
    border: 'none',
    textDecoration: 'none',
  },
  hover: {
    color: designSystem.textColors.link,
    backgroundColor: 'transparent',
    border: 'none',
    textDecoration: 'none',
  },
  focus: {
    color: designSystem.textColors.link,
    backgroundColor: 'transparent',
    border: 'none',
    textDecoration: 'none',
  },
  active: {
    color: designSystem.textColors.link,
    backgroundColor: 'transparent',
    border: 'none',
    textDecoration: 'none',
  },
  disabled: {
    color: designSystem.textColors.disabled,
    backgroundColor: 'transparent',
    border: 'none',
    textDecoration: 'none',
  },
};

const ModernLinkSecondaryColorState = {
  default: {
    color: designSystem.textColors.inverted,
    backgroundColor: 'transparent',
    border: 'none',
    textDecoration: 'underline',
  },
  hover: {
    color: designSystem.textColors.inverted,
    backgroundColor: 'transparent',
    border: 'none',
    textDecoration: 'underline',
  },
  focus: {
    color: designSystem.textColors.inverted,
    backgroundColor: 'transparent',
    border: 'none',
    textDecoration: 'underline',
  },
  active: {
    color: designSystem.textColors.inverted,
    backgroundColor: 'transparent',
    border: 'none',
    textDecoration: 'underline',
  },
  disabled: {
    color: designSystem.textColors.inverted,
    backgroundColor: 'transparent',
    border: 'none',
    textDecoration: 'underline',
  },
};

const ModernLinkColor: Color = {
  outlined: {
    true: {
      state: ModernLinkSecondaryColorState,
    },
    false: {
      state: ModernLinkColorState,
    },
  },
};

export default ModernLinkColor;
