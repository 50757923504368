import React from 'react';
import { Helmet } from 'react-helmet';
import { appConfig } from '@minecraft.app-config';

interface MetaDataProps {
  title?: string;
}

const MetaData = ({ title = 'Casting Networks' }: MetaDataProps) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${appConfig.cdnRoot}/favicon_package_v1.0.2/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${appConfig.cdnRoot}/favicon_package_v1.0.2/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${appConfig.cdnRoot}/favicon_package_v1.0.2/favicon-16x16.png`}
      />
      <link rel="manifest" href={`${appConfig.cdnRoot}/favicon_package_v1.0.2/site.webmanifest`} />
      <link
        rel="mask-icon"
        href={`${appConfig.cdnRoot}/favicon_package_v1.0.2/safari-pinned-tab.svg`}
        color="#5bbad5"
      />
      <link rel="shortcut icon" href={`${appConfig.cdnRoot}/favicon_package_v1.0.2/favicon.ico`} />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="msapplication-config" content={`${appConfig.cdnRoot}/favicon_package_v1.0.2/browserconfig.xml`} />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
  );
};

export default MetaData;
