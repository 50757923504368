import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from '@blocs.i18n';

export const TEST_ID = 'graphql-error';
const graphqlErrorString = 'GraphQL error: ';

type Props = {
  error?: {
    message?: string;
    networkError?: any;
  };
  testId?: string;
};

const ErrorStyles = styled.div`
  padding: 2rem;
  background: ${({ theme }) => theme.designSystem.bgColors.card}
  margin: 2rem 0;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-left: 5px solid ${({ theme }) => theme.designSystem.textColors.error};
  p {
    margin: 0;
    font-weight: 100;
  }
  strong {
    margin-right: 1rem;
  }
`;

const ErrorMessage: React.FC<Props> = ({ error = {}, children, testId }) => {
  const { t } = useTranslation();
  const wrapperTestId = testId || 'graphql-error-wrapper';

  if (!error?.message)
    return (
      <ErrorStyles data-testid={wrapperTestId}>
        <p data-testid={TEST_ID}>{t('common:globalError.generic')}</p>
        {children}
      </ErrorStyles>
    );

  if (error?.networkError?.result?.errors?.length) {
    return error.networkError.result.errors.map((subError, i) => (
      <div data-testid={wrapperTestId} key={subError.message}>
        <ErrorStyles key={subError.message}>
          <p data-testid={`${TEST_ID}-${i}`}>{subError.message.replace(graphqlErrorString, '')}</p>
        </ErrorStyles>
      </div>
    ));
  }

  return (
    <ErrorStyles data-testid={wrapperTestId}>
      <p data-testid={TEST_ID}>{error.message.replace(graphqlErrorString, '')}</p>
      {children}
    </ErrorStyles>
  );
};

export default ErrorMessage;
