import { ApolloClient, NormalizedCacheObject, InMemoryCache, from } from '@apollo/client';
import { Alert } from '@blocs.common';
import { createBasicHttpLink, buildAuthErrorLink, authLink, userCountryLink, PRODUCTION } from '@blocs.auth';
import { getEnvironment } from '@minecraft.environment';

const initializeState = (inMemCache): void => {
  Alert.initializeAlertState(inMemCache);
};

function createApolloClient(): ApolloClient<NormalizedCacheObject> {
  const client = new ApolloClient({
    connectToDevTools: getEnvironment()?.ENV !== PRODUCTION,
    link: from([buildAuthErrorLink({ redirectTo: '/login' }), authLink, userCountryLink, createBasicHttpLink()]),
    cache: new InMemoryCache(),
    resolvers: Alert.globalAlertResolvers,
    name: 'web',
    version: 'login',
  });

  initializeState(client);

  return client;
}

export default createApolloClient;
