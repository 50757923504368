import styled, { keyframes } from 'styled-components';
import BaseAnimation from './BaseAnimation';

const FadeOutAnimation = keyframes`  
  0% {
  opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    overflow: hidden;
    visibility: hidden;
    display: none;
    height: 0;
  }
`;

const FadeOut = styled(BaseAnimation)`
  animation-name: ${FadeOutAnimation};
`;

export default FadeOut;
