// see Typography system here: https://www.figma.com/file/AiDAD1WpprgfbZI2XV6hU1/Casting-Networks-Design-System?node-id=209%3A1589

export const FONT_SIZES = {
  xxl: '2.5rem', // ~40px
  xl: '2rem', // ~32px
  h1: '1.875rem', // ~30px
  h2: '1.5rem', // ~24px
  h3: '1.25rem', // ~20px
  h4: '1.125rem', // ~18px
  bodyLarge: '1.125rem', // ~18px
  bodyRegular: '1rem', // ~16px
  bodySmall: '.875rem', // ~14px
  detailLarge: '.75rem', // ~12px
  detailRegular: '.625rem', // ~10px
  detailSmall: '.5rem', // ~ 8px
} as const;

export const LINE_HEIGHTS = {
  expanded: 1.5,
  default: 1.375,
  condensed: 1.125,
  ultraCondensed: 1,
  tight: 0.5,
} as const;

export const FONT_WEIGHTS = {
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
} as const;
