/* eslint-disable camelcase */
export interface FeatureExperimentUser {
  key: string;
  secondary?: string;
  email?: string;
  name?: string;
  anonymous?: boolean;
  isQa: boolean;
  [key: string]: string | boolean | number | Array<string | boolean | number>;
}

export const TRACKING_EVENT = {
  PURCHASE_SUCCESS: 'successful_purchase',
  TALENT_SIGNUP_SUCCESS: 'successful_talent_signup',
  CBB_SUBMISSION_SUCCESS: 'successful_billboard_submission',
};

export type TrackingEventName = keyof typeof TRACKING_EVENT;
