import { useMemo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { QUERY_PARAMS } from '../constants';

const codeKey: string = QUERY_PARAMS.couponCode;

const useCouponCodeCatcher = () => {
  const history = useHistory();
  const [complete, setComplete] = useState<boolean>(false);
  const queryParams = useMemo(() => {
    return new URLSearchParams(history.location.search);
  }, [history.location.search]);
  const couponCode = queryParams.get(codeKey);
  const localStorageCode = localStorage.getItem(codeKey);

  // if there is a coupon code on the query string, persist into local storage
  // so that it will be present the next time the user completes
  // an validated authentication
  useEffect(() => {
    if (!complete && couponCode && localStorageCode !== couponCode) {
      // set coupon code into localStorage
      localStorage.setItem(codeKey, couponCode);
      // remove coupon code from the query params
      queryParams.delete(codeKey);
      history.replace({
        search: queryParams.toString(),
      });
    }

    if (!complete) {
      setComplete(true);
    }
  }, [couponCode, queryParams, history, localStorageCode, complete]);

  return { complete };
};

export default useCouponCodeCatcher;
