import { useCallback, useState } from 'react';

interface ModalState<T = Record<string, any>> {
  isOpen: boolean;
  modalContext: T;
}

type CtxUpdateFn<T> = (oldCtx: T) => T;

export interface UseToggleResult<T = Record<string, any>> extends ModalState<T> {
  toggle(context?: T): void;
  open(context?: T): void;
  close(context?: T): void;
  setContext(context: T | CtxUpdateFn<T>): void;
}

export const useToggle = <T = Record<string, any>>(defaultState = false): UseToggleResult<T> => {
  const [{ isOpen, modalContext }, setModalState] = useState<ModalState<T>>({
    isOpen: defaultState,
    modalContext: null,
  });

  const toggle = useCallback(
    (context = {}) => {
      setModalState((prevState) => ({ isOpen: !prevState.isOpen, modalContext: context }));
    },
    [setModalState]
  );
  const open = useCallback(
    (context = {}) => {
      setModalState({ isOpen: true, modalContext: context });
    },
    [setModalState]
  );
  const close = useCallback(
    (context = {}) => {
      setModalState({ isOpen: false, modalContext: context });
    },
    [setModalState]
  );
  const setContext = useCallback(
    (context = {}) => {
      setModalState((prevState) => {
        let newCtx = context;

        if (typeof context === 'function') {
          newCtx = context(prevState.modalContext);
        }

        return { isOpen: prevState.isOpen, modalContext: newCtx };
      });
    },
    [setModalState]
  );

  return { isOpen, modalContext, toggle, open, close, setContext };
};
