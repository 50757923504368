import styled from 'styled-components';
import Icon from '@minecraft.icon';

export const StyledLinkWrapper = styled.div`
  line-height: 1.5;
`;

export const StyledLockedMediaBanner = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0.2rem 1.8rem;
  text-align: center;
  font-size: 0.7rem;
  color: ${(props): string => props.theme.colors.primary.black};
  background: ${(props): string => props.theme.colors.primary.ltGrey1};
`;

export const StyledLockIcon = styled(Icon)`
  width: 1rem;
  height: 1rem;
`;

export const StyledImageWrapper = styled.div`
  position: relative;
  display: inline-block;
`;
