import { createHttpLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { getEnvironment } from '@minecraft.environment';

// this is the terminating link used by
// - casting
// - reps
// - talent
// it is able to support regular GraphQL POST requests
// as well a multipart File uploads
// see: https://github.com/jaydenseric/apollo-upload-client
export const createUploadHttpLink = () => {
  const env = getEnvironment();

  return createUploadLink({ uri: env.API_URL, credentials: 'same-origin' });
};

// this is the terminating link used by
// - admin
// - ula
// it is the basic terminating link that supports GraphQL requests
export const createBasicHttpLink = () => {
  const env = getEnvironment();

  return createHttpLink({ uri: env.API_URL });
};
