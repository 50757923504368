import React, { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import { EnterpriseTheme, StudioTheme } from '@minecraft.themes';
import { usePermissionsContext } from './PermissionsProvider';

export const UlaThemeProvider: FC = ({ children }) => {
  const { permissions } = usePermissionsContext();

  return <ThemeProvider theme={permissions.useStudioTheme ? StudioTheme : EnterpriseTheme}>{children}</ThemeProvider>;
};
