import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from '@blocs.i18n';
import { media } from '@minecraft.themes';
import { useRemoveSplashLoader } from '@minecraft.utils';
import { getEnvironment } from '@minecraft.environment';

const env = getEnvironment();

const StyledContainer = styled.div`
  height: 100vh;
  position: relative;
  overflow: hidden;
`;

const StyledNav = styled.nav`
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const StyledLink = styled.a`
  display: flex;
  height: 2.0625rem;
  justify-content: center;
  color: ${(props) => props.theme.colors.primary.darkGrey};
  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary.darkGrey};
  }
`;

const StyledLogoText = styled.p`
  display: none;
  margin: 0 0 0 0.5rem;
  text-align: left;
  text-transform: uppercase;
  width: 5.5rem;
  line-height: 1;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  ${media.sm`
    display: block;
  `}
`;

const StyledInnerContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTextContainer = styled.div`
  font-family: ${(props) => props.theme.typography.body.font};
  transform: translateY(-40%);
  max-width: 30rem;
  padding: 1rem;
  ${media.sm`
    max-width: 40rem;
    `}
  ${media.md`
    padding: 0;
    max-width: 45rem;
  `}
`;

const StyledHeading = styled.h1`
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 1.5rem;
  font-size: 1.6rem;
  ${media.sm`
    font-size: 2.5rem;
  `}
  ${media.md`
    font-size: 3.2rem;
  `}
`;

const StyledText = styled.p`
  font-size: 1rem;
  margin: 0;
  ${media.sm`
    font-size: 1.3rem;
  `}
  ${media.md`
    font-size: 1.7rem;
  `}
`;

const StyledBackgroundLogo = styled.img`
  position: absolute;
  width: 32.5rem;
  bottom: -11rem;
  right: -17.5rem;
  ${media.sm`
    width: 61.25rem;
    bottom: -19.375rem;
    right: -32.8rem;
  `}
`;

type Props = {
  scheduled?: boolean;
};

const MaintenanceScreen = ({ scheduled }: Props): JSX.Element => {
  const { t } = useTranslation();

  useRemoveSplashLoader();
  const history = useHistory();
  const mode = new URLSearchParams(history.location.search).get('mode');
  const isScheduledMode = scheduled || (mode && mode === 'scheduled');

  return (
    <StyledContainer>
      <StyledNav>
        <StyledLink href="https://castingnetworks.com/">
          <img src={`${env.CDN_URL}/images/logos/cn-icon-only.svg`} alt={t('common:logo.altText')} />
          <StyledLogoText>{t('common:logo.text')}</StyledLogoText>
        </StyledLink>
      </StyledNav>
      <StyledInnerContainer>
        {isScheduledMode ? (
          <StyledTextContainer>
            <StyledHeading>{t('common:maintenance.scheduled.title')}</StyledHeading>
            <StyledText>{t('common:maintenance.scheduled.description')}</StyledText>
          </StyledTextContainer>
        ) : (
          <StyledTextContainer>
            <StyledHeading>{t('common:maintenance.unscheduled.title')}</StyledHeading>
            <StyledText>{t('common:maintenance.unscheduled.description')}</StyledText>
          </StyledTextContainer>
        )}
      </StyledInnerContainer>
      <StyledBackgroundLogo src={`${env.CDN_URL}/images/logos/cn-icon-only.svg`} />
    </StyledContainer>
  );
};

export default MaintenanceScreen;
