import React, { FC } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from '@blocs.i18n';
import { updatesCardCountVar } from '../Auth/apollo/updatesCardCountVar';
import { getUnreadContentCardsCount, toggleContentCards } from './Braze';

type UpdatesFeedProps = {
  wrapperElement: FC<Record<string, unknown>>;
  additionalWrapperElProps: {
    [key: string]: unknown;
  };
};

// exported so can be spied on in UpdatesFeed.test.ts
export const contentCardCountRefresh = (): Promise<void> => {
  return getUnreadContentCardsCount((updatedCount: number) => {
    const currentCount = updatesCardCountVar();

    if (currentCount !== updatedCount) {
      updatesCardCountVar(updatedCount);
    }
  });
};

export const UpdatesFeed: FC<UpdatesFeedProps> = ({ wrapperElement: WrapperElement, additionalWrapperElProps }) => {
  const { t } = useTranslation();
  const count = useReactiveVar(updatesCardCountVar);

  const toggleUpdatesFeedDrawer = async () => {
    toggleContentCards('braze-updates-feed');

    // Handles count reset if user closes drawer by clicking X button (3rd party el)
    const brazeDrawerCloseBtn = document.querySelector('.ab-feed > .ab-feed-buttons-wrapper > .ab-close-button');

    brazeDrawerCloseBtn?.addEventListener('click', () => contentCardCountRefresh());

    // Handles count reset if user closes drawer by clicking Updates Link in App again
    await contentCardCountRefresh();
  };

  return (
    <>
      <WrapperElement onClick={toggleUpdatesFeedDrawer} {...additionalWrapperElProps}>
        {t('common:mainNav.updates')} {count > 0 ? `(${count})` : ''}
      </WrapperElement>
    </>
  );
};
