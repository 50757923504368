import * as React from 'react';
import Loader from '@minecraft.loader';
import LoaderContext from 'src/contexts/LoaderContext';
import { LoaderOverlay } from 'styles/Main.style';

const LoaderContextProvider: React.FunctionComponent = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const showLoader = (loading: boolean) => {
    setIsLoading(loading);
  };

  return (
    <LoaderContext.Provider value={{ isLoading, showLoader }}>
      <>
        {isLoading && (
          <LoaderOverlay>
            <Loader />
          </LoaderOverlay>
        )}
        {children}
      </>
    </LoaderContext.Provider>
  );
};

export default LoaderContextProvider;
