import React, { FC } from 'react';
import { useMergedResponsiveProps } from '../helpers';
import { GridProps, ResponsiveProps } from '../types';
import { GridBase } from './GridBase';

/**
 * GridContainer
 * @deprecated Use GridDynamic instead
 * @example
 * <GridContainer
 *  columns="repeat(2, 1fr)"
 * >
 *  <BasicContainer
      gridColumnStart={0}
      gridColumnEnd={3}
      gap={2}
      order={1}
    >
 *  </BasicContainer>
 * </GridContainer>
 */
export const GridContainer: FC<GridProps> = (props) => <GridBase {...props} />;

// GridDynamic component that adapts to screen size
export const GridDynamic: FC<GridProps & ResponsiveProps<GridProps>> = ({
  children,
  smProps,
  mdProps,
  lgProps,
  xlProps,
  ...props
}) => {
  const allProps = useMergedResponsiveProps({ props, smProps, mdProps, lgProps, xlProps });
  return <GridBase {...allProps}>{children}</GridBase>;
};
