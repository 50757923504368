import colors from '../../colors';
import { Typography } from '../../TSTypes/typography';

const typography: Typography = {
  body: {
    backgroundColor: colors.primary.darkGrey2,
    color: colors.primary.ltGrey2,
    font: '"IBM Plex Sans", sans-serif',
    fontWeight: '400',
    size: {
      xsm: {
        fontSize: '10px',
      },
      sm: {
        fontSize: '12px',
      },
      md: {
        fontSize: '14px',
      },
    },
  },
  header: {
    type: {
      h1: {
        fontWeight: '600',
        fontSize: '24px',
        color: colors.primary.darkGrey,
      },
      h2: {
        fontWeight: '500',
        fontSize: '20px',
        color: colors.primary.darkGrey,
      },
      h3: {
        fontWeight: '600',
        fontSize: '18px',
        color: colors.accent.dkBlue2,
      },
      h4: {
        fontWeight: '500',
        fontSize: '16px',
        color: colors.primary.darkGrey,
      },
    },
  },
};

export default typography;
