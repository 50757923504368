import React, { useState } from 'react';
import { DropdownItem } from 'reactstrap';
import orderBy from 'lodash/orderBy';
import truncate from 'lodash/truncate';
import { getEnvironment } from '@minecraft.environment';
import { usePermissions } from '@minecraft.permissions';
import { useTranslation } from '@blocs.i18n';
import { AccountOrgFragment, SystemRoleCode } from '@minecraft.graphql-operations';
import { ClaimAdditionalAccountModal, useUserContext, APPLICATIONS, ApplicationValue } from '@blocs.auth';
import { useModal } from '@minecraft.modal';
import { useGlobalNotification } from '@minecraft.notification-provider';
import { FEATURE_FLAGS, useFeatureExperiment } from '@blocs.features-experiments';
import { Updater } from '../typed';
import DivisionSwitcherIconItem from './DivisionSwitcherIconItem';
import DivisionSwitcherLogoItem from './DivisionSwitcherLogoItem';
import {
  StyledDivisionSwitcherLink,
  StyledDropdownMenu,
  StyledDropdownToggle,
  StyledButtonDropdown,
  StyledDivisionSwitcherSubMenu,
  StyledDivisionSwitcherLogoItemWrapper,
  ClaimAccountButton,
  StyledDivisionSwitcherItemWithMenu,
} from './DivisionSwitcherStyles';

interface GetSwitchableOrgsParams {
  organizations: AccountOrgFragment[];
  currentRole: AccountOrgFragment['systemRoleCode'];
  isCasting: boolean;
}

const getSwitchableOrgs = ({ organizations, currentRole, isCasting }: GetSwitchableOrgsParams) => {
  // all divisions appear in reps app regardless of role
  if (!isCasting) {
    return organizations;
  }

  // in casting, only the orgs with the current role should appear, plus collaborator (if applicable)
  const roleOrgs =
    currentRole === SystemRoleCode.SharedProjectUser
      ? organizations.filter(
          (o) =>
            o.systemRoleCode !== SystemRoleCode.SharedProjectUser &&
            [SystemRoleCode.CastingDirector, SystemRoleCode.Studio, SystemRoleCode.ProjectCreator].includes(
              o.systemRoleCode as SystemRoleCode
            )
        )
      : organizations.filter((o) => o.systemRoleCode === currentRole);
  const collabOrg = organizations.find((o) => o.systemRoleCode === SystemRoleCode.SharedProjectUser);

  if (collabOrg) {
    roleOrgs.push(collabOrg);
  }

  return roleOrgs;
};

/* ----- Types ----- */
type Props = {
  isNavbarOpen: boolean;
  shouldUseDivisions?: boolean;
  updater: Updater;
  showSettings: boolean;
  isPC?: boolean;
  showAdministrative?: boolean;
};

/* ----- Main Component ----- */
const DivisionSwitcher = ({
  updater,
  isNavbarOpen,
  shouldUseDivisions = true,
  showAdministrative = true,
  showSettings = false,
  isPC,
}: Props): JSX.Element => {
  const castingNotificationSettings = useFeatureExperiment(FEATURE_FLAGS.CASTING_NOTIFICATION_SETTINGS);
  const { t } = useTranslation();
  const { isOpen, open, close } = useModal();
  const { ability } = usePermissions();
  const { myAccount, tokens } = useUserContext();
  const environment = getEnvironment();
  const currentApp = environment['AUDIENCE'] as ApplicationValue;
  const currentRole = tokens?.accessParsed?.role?.code;
  const isReps = currentApp === APPLICATIONS.reps;
  const isCasting = currentApp === APPLICATIONS.casting;
  const selectedDivisionId = tokens?.accessParsed?.organizationId;
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(isNavbarOpen);
  const [isAccountSettingsDropdownOpen, setAccountSettingsDropdownOpen] = useState<boolean>(false);
  const { handleLogoutClick, handleDivisionChange } = updater;
  const { showMessage } = useGlobalNotification();
  const isLegacyUserOnboardingDeprecated = useFeatureExperiment(FEATURE_FLAGS.WEB_ONBOARDING_DEPRECATION);
  // Should only show the help center link if we are not showing the Zendesk widget
  const organizations = getSwitchableOrgs({
    organizations: shouldUseDivisions ? myAccount.divisions : myAccount.organizations,
    currentRole,
    isCasting,
  });
  const selectedDivision = organizations.find(
    (division) => division?.id?.toString() === selectedDivisionId?.toString()
  );
  const sortedDivisions = orderBy(organizations, ['name'], ['asc']);
  const toggleAccountSettingsDropdown = () => {
    setAccountSettingsDropdownOpen(!isAccountSettingsDropdownOpen);
  };

  const dropDownToggle = (): void => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleMenu = () => {
    dropDownToggle();
    toggleAccountSettingsDropdown();
  };

  const showNotificationSettingsItem = (castingNotificationSettings && isCasting) || isReps;

  return (
    <StyledButtonDropdown
      isOpen={isDropdownOpen}
      toggle={dropDownToggle}
      group={false}
      $isPC={isPC}
      data-qa-id="account-profile-menu"
    >
      <StyledDropdownToggle color="">
        {/* That's Basically Logo Item but with Username Data, that's why I did not create a separate component */}
        <DivisionSwitcherLogoItem
          username={truncate(`${myAccount.firstName} ${myAccount.lastName}`, { length: 20 })}
          org={selectedDivision}
          isPC={isPC}
          isPrimary
          qaId="account-selected"
          data-testid="selected-division-wrapper"
        />
      </StyledDropdownToggle>
      <StyledDropdownMenu>
        {sortedDivisions.map((division) => {
          const id = division?.id;
          const isSelected = String(id) === String(selectedDivisionId);

          return (
            !isSelected && (
              <StyledDivisionSwitcherLogoItemWrapper key={id} data-qa-id="account-division-menu">
                <DivisionSwitcherLogoItem
                  onClick={async () => {
                    try {
                      await handleDivisionChange(id, division?.systemRoleCode);
                    } catch (e) {
                      showMessage({ message: t('common:globalError.genericUnexpected'), color: 'danger' });
                    }
                  }}
                  org={division}
                  qaId="account-not-selected"
                  data-testid={`division-item-${id}`}
                />
              </StyledDivisionSwitcherLogoItemWrapper>
            )
          );
        })}

        {showSettings && (
          <StyledDivisionSwitcherItemWithMenu>
            <DivisionSwitcherIconItem
              dataQaId="account-settings-menu"
              onClick={toggleAccountSettingsDropdown}
              text={t('common:accountSettingsMenu.accountSettingsNoMark')}
              leftIcon="settings"
              isPC={isPC}
              isSubMenu
            />
            {isAccountSettingsDropdownOpen && (
              <>
                <StyledDivisionSwitcherSubMenu>
                  <StyledDivisionSwitcherLink to="/account-settings/details">
                    <DivisionSwitcherIconItem
                      onClick={toggleMenu}
                      text={t('common:accountDetails.subNav.accountDetails')}
                      isPC={isPC}
                    />
                  </StyledDivisionSwitcherLink>
                  {showNotificationSettingsItem && (
                    <StyledDivisionSwitcherLink to="/account-settings/notifications">
                      <DivisionSwitcherIconItem
                        onClick={toggleMenu}
                        text={t('common:accountDetails.subNav.notificationSettings')}
                        isPC={isPC}
                        dataQaId="notification-settings-menu"
                      />
                    </StyledDivisionSwitcherLink>
                  )}
                  {isReps && showAdministrative && (
                    <StyledDivisionSwitcherLink to="/account-settings/administrative">
                      <DivisionSwitcherIconItem
                        onClick={toggleMenu}
                        text={t('common:accountDetails.subNav.administrative')}
                        isPC={isPC}
                        dataQaId="administrative-settings-menu"
                      />
                    </StyledDivisionSwitcherLink>
                  )}
                </StyledDivisionSwitcherSubMenu>
              </>
            )}
          </StyledDivisionSwitcherItemWithMenu>
        )}
        {!isLegacyUserOnboardingDeprecated && (
          <ClaimAccountButton data-qa-id="global-header-additional-account-claim" onClick={open}>
            <DivisionSwitcherIconItem
              text={t('common:accountSettingsMenu.claimAdditionalAccounts')}
              leftIcon="individual"
              isPC={isPC}
            />
          </ClaimAccountButton>
        )}
        <ClaimAdditionalAccountModal
          isOpen={isOpen}
          close={close}
          handleDivisionChange={(orgId) => handleDivisionChange(orgId, currentRole)}
        />
        {!ability.can('display', 'zendesk-support') && (
          <DivisionSwitcherIconItem
            as={DropdownItem}
            onClick={() => window.open('https://support.castingnetworks.com/hc/en-us', '_blank')}
            leftIcon="help-filled"
            text={t('common:mainNav.helpCenter')}
            isPC={isPC}
          />
        )}
        <DivisionSwitcherIconItem
          as={DropdownItem}
          onClick={handleLogoutClick}
          leftIcon="logout"
          text={t('common:mainNav.logOut')}
          isPC={isPC}
        />
      </StyledDropdownMenu>
    </StyledButtonDropdown>
  );
};

export default DivisionSwitcher;
