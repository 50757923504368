import { useEffect } from 'react';

const useRemoveSplashLoader = () => {
  useEffect(() => {
    const el = document.getElementById('splash');
    if (el) {
      el.remove();
    }
  }, []);
};

export default useRemoveSplashLoader;
