import React, { FC } from 'react';
import styled from 'styled-components';
import { media } from '@minecraft.themes';
import { useThemeSwitcherContext } from '../ThemeSwitcherContext';

const SwicherButton = styled.div<{ $isDarkTheme: boolean }>`
  border-width: 0.0625rem;
  border-style: solid;
  border-radius: 50%;
  display: flex;
  cursor: pointer;
  flex-shrink: 0;
  height: 1.5625rem;
  width: 1.5625rem;

  ${(props): string =>
    props.$isDarkTheme
      ? `
        background: linear-gradient(to right, ${props.theme.colors.primary.ltGrey2} 0%, ${props.theme.colors.primary.ltGrey2} 50%, ${props.theme.colors.primary.darkGrey} 50%, ${props.theme.colors.primary.darkGrey} 100%);
        border-color: ${props.theme.colors.primary.ltGrey2};
        box-shadow: inset 0 0 0.0625rem 0.0625rem ${props.theme.colors.primary.ltGrey2};
      `
      : `
      background: linear-gradient(to right, ${props.theme.colors.primary.white} 0%, ${props.theme.colors.primary.white} 50%, ${props.theme.colors.primary.darkGrey} 50%, ${props.theme.colors.primary.darkGrey} 100%);
      border-color: ${props.theme.colors.primary.darkGrey};
      box-shadow: inset 0 0 0.0625rem 0.0625rem ${props.theme.colors.primary.white};
      `}

  ${media.sm`
    height: 1.0625rem;
    width: 1.0625rem;
  `}
`;

type Props = {
  className?: string;
};

const ThemeSwitcherButton: FC<Props> = ({ className }) => {
  const { isDarkTheme, handleThemeSwitch } = useThemeSwitcherContext();

  return (
    <SwicherButton
      $isDarkTheme={isDarkTheme}
      className={className}
      tabIndex={0}
      role="button"
      onClick={handleThemeSwitch}
    />
  );
};

export default ThemeSwitcherButton;
