import { parseBase64, TokenParsed } from '@minecraft.utils';

export const parseJwt = (token: string): TokenParsed | null => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    return parseBase64(base64);
  } catch (e) {
    return null;
  }
};

export const isTokenExpired = (token: string): boolean => {
  if (!token) {
    return true;
  }

  const parsedToken = parseJwt(token);

  if (!parsedToken) return true;

  const expirationDate = parsedToken.exp * 1000;
  const actualDate = new Date().getTime();

  return actualDate > expirationDate;
};
