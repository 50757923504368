import * as React from 'react';
import { Alert } from 'reactstrap';
import styled, { css } from 'styled-components';
import { HideOut } from '@minecraft.animation';
import { Theme } from '@minecraft.themes';

export type Props = {
  children: React.ReactNode;
  close?: string;
  type: 'error' | 'progress' | 'warning' | 'success' | 'notify';
  size: 'fullWidth' | 'inPage' | 'cbPublish';
  theme?: Theme;
  sticky?: boolean;
  onClose?: () => void;
};

const StyledHideOut = styled(HideOut)`
  position: relative;
  z-index: 99999;
`;

const ExtendedUncontrolledAlert = ({ children, onClose, ...restProps }) => {
  const [isOpened, setIsOpened] = React.useState<boolean>(true);

  return (
    <Alert
      isOpen={isOpened}
      toggle={() => {
        setIsOpened(!isOpened);
        onClose && onClose();
      }}
      {...restProps}
    >
      {children}
    </Alert>
  );
};

const CNIUncontrolledAlert = styled(ExtendedUncontrolledAlert)`
  color: ${(props) => props.theme.alert.type[props.type].color};
  background-color: ${(props) => props.theme.alert.type[props.type].backgroundColor};
  border-radius: ${(props) => props.theme.alert.size[props.size].borderRadius};
  padding: ${(props) => props.theme.alert.size[props.size].padding};
  line-height: ${(props) => props.theme.alert.size[props.size].lineHeight};
  position: ${(props) => props.theme.alert.size[props.size].position};
  left: ${(props) => props.theme.alert.size[props.size].left};
  border: none;
  width: 100%;
  font-weight: 600;
  text-align: center;
  .close,
  .close:not(:disabled):not(.disabled):focus,
  .close:not(:disabled):not(.disabled):hover {
    color: ${(props) => props.theme.alert.type[props.type].color};
    padding: ${(props) => props.theme.alert.size[props.size].padding};
    font-weight: 100;
    text-shadow: none;
    opacity: 1;
    outline: 0;
  }
  z-index: 1;
`;

const CNIAlert = styled(Alert)`
  color: ${(props) => props.theme.alert.type[props.type].color};
  background-color: ${(props) => props.theme.alert.type[props.type].backgroundColor};
  border-radius: ${(props) => props.theme.alert.size[props.size].borderRadius};
  padding: ${(props) => props.theme.alert.size[props.size].padding};
  line-height: ${(props) => props.theme.alert.size[props.size].lineHeight};
  position: ${(props) => props.theme.alert.size[props.size].position};
  left: ${(props) => props.theme.alert.size[props.size].left};
  border: none;
  width: 100%;
  font-weight: 600;
  text-align: center;
  z-index: 1;
`;

const stickyStyles = css`
  padding: ${(props) => props.theme.alert.sticky.padding};
  position: ${(props) => props.theme.alert.sticky.position};
  left: ${(props) => props.theme.alert.sticky.left};
  right: ${(props) => props.theme.alert.sticky.right};
  top: ${(props) => props.theme.alert.sticky.top};
  width: ${(props) => props.theme.alert.sticky.width};
  z-index: ${(props) => props.theme.alert.sticky.zIndex};
`;

const CNIStickyUncontrolledAlert = styled(CNIUncontrolledAlert)<any>`
  ${stickyStyles}
`;
const CNIStickyAlert = styled(CNIAlert)`
  ${stickyStyles}
`;

const GetAlertComponent = ({ sticky = false, ...props }) => {
  const animationRef = React.useRef(null);

  if (animationRef.current) {
    animationRef.current.style.display = 'block';
  }

  if (props.close === 'true') {
    return sticky ? (
      <CNIStickyUncontrolledAlert {...props}>{props.children}</CNIStickyUncontrolledAlert>
    ) : (
      <CNIUncontrolledAlert {...props}>{props.children}</CNIUncontrolledAlert>
    );
  }

  return (
    <StyledHideOut
      ref={animationRef}
      duration="5s"
      delay="0s"
      onAnimationEnd={() => {
        animationRef.current.style.display = 'none';

        return props.onClose && props.onClose();
      }}
    >
      {sticky ? (
        <CNIStickyAlert {...props}>{props.children}</CNIStickyAlert>
      ) : (
        <CNIAlert {...props}>{props.children}</CNIAlert>
      )}
    </StyledHideOut>
  );
};

export default (props: Props) => GetAlertComponent(props);
