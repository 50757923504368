import { BrowserHistoryBuildOptions, createBrowserHistory, History } from 'history';

let history;

/**
 * @deprecated Use `useHistory` directly
 */
export function getRouterHistory() {
  return history;
}

/**
 * @deprecated
 * @param options
 */
export function setRouterHistory(options: BrowserHistoryBuildOptions): History {
  history = createBrowserHistory(options);
  return getRouterHistory();
}
