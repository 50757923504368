import { DESIGN_SYSTEM_COLORS } from '../../constants';
import { Color } from '../../TSTypes/button';

export const primaryColor: Color = {
  outlined: {
    true: {
      state: {
        default: {
          color: DESIGN_SYSTEM_COLORS.grayscale['650'],
          backgroundColor: DESIGN_SYSTEM_COLORS.grayscale['0'],
          border: `1px solid ${DESIGN_SYSTEM_COLORS.grayscale['650']}`,
        },
        hover: {
          color: DESIGN_SYSTEM_COLORS.grayscale['0'],
          backgroundColor: DESIGN_SYSTEM_COLORS.grayscale['600'],
          border: `1px solid ${DESIGN_SYSTEM_COLORS.grayscale['600']}`,
        },
        active: {
          color: DESIGN_SYSTEM_COLORS.grayscale['0'],
          backgroundColor: DESIGN_SYSTEM_COLORS.grayscale['500'],
          border: `1px solid ${DESIGN_SYSTEM_COLORS.grayscale['500']}`,
        },
        focus: {
          color: DESIGN_SYSTEM_COLORS.grayscale['650'],
          backgroundColor: DESIGN_SYSTEM_COLORS.grayscale['0'],
          border: `1px solid ${DESIGN_SYSTEM_COLORS.grayscale['650']}`,
          boxShadow: `0px 0px 0px 0.2rem rgba(97, 30, 239, 0.2)`,
        },
        disabled: {
          color: DESIGN_SYSTEM_COLORS.grayscale['650'],
          backgroundColor: DESIGN_SYSTEM_COLORS.grayscale['750'],
          border: `1px solid ${DESIGN_SYSTEM_COLORS.grayscale['750']}`,
        },
      },
    },
    false: {
      state: {
        default: {
          color: DESIGN_SYSTEM_COLORS.grayscale['0'],
          backgroundColor: DESIGN_SYSTEM_COLORS.grayscale['650'],
          border: `1px solid ${DESIGN_SYSTEM_COLORS.grayscale['650']}`,
        },
        hover: {
          color: DESIGN_SYSTEM_COLORS.grayscale['0'],
          backgroundColor: DESIGN_SYSTEM_COLORS.grayscale['600'],
          border: `1px solid ${DESIGN_SYSTEM_COLORS.grayscale['600']}`,
        },
        active: {
          color: DESIGN_SYSTEM_COLORS.grayscale['0'],
          backgroundColor: DESIGN_SYSTEM_COLORS.grayscale['500'],
          border: `1px solid ${DESIGN_SYSTEM_COLORS.grayscale['500']}`,
        },
        focus: {
          color: DESIGN_SYSTEM_COLORS.grayscale['0'],
          backgroundColor: DESIGN_SYSTEM_COLORS.grayscale['650'],
          border: `1px solid ${DESIGN_SYSTEM_COLORS.grayscale['650']}`,
          boxShadow: `0px 0px 0px 0.2rem rgba(97, 30, 239, 0.2)`,
        },
        disabled: {
          color: DESIGN_SYSTEM_COLORS.grayscale['650'],
          backgroundColor: DESIGN_SYSTEM_COLORS.grayscale['750'],
          border: `1px solid ${DESIGN_SYSTEM_COLORS.grayscale['750']}`,
        },
      },
    },
  },
};
