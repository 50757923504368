import React, { FC } from 'react';
import styled from 'styled-components';

const StyledLineItem = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.designSystem.borderColors.default};

  /** The last line item should not have a border **/
  &:last-of-type {
    border-bottom: none;
  }
`;

export type LineItemProps = {
  className?: string;
  'data-testid'?: string;
};

/**
 * A simple item that's usually in a list or stack of elements, it simply adds the bottom border and a bit of padding
 * [Figma Diagram](https://www.figma.com/file/AiDAD1WpprgfbZI2XV6hU1/Casting-Networks-Design-System?node-id=1143%3A3&t=Q5QF7ColgZMIcWX0-0)
 * See "Project Details" section
 *
 * @example
 * <LineItem>Any Child Component</LineItem>
 */
export const LineItem: FC<LineItemProps> = ({ children, className, 'data-testid': dataTestId }) => {
  return (
    <StyledLineItem className={`cn_w-full cn_atom_pb-2 ${className ?? ''}`} data-testid={dataTestId}>
      {children}
    </StyledLineItem>
  );
};
