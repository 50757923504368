import React, { FC } from 'react';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { getEnvironment } from '@minecraft.environment';
import { buildFeatureUser } from './utils';

const FeatureExperimentProvider: FC = ({ children }) => {
  const environment = getEnvironment();
  const user = buildFeatureUser();

  return (
    <LDProvider
      clientSideID={environment.LAUNCH_DARKLY_CLIENT_ID}
      context={{
        kind: 'user',
        ...user,
      }}
      reactOptions={{ useCamelCaseFlagKeys: false }}
      options={{ disableSyncEventPost: true }}
    >
      {children}
    </LDProvider>
  );
};

export default FeatureExperimentProvider;
