/**
 * HasPattern
 * @param value
 * @param pattern
 * @return boolean
 */

export const HasPattern = (value, pattern) => {
  if (value.match(pattern)) {
    return true;
  }

  return false;
};

export default HasPattern;
