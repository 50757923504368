import { useCallback, useMemo } from 'react';
import moment, { MomentInput } from 'moment-timezone';
import { useTranslation as baseHook } from 'react-i18next';
import { i18n as i18nType } from 'i18next';
import {
  TranslateFn,
  IntlDateFormatName,
  IntlDateFormatter,
  IntlPriceFormatter,
  IntlRelativeTimeFormatter,
  IntlDateAtTimeFormatter,
  IntlDateParams,
} from './types';

function convertIntlDateToTz(date: MomentInput, sourceTzStandardName: string): string | null {
  if (!sourceTzStandardName) {
    // if the timezone is undefined then moment will just default
    // to UTC which will give the wrong date, better to
    // return nothing so it's easier to catch the bug
    return '';
  }

  // strip UTC "Z" timezone off and cast the date to the source timezone
  return moment.tz(date.toString().replace('Z', ''), sourceTzStandardName).toISOString();
}

type IntlDateFormatOptions = {
  weekday?: 'narrow' | 'short' | 'long';
  era?: 'narrow' | 'short' | 'long';
  year?: 'numeric' | '2-digit';
  month?: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long';
  day?: 'numeric' | '2-digit';
  hour?: 'numeric' | '2-digit';
  minute?: 'numeric' | '2-digit';
  second?: 'numeric' | '2-digit';
  timeZoneName?: 'short' | 'long';
  // Time zone to express it in
  timeZone?: string;
  // Force 12-hour or 24-hour
  hour12?: true | false;
};

type IntlDateFormatOptionsFn = (timeZoneStandardName?: string) => IntlDateFormatOptions;
const INTL_DATE_FORMATS: { [K in IntlDateFormatName]: IntlDateFormatOptionsFn } = {
  DAY_SHORT: (timeZoneStandardName) => ({
    day: 'numeric',
    timeZone: timeZoneStandardName,
  }),
  WEEK_SHORT: (timeZoneStandardName) => ({
    weekday: 'short',
    timeZone: timeZoneStandardName,
  }),
  MONTH_SHORT: (timeZoneStandardName) => ({
    month: 'short',
    timeZone: timeZoneStandardName,
  }),
  MONTH_LONG: (timeZoneStandardName) => ({
    month: 'long',
    timeZone: timeZoneStandardName,
  }),
  MONTH_DAY: (timeZoneStandardName) => ({
    month: 'short',
    day: 'numeric',
    timeZone: timeZoneStandardName,
  }),
  MONTH_DAY_YEAR: (timeZoneStandardName) => ({
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    timeZone: timeZoneStandardName,
  }),
  MONTH_DAY_YEAR_NUMERIC: (timeZoneStandardName) => ({
    month: 'numeric',
    day: '2-digit',
    year: 'numeric',
    timeZone: timeZoneStandardName,
  }),
  MONTH_DAY_YEAR_TIME: (timeZoneStandardName) => ({
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: timeZoneStandardName,
  }),
  MONTH_DAY_YEAR_TZ: (timeZoneStandardName) => ({
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZoneName: 'short',
    timeZone: timeZoneStandardName,
  }),
  MONTH_DAY_YEAR_TIME_AT: (timeZoneStandardName) => ({
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: 'numeric',
    timeZone: timeZoneStandardName,
    hour12: true,
  }),
  MONTH_DAY_YEAR_TIME_TZ: (timeZoneStandardName) => ({
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
    timeZone: timeZoneStandardName,
  }),
  MONTH_DAY_TIME: (timeZoneStandardName) => ({
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: timeZoneStandardName,
  }),
  MONTH_DAY_TIME_TZ: (timeZoneStandardName) => ({
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
    timeZone: timeZoneStandardName,
  }),
  YEAR: (timeZoneStandardName) => ({
    year: 'numeric',
    timeZone: timeZoneStandardName,
  }),
  TIME: (timeZoneStandardName) => ({
    hour: 'numeric',
    minute: 'numeric',
    timeZone: timeZoneStandardName,
  }),
  TIME_TZ: (timeZoneStandardName) => ({
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
    timeZone: timeZoneStandardName,
  }),
  MONTH_LONG_DAY_YEAR: (timeZoneStandardName) => ({
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZone: timeZoneStandardName,
  }),
  MONTH_LONG_DAY_YEAR_TIME_TZ: (timeZoneStandardName) => ({
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
    timeZone: timeZoneStandardName,
  }),
  TIME_12_HOUR: () => ({
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }),
};

// for possible range units, see
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/RelativeTimeFormat/format#parameters
enum RelativeTimeRange {
  Year = 'year',
  Month = 'month',
  Day = 'day',
  Hour = 'hour',
  Minute = 'minute',
  Second = 'second',
}

type RangesTuple = [
  RelativeTimeRange.Year,
  RelativeTimeRange.Month,
  RelativeTimeRange.Day,
  RelativeTimeRange.Hour,
  RelativeTimeRange.Minute,
  RelativeTimeRange.Second
];

type IntlRelativeTimeRangeOptions =
  | RelativeTimeRange.Year
  | RelativeTimeRange.Month
  | RelativeTimeRange.Day
  | RelativeTimeRange.Hour
  | RelativeTimeRange.Minute
  | RelativeTimeRange.Second;
type IntlRelativeTimeAndRange = { time: number; range: IntlRelativeTimeRangeOptions };

const getRelativeTimeAndRange = (date: string): IntlRelativeTimeAndRange => {
  const now = moment();
  const relativeDate = moment(date);

  // can drop using moment lib here if decided to use different diff algo
  const getTimeDifferenceIn = (range: Partial<moment.unitOfTime.Diff>) => relativeDate.diff(now, range);

  // order matters here so using tuple
  const ranges: RangesTuple = [
    RelativeTimeRange.Year,
    RelativeTimeRange.Month,
    RelativeTimeRange.Day,
    RelativeTimeRange.Hour,
    RelativeTimeRange.Minute,
    RelativeTimeRange.Second,
  ];

  let relativeTimeAndRange: IntlRelativeTimeAndRange | null = null;

  ranges.forEach((range, i, arr) => {
    if (relativeTimeAndRange) return;

    const time = getTimeDifferenceIn(range);

    if (time || i === arr.length - 1) {
      relativeTimeAndRange = { time, range };
    }
  });

  return relativeTimeAndRange;
};

interface UseTranslationHelpers {
  t: TranslateFn;
  i18n: i18nType;
  ready: boolean;
  formatIntlDate: IntlDateFormatter;
  formatIntlPrice: IntlPriceFormatter;
  formatIntlRelativeTime: IntlRelativeTimeFormatter;
  formatIntlDateAtTime: IntlDateAtTimeFormatter;
}

// useTranslation wrapper in case we need to provide any
// custom behavior to the inputs / output
const useTranslation = (): UseTranslationHelpers => {
  const { t, i18n, ready } = baseHook();

  // helper to format dates / times using i18next datetime, see:
  // https://www.i18next.com/translation-function/formatting#datetime
  const formatIntlDate = useCallback(
    ({
      key = 'common:datetime.formattedDate',
      date,
      format = 'MONTH_DAY_YEAR_TIME',
      timeZoneStandardName = 'Etc/UTC',
      destTimeZoneStandardName,
      replaceUtc = true,
    }) => {
      // can't format undefined
      if (!date || !date.toString || typeof date.toString !== 'function') {
        return '';
      }

      const hasSourceAndDest = Boolean(timeZoneStandardName && destTimeZoneStandardName);
      const doUtcReplace = hasSourceAndDest ? false : replaceUtc;
      let normalizedDate = date.toString();

      if (hasSourceAndDest) {
        normalizedDate = convertIntlDateToTz(date, timeZoneStandardName);
      }

      if (doUtcReplace) {
        normalizedDate = normalizedDate.replace('Z', '');
      }

      const finalTz = hasSourceAndDest ? destTimeZoneStandardName : timeZoneStandardName;
      const inputDate = moment.tz(normalizedDate, finalTz);

      if (!inputDate.isValid()) {
        return t('common:datetime.invalidDate');
      }

      const options = {
        date: inputDate.toDate(),
        formatParams: {
          date: INTL_DATE_FORMATS[format](finalTz),
          // locale: <determine locale based on user's language and country
          // so that timezones on other continents display correctly
          // see: https://github.com/marnusw/date-fns-tz/issues/54
        },
      };

      return t(key, options);
    },
    [t]
  );

  // helper to format paired data strings, defaults to this format: Aug 16, 2022 at 6:14 PM
  const formatIntlDateAtTime = ({
    date,
    dateFormat = 'MONTH_DAY_YEAR',
    timeFormat = 'TIME',
    key = 'common:datetime.dateAtTime',
    timeZoneStandardName = 'Etc/UTC',
    destTimeZoneStandardName,
    replaceUtc = true,
  }: {
    date: any;
    dateFormat?: IntlDateFormatName;
    timeFormat?: IntlDateFormatName;
    key?: string;
    timeZoneStandardName?: string;
    destTimeZoneStandardName?: string;
    replaceUtc?: boolean;
  }) => {
    const day = formatIntlDate({
      date,
      format: dateFormat,
      replaceUtc,
      timeZoneStandardName,
      destTimeZoneStandardName,
    });

    const time = formatIntlDate({
      date,
      format: timeFormat,
      replaceUtc,
      timeZoneStandardName,
      destTimeZoneStandardName,
    });

    return t(key, {
      date: day,
      time,
    });
  };

  return {
    t,
    i18n,
    ready,
    formatIntlDate,
    formatIntlDateAtTime,
    // helper to format relative time using i18next relativetime, see:
    // https://www.i18next.com/translation-function/formatting#relativetime
    formatIntlRelativeTime: useCallback(
      ({ key = 'common:datetime.formattedRelativeTime', date }) => {
        // can't format undefined
        if (!date || !date.toString || typeof date.toString !== 'function') {
          return '';
        }

        const { time, range } = getRelativeTimeAndRange(date.toString());

        if (Number.isNaN(time) || !range) {
          return t('common:datetime.invalidDate');
        }

        const options = {
          time,
          formatParams: {
            time: {
              range,
              style: 'long',
              numeric: 'auto',
              locale: i18n.languages,
            },
          },
        };

        return t(key, options);
      },
      [t, i18n.languages]
    ),
    // helper to format prices using i18next currency, see:
    // https://www.i18next.com/translation-function/formatting#currency
    formatIntlPrice: useCallback(
      ({ key = 'common:currency.formattedPrice', price, currency }) => {
        // can't format undefined data
        if (!currency || typeof price !== 'number') {
          return '';
        }

        const options = {
          price,
          formatParams: {
            price: { currency, locale: i18n.languages },
          },
        };

        return t(key, options);
      },
      [i18n.languages, t]
    ),
  };
};

export default useTranslation;

/**
 * This hook is used to format dates and times according to the logged-in users timezone preferences.
 *
 * When formatting dates we should use the value of myAccount?.timeZone?.standardName from the user context
 * Unfortunately we can't pull this value from the user context in this hook because it would cause a circular dependency.
 */
export const useIntlDateTime = (timeZoneStandardName: string) => {
  const { formatIntlDate } = useTranslation();

  return useMemo(
    () => (params: IntlDateParams) => formatIntlDate({ timeZoneStandardName, ...params }),
    [timeZoneStandardName, formatIntlDate]
  );
};
