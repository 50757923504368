import React, { FC } from 'react';
import { FlexDynamic, FlexDynamicProps } from './Containers';

export const ButtonsWrapper: FC<FlexDynamicProps> = ({
  children,
  ...rest
}: FlexDynamicProps & { children: React.ReactNode }) => {
  return (
    <FlexDynamic
      data-testid="buttons-wrapper"
      direction="column"
      reverse
      alignItems="center"
      justifyContent="flex-start"
      gap="4"
      mdProps={{ direction: 'row', reverse: false, justifyContent: 'flex-end', wrap: 'nowrap' }}
      {...rest}
    >
      {children}
    </FlexDynamic>
  );
};
