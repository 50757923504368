import React, { useMemo } from 'react';
import styled from 'styled-components';
import { ColDef } from '@ag-grid-community/core';
import _get from 'lodash/get';
import { ListGroupItem, UnorderedListGroup } from '../ListGroup';
import { BodyText, BodyTextSmall } from '../Typography';
import { BasicContainer } from '../Containers';

const StyledUnorderedListGroup = styled(UnorderedListGroup)`
  width: 100%;
`;

// Note that AG Grid rows are all relative positioned, so this is relative to that assumption
const StyledActionBox = styled.div`
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  z-index: 1000;
`;

interface MobileCellProps {
  columnDefs: ColDef[];
  data: any;
  basicContainerProps?: Omit<React.ComponentProps<typeof BasicContainer>, 'className'>;
}

/**
 * This Mobile cell is used by AG Grid with a specialized colId called 'actions'
 * It will handle rendering all of your columns in a single cell but will also render
 * the contents of th 'actions' cell you define into a meatball menu
 *
 * Visual example can be seen in _lookbook_/MobileCellRenderer
 * It's usage can be seen in AutoArchiveModalCellRenderers
 */
export const MobileCell = ({ data, columnDefs, basicContainerProps = {} }: MobileCellProps) => {
  const actionCol = useMemo(() => columnDefs.find((col) => col.colId === 'actions'), [columnDefs]);
  const colsWithoutActions = useMemo(
    () => columnDefs.filter((col) => col.colId !== 'actions' && !col.hide),
    [columnDefs]
  );

  return (
    <BasicContainer {...basicContainerProps} className="cn_w-full cn_mt-4">
      <StyledUnorderedListGroup>
        <StyledActionBox>
          {actionCol?.cellRenderer({ data, field: _get(data, actionCol.field), ...actionCol?.cellRendererParams })}
        </StyledActionBox>
        {colsWithoutActions.map((column) => (
          <ListGroupItem key={column.colId ?? column.field}>
            <BodyTextSmall fontWeight="semibold">{column.headerName}</BodyTextSmall>
            <BodyTextSmall as="span" truncatableNoWrap>
              {column?.cellRenderer?.({ data, field: _get(data, column.field), ...column?.cellRendererParams }) ?? (
                <BodyText>{_get(data, column.field)}</BodyText>
              )}
            </BodyTextSmall>
          </ListGroupItem>
        ))}
      </StyledUnorderedListGroup>
    </BasicContainer>
  );
};
