const SCREEN_NAMES = {
  FORGOT_PASSWORD: 'forgot-password',
  LOGIN: 'login',
  PROFILE_CREATE: 'profile-create',
  UPLOAD_PHOTO: 'upload-photo',
  UPLOAD_VIDEO: 'upload-video',
  UPLOAD_AUDIO: 'upload-audio',
  IMAGE_CROPPER: 'image-cropper',
  MEDIA_VIEWER: 'media-viewer',
  PROFILE_VIEW: 'profile-view',
  EDIT_PROFILE: 'edit-profile',
};

export default SCREEN_NAMES;
