export enum HEAP_EVENT_NAMES {
  REP_MEDIA_UPLOAD = 'Rep Media Upload',
  DISPLAY_UPGRADE_MODAL = 'Display Upgrade Modal',
  SUCCESSFUL_TALENT_SIGN_UP = 'Successful Talent Sign Up',
  HOVER_IN_MR_ICON_SCHEDULER = 'Hover In MR Icon-Scheduler',
  TALENT_LOGIN_NAV_SUCCESSFUL_LOGIN = 'Talent - Login - Nav - Successful Login',
  TALENT_LOGIN_NAV_FAILED_LOGIN = 'Talent - Login - Nav - Failed Login',
  TALENT_LOGIN_NAV_RESET_PASSWORD = 'Talent - Login - Nav - Reset Password',
  TALENT_LOGIN_NAV_LEGACY_RESET_PASSWORD = 'Talent - Login - Nav - Legacy Reset Password',
}
