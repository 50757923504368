import { InMemoryCache, useMutation } from '@apollo/client';
import { SHOW_GLOBAL_ALERT, GET_GLOBAL_ALERT } from './graphqls/globalAlert';
import GlobalAlertComponent from './GlobalAlert';

/**
 * @deprecated use `useGlobalNotification` instead
 */
export type AlertTypes = 'error' | 'progress' | 'warning' | 'success';

/**
 * @deprecated use `useGlobalNotification` instead
 */
export type AlertSize = 'fullWidth' | 'inPage' | 'cbPublish';

/**
 * @deprecated use `useGlobalNotification` instead
 */
export type AlertFields = {
  type?: AlertTypes;
  size?: AlertSize;
  text?: string | { key?: string; components?: string[] };
  isCloseable?: boolean;
  sticky?: boolean;
  isShown?: boolean;
  isHtmlTag?: boolean;
  isTransTag?: boolean;
};

/**
 * @deprecated use `useGlobalNotification` instead
 */
export type GlobalAlertConfig = {
  variables: {
    globalAlertFields: AlertFields;
  };
};

/**
 * @deprecated use `useGlobalNotification` instead
 */
export const getGlobalAlertConfig = (text: string, type: AlertTypes, isHtmlTag = false): GlobalAlertConfig => ({
  variables: {
    globalAlertFields: { text, type, isHtmlTag },
  },
});

/**
 * @deprecated use `useGlobalNotification` instead
 */
export const useGlobalAlert = (globalAlertFields?: AlertFields): { showGlobalAlert } => {
  const [showGlobalAlert] = useMutation(SHOW_GLOBAL_ALERT, {
    variables: {
      globalAlertFields: {
        ...globalAlertFields,
      },
    },
  });

  return { showGlobalAlert };
};

/**
 * @deprecated use `useGlobalNotification` instead
 */
export const initializeAlertState = (cache: InMemoryCache): void => {
  cache.writeQuery({
    query: GET_GLOBAL_ALERT,
    data: {
      globalAlert: {
        __typename: 'GlobalAlert',
        type: 'success',
        size: 'fullWidth',
        text: '',
        isCloseable: false,
        sticky: true,
        isShown: false,
        isHtmlTag: false,
        isTransTag: false,
      },
    },
  });
};

/**
 * @deprecated use `useGlobalNotification` instead
 */
export const globalAlertResolvers = {
  Mutation: {
    showGlobalAlert: (_, { globalAlertFields }, { cache }): null => {
      const previous = cache.readQuery({ query: GET_GLOBAL_ALERT });

      cache.writeQuery({
        query: GET_GLOBAL_ALERT,
        data: {
          globalAlert: {
            ...previous.globalAlert,
            ...globalAlertFields,
            isShown: true,
          },
        },
      });

      return null;
    },
    hideGlobalAlert: (_, globalAlertOptions, { cache }): null => {
      const previous = cache.readQuery({ query: GET_GLOBAL_ALERT });

      cache.writeQuery({
        query: GET_GLOBAL_ALERT,
        data: {
          globalAlert: {
            ...previous.globalAlert,
            isShown: false,
          },
        },
      });

      return null;
    },
  },
};

/**
 * @deprecated use `useGlobalNotification` instead
 */
export const LINK_STATE = {
  defaults: {
    globalAlert: {
      __typename: 'GlobalAlert',
      type: 'success',
      size: 'fullWidth',
      text: '',
      isCloseable: false,
      sticky: true,
      isShown: false,
      isHtmlTag: false,
      isTransTag: false,
    },
  },
  resolvers: {
    Mutation: {
      showGlobalAlert: (_, { globalAlertFields }, { cache }): null => {
        const previous = cache.readQuery({ query: GET_GLOBAL_ALERT });

        cache.writeQuery({
          query: GET_GLOBAL_ALERT,
          data: {
            globalAlert: {
              ...previous.globalAlert,
              ...globalAlertFields,
              isShown: true,
            },
          },
        });

        return null;
      },
      hideGlobalAlert: (_, globalAlertOptions, { cache }): null => {
        const previous = cache.readQuery({ query: GET_GLOBAL_ALERT });

        cache.writeQuery({
          query: GET_GLOBAL_ALERT,
          data: {
            globalAlert: {
              ...previous.globalAlert,
              isShown: false,
            },
          },
        });

        return null;
      },
    },
  },
};

export { GlobalAlertComponent, SHOW_GLOBAL_ALERT, GET_GLOBAL_ALERT };
