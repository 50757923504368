const meatballMenuOptions = {
  OPTIONS: {
    SELECT_FROM_MEDIA_BIN: 'common:profile.button.fromMediaLibrary', // select option for Talents App
    SELECT_FROM_TALENT_MEDIA: 'common:profile.button.selectFromTalentMedia', // select option for Reps App
    UPLOAD_NEW: 'common:profile.button.uploadNew',
    HIDE_ON_PROFILE: 'common:profile.button.hideOnProfile',
    SET_AS_PRIMARY_PHOTO: 'common:profile.button.setPrimaryPhoto',
    SET_AS_PRIMARY_VIDEO: 'common:profile.button.setVideoReel',
    SET_AS_PRIMARY_AUDIO: 'common:profile.button.setAudioReel',
    EDIT_PHOTO: 'common:mediaHelper.photo.edit',
    EDIT_PHOTO_OR_THUMBNAIL: 'common:profile.button.editPhotoThumbnail',
  },
};

export default meatballMenuOptions;
