import React, { FC, ReactElement } from 'react';
import { MaintenanceScreen } from '@minecraft.splash-screens';
import { useFeatureExperiment, FEATURE_FLAGS, canSkipMaintenanceGuard } from '@blocs.features-experiments';

interface Props {
  routes: () => ReactElement;
}
const MaintenanceGuard: FC<Props> = ({ routes: Routes }) => {
  const isUnscheduledMaintenance: boolean = useFeatureExperiment(FEATURE_FLAGS.UNSCHEDULED_MAINTENANCE);
  const isScheduledMaintenance: boolean = useFeatureExperiment(FEATURE_FLAGS.SCHEDULED_MAINTENANCE);
  const isQaViewMaintenance: boolean = useFeatureExperiment(FEATURE_FLAGS.QA_VIEW_MAINTENANCE);
  const hasQaOverride = canSkipMaintenanceGuard();

  // display scheduled maintenance when:
  // - a QA user has the View Maintenance Flag ON
  // - the Scheduled Maintenance Flag is ON and the user does not have the QA override cookie
  if (isQaViewMaintenance || (isScheduledMaintenance && !hasQaOverride)) {
    return <MaintenanceScreen scheduled />;
  }

  // display unscheduled maintenance when:
  // - User is not in backlot AND
  // - the Unscheduled Maintenance Flag is ON and the user does not have the QA override cookie
  if (isUnscheduledMaintenance && !hasQaOverride) {
    return <MaintenanceScreen />;
  }

  return <Routes />;
};

export default MaintenanceGuard;
