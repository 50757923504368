import { ImageFileExtension, Angle } from 'cloudinary-react';
import * as profileConstants from './profileConstants';
import instanceConstants from './instanceConstants';

export const NO_COUNTRY_RESTRICTIONS = 'ALL';
export const commonPhotoTransformationParams = {
  // quality
  q: '100',
  // fetchFormat
  f: 'auto' as ImageFileExtension,
};
export const migratedPhotoTransformationParams = {
  a: 'ignore' as Angle,
};
export const videoTransformationParams = {
  // crop
  c: 'limit',
  // fetchMore
  f: 'mp4',
  // quality
  q: 'auto',
  // videoCodec
  vc: 'h264',
  // height
  h: '720',
  // width
  w: '1280',
};

export const audioTransformationParams = {
  // fetchMore
  f: 'mp3',
  // quality
  q: 'auto',
  // audioCodec
  ac: 'mp3',
};

export { profileConstants, instanceConstants };
