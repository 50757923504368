import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import styled from 'styled-components';
import { getAccessToken, ClaimAdditionalAccountModal, useUserContext } from '@blocs.auth';
import { useTranslation } from '@blocs.i18n';
import { useModal } from '@minecraft.modal';
import Icon from '@minecraft.icon';
import { media, EnterpriseTheme } from '@minecraft.themes';
import { WindowWidthContext } from '@minecraft.utils';
import { getEnvironment } from '@minecraft.environment';
import { usePermissions } from '@minecraft.permissions';
import { FEATURE_FLAGS, useFeatureExperiment } from '@blocs.features-experiments';
import MobileLogout from './MobileLogout';
import DivisionSwitcher from './DivisionSwitcher';
import { Updater } from './typed';
import { HeaderLogo } from './HeaderLogo';

/* ----- Styled Components ----- */
const StyledNavbar = styled(Navbar)`
  padding: 0;
  background-color: ${(props): string => props.theme.globalHeader.colors.backgroundColor};
  color: ${(props): string => props.theme.globalHeader.colors.link};
  border-bottom: 1px solid ${(props): string => props.theme.globalHeader.colors.border};
  ${media.lg`
    padding: 0.24rem 1rem;
  `}
  ${media.xlg`
    padding: 0.24rem 1.88rem;
  `}
`;

const StyledContainer = styled.div`
  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  ${media.xsm`
    padding: 0.72rem 1rem;
  `}
  ${media.sm`
    padding: 0.72rem 1.12rem;
  `}
  ${media.md`
    padding: 0.72rem 1.5rem;
  `}
  ${media.lg`
    padding: 0;
  `}
`;

const StyledLink = styled(Link)`
  align-items: center;
  display: flex;
  font-size: 0.875rem;
  line-height: 1;
  padding: 0;
  text-transform: uppercase;
  > span {
    color: ${(props): string => props.theme.globalHeader.colors.brand};
  }
  &:hover {
    text-decoration: none;
  }
  ${media.md`
    margin: 0;
  `}
`;

const StyledNavbarToggler = styled(NavbarToggler)`
  border: none;
  padding: 0;
`;

const StyledCollapse = styled(Collapse)`
  justify-content: space-between;
  padding: 0;
`;

type PanelProps = { $isPC?: boolean };

const StyledUserPanel = styled.div<PanelProps>`
  font-size: 0.875rem;
  line-height: 1.5;
  padding: ${(props): string => (props.$isPC ? '0.5rem 1rem' : '0.5rem 0')};
  ${(props): string =>
    props.$isPC
      ? `
    border: 1px solid ${props.theme.colors.primary.ltGrey1};
    border-radius: 5px;
    display: flex;
    align-items: center;
  `
      : ''}
  ${media.sm`
    font-size: 0.75rem;
  `}
`;

const StyledUncontrolledDropdown = styled(UncontrolledDropdown)`
  display: inline-block;
  margin-left: 0.25rem;
`;

const StyledDropdownToggle = styled(DropdownToggle)`
  background-color: transparent;
  border: none;
  height: 1rem;
  padding: 0;
  width: 1rem;
  filter: ${(props): string =>
    props.theme.globalHeader.colors.brand === props.theme.colors.primary.white ? 'invert(1)' : 'none'};
  &:active,
  &:focus,
  &:hover {
    background-color: transparent;
  }
  svg {
    max-width: 100%;
    vertical-align: super;
  }
`;

const StyledDropdownItem = styled(DropdownItem)`
  font-size: 0.875rem;
`;

const ClaimButton = styled.div`
  color: ${(props): string => props.theme.colors.primary.darkGrey};
  &:hover {
    color: ${(props): string => props.theme.colors.primary.darkGrey};
  }
`;

const StyledNav = styled(Nav)`
  font-size: 0.875rem;
  background-color: ${(props): string => props.theme.globalHeader.colors.mobileLinkBackground};
  color: ${(props): string => props.theme.globalHeader.colors.mobileLink};
  > li {
    margin-left: 2rem;
    margin-right: 2rem;
    a {
      margin-top: 0.375rem;
      margin-bottom: 0.375rem;
      color: inherit !important;
      font-weight: 500;
    }
  }
  ${media.lg`
    background-color: ${(props): string => props.theme.globalHeader.colors.backgroundColor};
    color: ${(props): string => props.theme.globalHeader.colors.link};
    > li {
      margin-left: 1.5rem;
      margin-right: 0;
      a {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  `}
`;

const MeatballIcon = styled(Icon)`
  width: 1rem;
  height: 1rem;
`;

const PCNameWrap = styled.div`
  display: inline-flex;
  align-items: center;
`;
const PCName = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  width: 2rem;
  height: 2rem;
  background: ${(props): string => props.theme.colors.primary.ltGrey1};
  color: ${(props): string => props.theme.colors.primary.white};
  margin-right: 0.5rem;
`;

/* ----- Types ----- */
type Props = {
  children: JSX.Element | JSX.Element[];
  logoImage: string;
  logoRedirectUrl: string;
  logoTitle: string;
  showDivisionSwitcher: boolean;
  showSettings?: boolean;
  widthData?: WindowWidthContext.WidthData;
  updater: Updater;
  shouldUseDivisions?: boolean;
};

/* ----- Main Component ----- */
const GlobalHeader = ({
  logoImage,
  logoTitle,
  children,
  logoRedirectUrl,
  showDivisionSwitcher,
  updater,
  widthData,
  shouldUseDivisions,
  showSettings = false,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const { ability } = usePermissions();
  const location = useLocation();
  const { myAccount } = useUserContext();
  const [isNavbarOpen, setIsNavbarOpen] = useState<boolean>(false);
  const { isOpen, open, close } = useModal();
  const isLegacyUserOnboardingDeprecated = useFeatureExperiment(FEATURE_FLAGS.WEB_ONBOARDING_DEPRECATION);

  useEffect(() => {
    // in case if navBar is open + url is changed - close menu
    if (location && isNavbarOpen) setIsNavbarOpen(false);
  }, [location]);

  const onboardingUrl = getEnvironment()?.ONBOARDING_URL;
  const accessToken = getAccessToken();
  const additionalAccountClaimLink = `${onboardingUrl}/login?audienceToken=${accessToken}`;
  const renderAsGatedUser = ability.can('request-approval', 'role'); // this is a PC role at this time

  const getPCName = (): JSX.Element => {
    const { firstName, lastName, accountOrganization } = myAccount;
    const firstLetter = firstName?.[0]?.toUpperCase();
    const secondLetter = lastName?.[0]?.toUpperCase();

    return (
      <PCNameWrap>
        <PCName>{`${firstLetter}${secondLetter}`}</PCName>
        {accountOrganization?.[0]?.name}
      </PCNameWrap>
    );
  };

  const getAccountName = (): string => {
    if (!myAccount) return 'Loading...';

    return `${myAccount.firstName} ${myAccount.lastName}`;
  };

  const renderUserPanel = (): JSX.Element => {
    if (!widthData || widthData.moreMd) {
      return (
        <StyledUserPanel $isPC={renderAsGatedUser}>
          {renderAsGatedUser ? getPCName() : getAccountName()}
          <StyledUncontrolledDropdown direction="left">
            <StyledDropdownToggle data-qa-id="global-header-meatball-menu">
              <MeatballIcon name="menu-meatball" />
            </StyledDropdownToggle>
            <DropdownMenu>
              <StyledDropdownItem>
                {!isLegacyUserOnboardingDeprecated && (
                  <ClaimButton data-qa-id="global-header-additional-account-claim" onClick={open}>
                    {t('common:accountSettingsMenu.claimAdditionalAccounts')}
                  </ClaimButton>
                )}
                <ClaimAdditionalAccountModal isOpen={isOpen} close={close} />
              </StyledDropdownItem>
              <StyledDropdownItem onClick={updater?.handleLogoutClick} data-qa-id="global-header-log-out">
                {t('common:mainNav.logOut')}
              </StyledDropdownItem>
            </DropdownMenu>
          </StyledUncontrolledDropdown>
        </StyledUserPanel>
      );
    }

    return <MobileLogout onClick={updater?.handleLogoutClick} />;
  };

  return (
    <StyledNavbar expand="lg" dark data-testid="global-nav-wrapper">
      <StyledContainer>
        <StyledLink to={logoRedirectUrl} data-qa-id="global-header-index-link" aria-label={logoTitle}>
          <HeaderLogo logoImage={logoImage} logoTitle={logoTitle} isPC={renderAsGatedUser} />
        </StyledLink>
        <StyledNavbarToggler
          onClick={(): void => setIsNavbarOpen(!isNavbarOpen)}
          data-qa-id="global-header-hamburger-toggler"
        >
          <Icon
            name={isNavbarOpen ? 'close' : 'menu-hamburger'}
            color={
              renderAsGatedUser
                ? EnterpriseTheme.colors.primary.darkGrey
                : EnterpriseTheme.globalHeader.colors.navbarToggler
            }
          />
        </StyledNavbarToggler>
      </StyledContainer>

      <StyledCollapse isOpen={isNavbarOpen} navbar>
        <StyledNav navbar>{children}</StyledNav>
        {myAccount &&
          (showDivisionSwitcher ? (
            <DivisionSwitcher
              isNavbarOpen={isNavbarOpen}
              updater={updater}
              shouldUseDivisions={shouldUseDivisions}
              showSettings={showSettings}
              additionalAccountClaimLink={additionalAccountClaimLink}
              isPC={renderAsGatedUser}
            />
          ) : (
            renderUserPanel()
          ))}
      </StyledCollapse>
    </StyledNavbar>
  );
};

export default GlobalHeader;
