/**
 * Required
 * @param value
 * @return {boolean}
 */

export const Required = value => {
  if (value === undefined || value === null || value === '' || value === ' ') {
    return true;
  }
  return false;
};

export default Required;
