import primary from '../../CoreTheme/button/primaryColor';
import additional from '../../CoreTheme/button/additionalColor';
import link from '../../CoreTheme/button/linkColor';
import warning from '../../CoreTheme/button/warningColor';
import { Button } from '../../TSTypes/button';

const button: Button = {
  borderRadius: '0.25rem',
  height: '2rem',
  height40: '2.5rem',
  color: {
    primary,
    additional,
    link,
    warning,
  },
  size: {
    small: {
      fontWeight: '600',
      fontSize: '0.75rem',
      padding: '0.25rem 1.25rem',
      minWidth: '6.875rem',
    },
    large: {
      fontWeight: '500',
      fontSize: '0.875rem',
      padding: '0.3125rem 2rem',
      minWidth: '8.75rem',
    },
  },
};

export default button;
